<template>
  <div>
    <l-rectangle ref="tile"
      color="yellow"
      :weight="4"
      :bounds="geohashBoundsRectangle"
    />
    <l-rectangle v-if="geohashBoundsMapExtentLng"
      ref="extentLng"
      color="green"
      :weight="5"
      :bounds="geohashBoundsMapExtentLng"
    />
    <l-polyline
      color="red"
      :weight="2"
      :latLngs="southBorderLine"
    />
    <l-polyline
      color="red"
      :weight="2"
      :latLngs="westBorderLine"
    />
    <l-polyline
      color="red"
      :weight="2"
      :latLngs="eastBorderLine"
    />
    <l-polyline
      color="red"
      :weight="2"
      :latLngs="northBorderLine"
    />
    <template v-if="geohashPreciseBoundsMapExtentLngFeaturesFill">
      <geohash-label-marker
        v-for="(item, index) in geohashPreciseBoundsMapExtentLngFeaturesFill"
        :key="`geohash-fill-label-${index}-${item.geohash}`"
        :lat-lng="item.center"
        :geohash="item.geohash"
      />
    </template>
    <template v-if="geohashPreciseBoundsMapExtentLngFeatures">
      <geohash-label-marker
        v-for="(item, index) in geohashPreciseBoundsMapExtentLngFeatures"
        :key="`geohash-label-${index}-${item.geohash}`"
        :lat-lng="item.center"
        :geohash="item.geohash"
      />
    </template>
    <geohash-label-marker
      :lat-lng="geohashPreciseCenter"
      :geohash="geohashPrecise"
    />
  </div>
</template>

<script>
// import markerGrid from './markerGrid'
import geohashMixin from './geohashMixin'
import geohashLabelMarker from './geohashLabelMarker'
// import geohashGridSquare from './geohashGridSquare'
import { findRealParent } from 'vue2-leaflet'
export default {
  name: 'geohashGrid',
  components: {
    geohashLabelMarker
  },
  mixins: [
    geohashMixin
  ],
  props: {
    pane: {
      type: String,
      default: () => 'geohash-grid'
    }
  },
  data () {
    return {
      className: 'z-top geodocr-grid',
      map: null,
      snap: null,
      panes: null,
      bounds: null,
      mapPane: null
    }
  },
  mounted () {
    let vm = this
    log: vm
    vm.setupSnap()
    window.arrive(`.leaflet-${this.pane}-pane`, function () {
      this.unbindArrive('.leaflet-geohash-grid-pane')
    })
    let mapComponent = findRealParent(this.$parent)
    this.map = mapComponent.mapObject
    this.panes = this.map.getPanes()
    this.mapPane = (this.pane in this.panes)
      ? this.panes[this.pane]
      : this.map.createPane(this.pane)
  },
  methods: {
    setupSnap () {
      let vm = this
      let s = vm.$snap.Snap(document.querySelector('svg'))
      /*
        let blur = vm.$snap.Snap.filter.blur(1, 1)
        let mouseBlur = vm.$snap.Snap.filter.blur(1, 1)
        vm.mapLayer.mouseFilter = s.filter(mouseBlur)
        vm.mapLayer.mouseFilter = vm.mapLayer.mouseFilter.attr({ filterUnits: 'objectBoundingBox' })
        vm.mapLayer.filter = s.filter(blur)
        vm.mapLayer.filter = vm.mapLayer.filter.attr({ filterUnits: 'objectBoundingBox' })
      */
      vm.snap = s
    },
    onAdd (e) {
    /*
      e.target._path.setAttribute('filter', `url(#${vm.mapLayer.filter.node.id})`)
      e.target.on('mouseover', function (evt) {
        evt.target._path.setAttribute('filter', `url(#${vm.mapLayer.mouseFilter.node.id})`)
      })
      e.target.on('mouseout', function (evt) {
        evt.target._path.setAttribute('filter', `url(#${vm.mapLayer.filter.node.id})`)
      })
    */
    }
  },
  computed: {}
}
</script>

<style lang="stylus" scoped>
.leaflet-overlay-pane
  z-index 800
.hash-marker
  font-family Courier New
  color white
  text-shadow -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000
  font-weight bold
  letter-spacing $hash-letter-spacing
  text-transform uppercase
  z-index 900
</style>
