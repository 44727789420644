import { sync } from 'vuex-pathify'
export default {
  props: {
    messageChunkSizes: {
      type: Array,
      default: () => [5, 10, 25, 50]
    }
  },
  computed: {
    messagesSettings: sync('layout/wire@messagesSettings'),
    pageOffset: {
      get () {
        return this.messagesSettings.offset || 0
      },
      set (val) {
        let { messagesSettings } = this
        this.messagesSettings = {
          ...messagesSettings,
          offset: val
        }
      }
    },
    pageLimit: {
      get () {
        return this.messagesSettings.limit || this.messageChunkSizes[0]
      },
      set (val) {
        let { messagesSettings } = this
        this.messagesSettings = {
          ...messagesSettings,
          limit: val
        }
      }
    },
    page: {
      get () {
        return (this.pageLimit + this.pageOffset) / this.pageLimit
      },
      set (val) {
        if (val > this.pageMax) {
          this.pageOffset = (
            this.pageLimit * this.pageMax
          ) - this.pageLimit
        } else if (val <= 0) {
          this.pageOffset = 0
        } else {
          this.pageOffset = (
            this.pageLimit * val
          ) - this.pageLimit
        }
      }
    },
    pageMax () {
      return (this.messageCount)
        ? Math.ceil(this.messageCount / this.pageLimit)
        : 1
    }
  }
}
