export default {
  DocrAttrs: {
    fields: {
      docId: {
        type: 'string',
        id: 1
      },
      docCells: {
        type: 'string',
        rule: 'repeated',
        id: 2
      },
      idHash: {
        type: 'string',
        id: 3
      }
    }
  },
  DocrContent: {
    fields: {
      type: {
        type: 'string',
        id: 1
      },
      content: {
        type: 'string',
        id: 2
      },
      attrs: {
        type: 'DocAttrs',
        id: 3
      }
    }
  },
  DocrRecord: {
    fields: {
      hid: {
        type: 'string',
        id: 1
      },
      idHash: {
        type: 'string',
        id: 2
      },
      exist: {
        type: 'string',
        id: 3
      },
      begin: {
        type: 'string',
        id: 4
      },
      pivot: {
        type: 'string',
        id: 5
      },
      cease: {
        type: 'string',
        id: 6
      },
      uid: {
        type: 'string',
        id: 7
      },
      origin: {
        type: 'string',
        id: 8
      },
      syncUpdate: {
        type: 'bytes',
        id: 9
      },
      geohash: {
        type: 'string',
        id: 10
      },
      searchText: {
        type: 'string',
        id: 11
      },
      resources: {
        type: 'string',
        repeated: true,
        id: 12
      },
      tags: {
        type: 'string',
        repeated: true,
        id: 13
      },
      type: {
        type: 'string',
        id: 14
      },
      content: {
        type: 'bytes',
        comment: 'isObject',
        id: 15
      },
      attrs: {
        type: 'DocrAttrs',
        id: 16
      },
      title: {
        type: 'bytes',
        comment: 'isObject',
        id: 17
      }
    }
  }
}
