import selectNamedCrs from './components/selectNamedCrs'
import crs from './libs/crs'
import {
  SCHEMA_SPEC,
  PRIMARY_KEY,
  SCHEMA_NAME,
  WORKER_NAME
} from './constants'
import {
  LeafletWorkerInterface
} from './LeafletWorkerInterface'

const Worker = require(`worker-loader?name=tile-worker.js!./worker.js`)

export const plugin = ({ app, router, Vue, store, schemaSpec }) => {
  let worker = new LeafletWorkerInterface(Worker, {
    vm: store._vm,
    store,
    storeKey: SCHEMA_NAME,
    schemaName: SCHEMA_NAME,
    primaryKey: PRIMARY_KEY,
    schemaSpec
  })
  Vue.prototype[WORKER_NAME] = worker
  Vue.component('select-named-crs', selectNamedCrs)
  return worker
}
export store from './store'
export const schemaSpecMerge = { [SCHEMA_NAME]: SCHEMA_SPEC }
export const schemaName = SCHEMA_NAME
export const workerName = WORKER_NAME
