const mortice = require('mortice')
import WorkerInterface from './WorkerInterface'
let defaultOpts = {
  workerCount: 1
}
export default class UtilWorkerInterface extends WorkerInterface {
  get urlCache () {
    return new LRU({
      max: 5000,
      maxAge: 1000 * 60 * 60, // 1 hour
      updateAgeOnGet: true // reup max age
    })
  }
  api () {
    let self = this
    return {
      getMerkleKey: self.getMerkleKey.bind(self),
      uInt8Concatenate: self.uInt8Concatenate.bind(self),
      getBufferChunks: self.getBufferChunks.bind(self),
      getKey: self.getKey.bind(self),
      fetchBuffer: self.fetchBuffer.bind(self),
      fetchBlob: self.fetchBlob.bind(self),
      getBuffer: self.getBuffer.bind(self)
    }
  }
  async fetchBlob ({
    url,
    init
  }, opts = {
    useCache: true
  }) {
    let self = this
    let release = await self.readLock(url)
    if (opts.useCache && self.urlCache.has(url)) {
      release()
      return self.urlCache.get(url)
    }
    let message = {
      handler: 'fetchBlob',
      url,
      init
    }
    let { result } = await this.message(message)
    release()
    return result
  }
  async fetchBuffer ({
    url,
    init
  }, opts = {
    useCache: true
  }) {
    let self = this
    let release = await self.readLock(url)
    if (opts.useCache && self.urlCache.has(url)) {
      release()
      return self.urlCache.get(url)
    }
    let message = {
      handler: 'fetchBlob',
      getBuffer: true,
      getKey: true,
      url,
      init
    }
    let { result } = await this.message(message)
    release()
    return result
  }
  async getBuffer ({
    getKey,
    cacheKey,
    blob
  }, opts = {
    useCache: true
  }) {
    let self = this
    if (!blob) return new Error('NoBlob')
    let message = {
      handler: 'getBuffer',
      getKey,
      blob
    }
    let {
      result
    } = await this.message(message)
    return result
  }
  async getKey (buffer) {
    let message = {
      handler: 'hashDigestBase64',
      buffer
    }
    let { result } = await this.message(message)
    return result
  }
  async getMerkleKey (buffers) {
    let message = {
      handler: 'hashDigestBase64Merkle',
      buffers
    }
    let { result } = await this.message(message)
    return result
  }
  getBufferChunks ({
    buffer,
    chunkSize
  }) {
    let message = {
      handler: 'getBufferChunks',
      buffer,
      chunkSize
    }
    return this.message(message)
  }
  uInt8Concatenate ({ array }) {
    let message = {
      handler: 'uInt8ConcatenateW',
      array
    }
    return this.message(message)
  }

  readLock (key) {
    return mortice(
      key, { concurrency: 1, timeout: 10000 }
    ).readLock()
  }

  writeLock (key) {
    return mortice(
      key, { concurrency: 1, timeout: 10000 }
    ).writeLock()
  }
}
