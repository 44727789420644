import { cacheBlobUrls } from '../../persistence'
import geohash from '../fields/geohash'
import timestamps from '../fields/timestamps'
import attribution from '../fields/attribution'
export default {
  _primary: 'uid',
  _icon: 'img:icons/icon-docr.svg',
  title: {
    label: ({ key, value }) => value,
    icon: 'fa fa-heading',
    component: 'q-input',
    convert (text) {
      return {
        type: 'heading',
        attrs: { level: 2 },
        content: [{
          type: 'text',
          text
        }]
      }
    },
    save (key, recordValue, text) {
      return this.convert(text)
    },
    props (key, recordValue) {
      if (isString(recordValue)) {
        recordValue = this.convert(recordValue)
      }
      let content = recordValue.content.find(c => c.type === 'text')
      return {
        label: 'Title',
        standout: true,
        value: content.text
      }
    }
  },
  tags: {
    icon: 'fa fa-tags',
    component: 'q-select',
    props (key, val) {
      if (!val) val = []
      return {
        key: `tags-${key}-${val.join('-')}`,
        label: 'Tags',
        standout: true,
        multiple: true,
        'use-input': true,
        'use-chips': true,
        'hide-dropdown-icon': true,
        'new-value-mode': 'add-unique',
        value: val
      }
    }
  },
  geohash,
  attribution,
  attrs: {
    icon: 'fa fa-list-alt',
    hidden: true
  },
  ...timestamps,
  share: {
    icon: 'fa fa-user-tag',
    hidden: true
  },
  encrypt: {
    icon: 'fa fa-hashtag',
    disabled: true,
    hidden: true
  },
  searchText: {
    icon: 'fa fa-search',
    disabled: true,
    hidden: true
  },
  hid: {
    pk: true,
    key: true,
    disabled: true,
    icon: 'fa fa-key'
  },
  uid: {
    pk: true,
    key: true,
    disabled: true,
    icon: 'fa fa-key'
  }
}
