import { validate, getInitial } from 'sculp'
import {
  enums as flipEnums,
  schema as flipSchema
} from './flips'
const flipValidate = (ob) => validate(ob, flipSchema)

import {
  enums as rotateEnums,
  schema as rotateSchema
} from './rotations'
const rotateValidate = (ob) => validate(ob, rotateSchema)

export {
  flipEnums,
  flipSchema,
  flipValidate,
  rotateEnums,
  rotateSchema,
  rotateValidate
}
