<template>
  <q-btn icon="fa fa-file-signature"
    label="EXPORT IDENTITY"
    class="text-black full-width"
    color="warning"
    size="sm"
    dense
    push
  >
    <q-popup-proxy v-close-popup
      ref="exportDialog"
      transition-show="flip-up"
      transition-hide="flip-down"
    >
      <q-card>
        <close-dialog-button @close="() => $refs.exportDialog.toggle()" />
        <q-item-section class="bg-primary q-pa-sm">
            <q-item-label overline class="text-info">
              <q-icon name="fa far fa-envelope" />
              {{record.identity.email}}
            </q-item-label>
          <q-item-label>
            <q-icon size="2em" name="fa fa-key" color="yellow" />
            Export Identity File
          </q-item-label>
          <q-item-label caption class="text-info">
            GEODOCR Recommends Password Protection for Identity Files
          </q-item-label>
        </q-item-section>
        <q-card-actions vertical class="bg-primary">
          <form>
            <q-input v-model="exportPassword"
              class="col-grow q-py-sm"
              label="Password"
              type="password"
              color="accent"
              autofocus
              outlined
            />
            <div class="text-green text-subtitle2">
              Password Strength [minimum Length: 8, Lower Case, Upper Case, Special Characters]
            </div>
            <q-linear-progress
              :value="exportPasswordStrengthRatio"
              :color="exportPasswordStrengthRatio > 0.8
                ? 'positive'
                : exportPasswordStrengthRatio > 0.5
                  ? 'warning'
                  : 'negative'
              "
              class="q-mt-sm"
              size="20px"
              rounded
              stripe
              dark
            />
            <q-input v-model="exportPasswordDupe"
              class="col-grow q-py-sm"
              label="Duplicate Password"
              type="password"
              color="accent"
              outlined
            />
          </form>
          <q-btn @click="$IdentityWorker
            .exportUserFile(record.uid, {
              password: exportPassword,
              startDownload: true
            })"
            :disable="!exportPasswordValid"
            icon="fa fa-lock"
            color="amber"
            label="Export with Password"
            outline
          />
        </q-card-actions>
        <q-card-actions vertical>
          <q-separator/>
          <q-btn @click="$IdentityWorker.exportUserFile(record.uid)"
            label="Export without Password"
            icon="fa fa-lock-open"
            color="warning"
            outline
          />
        </q-card-actions>
      </q-card>
    </q-popup-proxy>
  </q-btn>
</template>

<script>
export default {
  name: 'RecordExportIdentity',
  props: {
    record: Object
  },
  data () {
    return {
      exportPassword: null,
      exportPasswordDupe: null
    }
  },
  computed: {
    exportPasswordStrengthRatio () {
      return (this.exportPasswordStrength)
        ? this.exportPasswordStrength / 9
        : 0
    },
    exportPasswordValid () {
      return this.exportPassword &&
        this.exportPassword.length > 8 &&
        this.exportPassword === this.exportPasswordDupe
    },
    exportPasswordStrength () {
      return (this.exportPassword)
        ? (() => {
          let score = this.exportPassword.length > 8 ? 1 : 0
          score += /[A-Z]/.test(this.exportPassword) ? 1 : 0
          score += /[a-z]/.test(this.exportPassword) ? 1 : 0
          score += /[0-9]/.test(this.exportPassword) ? 1 : 0
          score += /[!@#$%^&*()]/.test(this.exportPassword) ? 1 : 0
          score += this.exportPassword.length > 10 ? 1 : 0
          score += this.exportPassword.length > 12 ? 1 : 0
          score += this.exportPassword.length > 14 ? 1 : 0
          score += this.exportPassword.length > 16 ? 1 : 0
          score += this.exportPassword.length > 18 ? 1 : 0
          score += this.exportPassword.length > 20 ? 1 : 0
          console.log(score)
          return score
        })()
        : 0
    }
  }
}
</script>
