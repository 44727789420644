// import 'arrive' --> loaded in html template
import observerStore from './store'
import { debounce } from 'quasar'
import { STORE_NAME } from './constants'
import { utils } from '../utils'
export default ({ app, router, Vue, store }) => {
  window.addEventListener('orientationchange', function () {
    store.dispatch('observer/refreshFixtures')
  })
  store.subscribe(debounce((mutation, state) => {
    if (
      mutation.type === 'layout/splitter' &&
      mutation.payload.path === 'model'
    ) {
      store.dispatch('observer/refreshFixtures')
    }
  }), 1000)
  store.registerModule('observer', observerStore)
  utils.fireLoadingEvent(document)
}
