export const LOADING_CONTENT = {
  type: 'doc',
  attrs: {
    docId: null
  },
  content: [{
    type: 'heading',
    attrs: { level: 2 },
    content: [{
      type: 'text',
      text: 'Loading DOCR'
    }]
  }]
}
export const DEFAULT_CONTENT = {
  type: 'doc',
  attrs: {
    docId: null
  },
  content: [
    {
      type: 'heading',
      attrs: {
        level: 2
      },
      content: [
        {
          type: 'text',
          text: 'Geodocr'
        }
      ]
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'Geodocr: Share your world'
        }
      ]
    }
  ]
}
