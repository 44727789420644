export default {
  Packet: {
    fields: {
      type: {
        type: 'string',
        id: 1
      },
      index: {
        type: 'fixed32',
        id: 2
      },
      length: {
        type: 'fixed32',
        id: 3
      },
      data: {
        type: 'bytes',
        id: 4
      },
      hid: {
        type: 'string',
        id: 5
      },
      fileSize: {
        type: 'fixed32',
        id: 6
      },
      fileHid: {
        type: 'string',
        id: 7
      }
    }
  }
}
