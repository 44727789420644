import Modeler from './Modeler'
import { timehashLib } from './libs'
import DataWrapper from './DataWrapper'
import * as regexps from './regexps'
import * as checks from './checks'
import protobufRoot from './protobufRoot'
import { utils } from '../utils'

export const modeler = new Modeler()

export default ({ app, Vue, router, store }) => {
  Vue.prototype.$pb = protobufRoot
  Vue.prototype.$regexps = regexps
  Vue.prototype.$checks = checks
  modeler.setRuntime({
    app,
    store
  })
  Vue.prototype.$mod = modeler
  Vue.prototype.$DataWrapper = DataWrapper
  utils.fireLoadingEvent(document)
}
