<template>
  <q-item style="min-width:400px" class="leaflet-pm-toolbar" clickable @click.stop="flyTo">
    <q-item-section avatar>
      <q-btn @click="onAdd"
        icon="img:icons/add-map-items.svg"
        label="add"
        size="md"
        stack
        flat
      />
    </q-item-section>
    <q-item-section avatar>
      <q-btn @click.stop icon="fa fa-edit" flat size="sm" label="Name" stack>
        <q-popup-proxy class="z-max"
          anchor="top left"
          self="bottom left"
          transition-show="flip-up"
          transition-hide="flip-down"
          >
          <q-input class="z-max"
            color="accent"
            debounce="1000"
            clearable
            autogrow
            autofocus
            outlined
            v-model="featureNameModel"
            label="Feature Name"
            v-match-popup-width>
          </q-input>
        </q-popup-proxy>
      </q-btn>
    </q-item-section>
    <q-item-section>
      <q-item-label>
        {{featureNameModel}}
      </q-item-label>
      <q-item-label caption lines="1">
        <div :class="`control-icon leaflet-pm-icon-${icons[shape]}`">
          {{shape}}
        </div>
      </q-item-label>
    </q-item-section>
    <q-item-section avatar>
      <q-btn @click="onRemove"
        icon="fa fa-times-circle"
        color="negative"
        size="md"
        stack
        flat
      />
    </q-item-section>
  </q-item>
</template>

<script>
import { uid } from 'quasar'
import { sanitizeUrl } from '@braintree/sanitize-url'
import crs4326 from '../../static/crs/4326.json'
export default {
  name: 'PmLayerItem',
  props: {
    featureIndex: Number,
    map: Object,
    layer: Object,
    shape: String
  },
  data () {
    return {
      api: null,
      confirm: () => {},
      cancel: () => {},
      originalStyle: null,
      featureNameModel: null,
      icons: {
        'CircleMarker': 'circle-marker',
        'Circle': 'circle',
        'Line': 'polyline',
        'Polygon': 'polygon',
        'Rectangle': 'rectangle',
        'Marker': 'marker'
      }
    }
  },
  mounted () {
    this.featureNameModel = `Features ${this.featureIndex}`
    this.api = this.$leaflet.api()
  },
  methods: {
    flyTo () {
      this.map.once('zoomend', () => {
        let el = this.layer.getElement()
        el.classList.add('feature-selected')
        this.map.eachLayer((otherLayer) => {
          if (this.layer._leaflet_id !== otherLayer._leaflet_id) {
            if (otherLayer.getElement) {
              let oel = otherLayer.getElement()
              oel.classList.remove('feature-selected')
            }
          }
        })
      })
      this.map.flyTo(this.latLng)
    },
    exportGeojson (layer) {
      let geojson = layer.toGeoJSON()
      geojson.properties = Object.assign({}, geojson.properties, layer.options)
      if (this.shape === 'CircleMarker') {
        geojson.properties.radius = this.api.pixelToDistance(this.map) * geojson.properties.radius
      }
      geojson.properties.uid = uid()
      geojson.properties.icon = this.icons[this.shape]
      geojson.properties.featureName = sanitizeUrl(this.featureNameModel)
      geojson.properties.featureDescription = '[Geodocr.com]'
      geojson.properties.units = 'm'
      geojson.properties.shape = this.shape
      geojson.properties.exportCrs = crs4326
      return geojson
    },
    onAdd () {
      let feature = {
        featureName: this.featureNameModel,
        layer: this.layer,
        shape: this.shape,
        geojson: this.exportGeojson(this.layer)
      }
      this.$emit('add', feature)
      this.map.removeLayer(this.layer)
    },
    onRemove () {
      this.map.removeLayer(this.layer)
    }
  },
  computed: {
    latLng () {
      return this.layer.getLatLng
        ? this.layer.getLatLng()
        : this.layer.getCenter()
    }
  }
}
</script>

<style lang="stylus">
div.feature-selected > svg > g > path
  stroke yellow!important
path.feature-selected
  stroke yellow!important

</style>
