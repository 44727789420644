/* eslint-disable */
function wait (ms) {
  let start = new Date().getTime()
  let end = start
  while (end < start + ms) {
    end = new Date().getTime()
  }
}
const regexpTransformParseMatrix = /matrix\(([-+]?[0-9]*|[-+]?[0-9]*\.[0-9]+),\s([-+]?[0-9]*|[-+]?[0-9]*\.[0-9]+),\s([-+]?[0-9]*|[-+]?[0-9]*\.[0-9]+),\s([-+]?[0-9]*|[-+]?[0-9]*\.[0-9]+),\s([-+]?[0-9]*|[-+]?[0-9]*\.[0-9]+),\s([-+]?[0-9]*|[-+]?[0-9]*\.[0-9]+)\)/i
function transformParseMatrix (cssMatrixTransform) {
  let [
    str,
    scaleX,
    skewY,
    skewX,
    scaleY,
    translateX,
    translateY
  ] = regexpTransformParseMatrix.exec(cssMatrixTransform)
  return {
    scaleX: parseFloat(scaleX),
    skewY: parseFloat(skewY),
    skewX: parseFloat(skewX),
    scaleY: parseFloat(scaleY),
    translateX: parseFloat(translateX),
    translateY: parseFloat(translateY)
  }
}
function inRange (x, min, max) {
  return ((x - min) * (x - max) <= 0)
}

import { flatten, expand } from 'objnest'
const flattenSort = (ob) => {
  let flat = flatten(ob)
  return Object.entries(flat).sort(
    function ([k1, v1], [k2, v2]) {
      let textA = k1.toUpperCase()
      let textB = k2.toUpperCase()
      return (textA < textB)
        ? -1
        : (textA > textB) ? 1 : 0
    }
  )
}
const expandArray = (arr) => {
  return expand(
    arr.reduce((acc, [key, val]) => {
      acc[key] = val
      return acc
    }, {})
  )
}

const eachRecursive = (obj, fn, depth = 0, root = null) => {
  if (!root) root = { ...obj }
  let promises = []
  for (let key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      Object.prototype.toString.call(obj[key]) === '[object Object]' &&
      obj[key] !== null
    ) {
      eachRecursive(obj[key], fn, depth++, root)
    } else {
      promises.push(fn(obj, key, depth, root))
    }
  }
  return promises.map(p => Promise.resolve(p))
}

const chunk = (array, size) => {
  const chunkedArr = []
  let index = 0
  while (index < array.length) {
    chunkedArr.push(array.slice(index, size + index))
    index += size
  }
  return chunkedArr
}
const dL = (string) => {
  return string.charAt(0).toLowerCase() +
    string.slice(1)
}
const uL = (string) => {
  return string.charAt(0).toUpperCase() +
    string.slice(1)
}
const fireLoadingEvent = (el) => {
  let event = new Event('geodocrloading')
  el.dispatchEvent(event)
}
export const utils = {
  uL,
  dL,
  wait,
  chunk,
  expand,
  inRange,
  flatten,
  afterEvent,
  expandArray,
  flattenSort,
  eachRecursive,
  fireLoadingEvent,
  transformParseMatrix
}
import { sync } from 'vuex-router-sync'
import AsyncComputed from 'vue-async-computed'
import { plugin as iconWrapperPlugin } from 'vue-leaflet-icon-wrapper'
export default async ({ Vue, router, store }) => {
  Vue.use(AsyncComputed)
  Vue.use(iconWrapperPlugin)
  Vue.prototype.$Vue = Vue
  Vue.prototype.$utils = utils
  Vue.prototype.$unsyncRouter = sync(store, router)
  fireLoadingEvent(document)
}