import defaultIcon from './defaultIcon'
import searchIcon from './searchIcon'
import imageIcon from './imageIcon'
import dummy from './dummy'
export {
  imageIcon,
  defaultIcon,
  searchIcon,
  dummy
}
