import { render, staticRenderFns } from "./recordDetail.vue?vue&type=template&id=7ce1212a&"
import script from "./recordDetail.vue?vue&type=script&lang=js&"
export * from "./recordDetail.vue?vue&type=script&lang=js&"
import style0 from "./recordDetail.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSpinnerRadio from 'quasar/src/components/spinner/QSpinnerRadio.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QItem,QItemSection,QSpinnerRadio,QIcon,QBadge,QTooltip,QItemLabel,QBtn,QDialog,QBanner,QCheckbox});
