<template>
  <div>
    <q-toggle class="q-pb-md" v-if="properties"
      @input="handleToggle"
      indeterminate-value="false"
      label="Connect Sync"
      :value="properties.syncOn"
      color="green"
      dense
    />
  </div>
</template>

<script>
import Manifest from '../lib/Manifest'
import { get } from 'vuex-pathify'
export default {
  name: 'WireConnectSync',
  data () {
    return {
      manifest: null
    }
  },
  mounted () {
    this.$store.dispatch('wire/getSyncProperties', this).then((properties) => {
      this.handleToggle(properties.syncOn)
    })
  },
  methods: {
    async handleToggle (val) {
      let {
        idHash,
        deviceHash
      } = this
      let properties = await this.$store.dispatch('wire/setSyncProperties', { idHash, properties: { syncOn: val } })
      if (val) {
        this.manifest = new Manifest(this.$store, { idHash, deviceHash, properties })
      } else {
        // cease room
        if (this.manifest) this.manifest.destroy()
      }
    }
  },
  computed: {
    properties: get('wire/sync@properties'),
    room: get('wire/sync@room'),
    idHash: get('identity/idHash'),
    deviceHash: get('layout/deviceId@deviceHash')
  }
}
</script>
