<template>
  <l-marker
    :lat-lng="latLng"
    color="grey"
  >
    <l-icon
      className="clickable-icon"
      :icon-size="[6, 6]"
      :icon-anchor="[4,16]"
    >
      <i class="fa fa-hashtag"/>
      <div class="hash-marker">
        {{geohash}}
      </div>
    </l-icon>
  </l-marker>
</template>

<script>
import { LatLng } from 'leaflet'
import { findRealParent } from 'vue2-leaflet'
export default {
  name: 'geohashLabelMarker',
  props: {
    latLng: {
      type: LatLng
    },
    geohash: {
      type: String,
      default: ''
    }
  },
  mounted () {
    let mapComponent = findRealParent(this.$parent)
    this.map = mapComponent.mapObject
  },
  computed: {
    isNotVisible () {
      return (this.mapBounds)
        ? this.mapBounds.contains(this.normalized)
        : false
    },
    mapBounds () {
      return (this.map)
        ? this.map.getBounds()
        : null
    }
  }
}
</script>

<style lang="stylus">
i.hash
  color #000
  opacity 0.2
</style>
