import {
  DexieWorkerInterface
} from '../DexieWorkerInterface'
export class DxfWorkerInterface extends DexieWorkerInterface {
  async dxfLayerEntities ({
    file,
    layername,
    filename,
    hid
  }) {
    let message = {
      handler: 'dxfLayerEntities',
      file,
      layername,
      filename,
      hid
    }
    let { result } = await this.message(message)
    return result
  }
  async dxfEntities ({
    file,
    filename,
    hid,
    handle,
    ownerhandle
  }) {
    let message = {
      handler: 'dxfEntities',
      file,
      handle,
      ownerhandle,
      filename,
      hid
    }
    let { result } = await this.message(message)
    return result
  }
  async dxfBlockEntities ({
    file,
    blockname,
    filename,
    hid
  }) {
    let message = {
      handler: 'dxfBlockEntities',
      file,
      blockname,
      filename,
      hid
    }
    let { result } = await this.message(message)
    return result
  }
  async dxfLoad (file, opts = {}) {
    let message = {
      handler: 'loadDxf',
      file,
      ...opts
    }
    let { result } = await this.message(message)
    return result
  }
}
