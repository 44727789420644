import transform3857 from './3857.json'
import transform4326 from './4326.json'
import transform103701 from './103701.json'
import transform103701Landfill from './103701_landfill.json'
import transform104741 from './104741.json'
import transform104741Landfill from './104741_landfill.json'

const crs = [
  transform3857,
  transform4326,
  transform103701,
  transform103701Landfill,
  transform104741,
  transform104741Landfill
]

export default crs
