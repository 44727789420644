/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import '@quasar/extras/roboto-font/roboto-font.css'

import '@quasar/extras/material-icons/material-icons.css'

import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'



import '@quasar/extras/animate/fadeIn.css'

import '@quasar/extras/animate/fadeOut.css'

import '@quasar/extras/animate/fadeInLeft.css'

import '@quasar/extras/animate/fadeInRight.css'

import '@quasar/extras/animate/fadeInUp.css'

import '@quasar/extras/animate/fadeInDown.css'

import '@quasar/extras/animate/fadeOutRight.css'

import '@quasar/extras/animate/fadeOutLeft.css'

import '@quasar/extras/animate/fadeOutUp.css'

import '@quasar/extras/animate/fadeOutDown.css'

import '@quasar/extras/animate/slideInLeft.css'

import '@quasar/extras/animate/slideOutLeft.css'

import '@quasar/extras/animate/slideInRight.css'

import '@quasar/extras/animate/slideOutRight.css'


// We load Quasar stylesheet file
import 'quasar/dist/quasar.styl'


// We add Quasar addons, if they were requested
import 'quasar/src/css/flex-addon.styl'



import 'src/css/app.styl'

import 'src/css/leaflet.styl'


import Vue from 'vue'
import createApp from './app.js'


import 'app/src-pwa/register-service-worker.js'




import qboot_Bootobserver from 'boot/observer'

import qboot_BootdeviceId from 'boot/deviceId'

import qboot_Bootutils from 'boot/utils'

import qboot_Bootmodels from 'boot/models'

import qboot_Bootversion from 'boot/version'

import qboot_BootdexieWorkers from 'boot/dexieWorkers'

import qboot_Bootleaflet from 'boot/leaflet'

import qboot_Booti18n from 'boot/i18n'

import qboot_Bootaxios from 'boot/axios'

import qboot_Bootgeohash from 'boot/geohash'

import qboot_Boottimehash from 'boot/timehash'

import qboot_Bootcomponents from 'boot/components'

import qboot_Booteditor from 'boot/editor'

import qboot_Bootpersistence from 'boot/persistence'











// Needed only for iOS PWAs
if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && window.navigator.standalone) {
  import(/* webpackChunkName: "fastclick"  */ '@quasar/fastclick')
}


const publicPath = `/`


async function start () {
  const { app, store, router } = await createApp()

  

  
  let hasRedirected = false
  const redirect = url => {
    hasRedirected = true
    const normalized = Object(url) === url
      ? router.resolve(url).route.fullPath
      : url

    window.location.href = normalized
  }

  const urlPath = window.location.href.replace(window.location.origin, '')
  const bootFiles = [qboot_Bootobserver,qboot_BootdeviceId,qboot_Bootutils,qboot_Bootmodels,qboot_Bootversion,qboot_BootdexieWorkers,qboot_Bootleaflet,qboot_Booti18n,qboot_Bootaxios,qboot_Bootgeohash,qboot_Boottimehash,qboot_Bootcomponents,qboot_Booteditor,qboot_Bootpersistence]

  for (let i = 0; hasRedirected === false && i < bootFiles.length; i++) {
    if (typeof bootFiles[i] !== 'function') {
      continue
    }

    try {
      await bootFiles[i]({
        app,
        router,
        store,
        Vue,
        ssrContext: null,
        redirect,
        urlPath,
        publicPath
      })
    }
    catch (err) {
      if (err && err.url) {
        window.location.href = err.url
        return
      }

      console.error('[Quasar] boot error:', err)
      return
    }
  }

  if (hasRedirected === true) {
    return
  }
  

  

    

    

    
      new Vue(app)
    

    

    

  

}

start()
