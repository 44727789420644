export const sectionMenuItems = [
  'docr',
  'map',
  'browse',
  'sync',
  'identity'
]
export const sectionMenuItemsShort = {
  'docr': 'docr',
  'map': 'map',
  'br': 'browse',
  'sync': 'sync',
  'id': 'identity',
  'sh': 'search'
}
export const syncDbItems = [
  'wire',
  'docr',
  'identity',
  'media',
  'tileLayers'
]

export const licenseItems = {
  'CR': 'Copyright [All rights Reserved]',
  'CC0': 'Freeing content globally without restrictions',
  'CC-BY': 'Attribution alone',
  'CC-BY-NC': 'Attribution + ShareAlike',
  'CC-BY-NC-ND': 'Attribution + Noncommercial',
  'CC-BY-NC-SA': 'CC-BY-NC-SA icon  Attribution + Noncommercial + ShareAlike',
  'CC-BY-SA': 'Attribution + NoDerivatives',
  'CC-BY-ND': 'Attribution + Noncommercial + NoDerivatives'
}
