import {
  DexieWorkerInterface
} from '../DexieWorkerInterface'
export class DocrWorkerStateInterface extends DexieWorkerInterface {
  get mediaSchema () {
    return 'media'
  }
  hashDocr (doc) {
    let message = {
      handler: 'hashDocr',
      doc
    }
    return this.message(message)
  }
  flattenDocr (doc) {
    let message = {
      handler: 'flattenDocr',
      doc
    }
    return this.message(message)
  }
  importZip (file, opts = {
    mediaSchema: 'media',
    schemaName: null,
    dbName: null
  }) {
    let self = this
    let message = {
      handler: 'importZipDocr',
      file,
      db: opts.dbName || self.dbName,
      schema: opts.schemaName || self.schemaName,
      mediaSchema: opts.mediaSchema || self.mediaSchema
    }
    return this.message(message)
  }
  exportZip (hidOrUid, opts = {
    mediaSchema: 'media',
    schemaName: null,
    dbName: null
  }) {
    let self = this
    let message = {
      handler: 'exportZipDocr',
      db: opts.dbName || self.dbName,
      schema: opts.schemaName || self.schemaName,
      mediaSchema: opts.mediaSchema || self.mediaSchema,
      ...hidOrUid
    }
    console.log(message)
    return this.message(message)
  }
  api () {
    let self = this
    return {
      fetchBlob: self.fetchBlob.bind(self),
      getBuffer: self.getBuffer.bind(self),
      setDoc: self.setDoc,
      getDoc: self.getDoc,
      clearDoc: self.clearDoc,
      store: {
        get: self.store.get,
        set: self.store.set
      },
      docKeys: self.docKeys,
      calcOriginDocId: self.calcOriginDocId
    }
  }
  clearDoc ({ docId, origin }) {
    let self = this
    let doc = self.getDoc({ docId, origin })
    doc.destroy()
    return self.setDoc({ docId, doc: null })
  }
  setDoc ({ docId, origin, doc }) {
    let self = this
    let originDocId = self.calcOriginDocId({ docId, origin })
    return self.store.set(`docr/docOthers@${docId}`, doc)
  }
  getDoc ({ docId, origin, runtimeY, content }) {
    let self = this
    let originDocId = self.calcOriginDocId({ docId, origin })
    let docOthers = self.store.get(`docr/docOthers`)
    if (!docOthers[originDocId]) {
      docOthers[originDocId] = new (runtimeY || Y).Doc()
      docOthers[originDocId].on('update', (updateMessage, origin, doc) => {
        self.store.set(`docr/docStats@${originDocId}`, self.docKeys(doc))
      })
      self.store.get(`docr/docOthers@${originDocId}`, docOthers[originDocId])
    }
    return docOthers[originDocId]
  }
  docKeys (doc) {
    return [ ...doc.share.keys() ]
  }
  calcOriginDocId ({ docId, origin }) {
    return dataHashDigestHex(`${origin}${docId}`)
  }
}
