import { pixelToDistance } from './utils'
import { L } from './libs'
import { tileProviders } from './tileProviders'
import { scales, calcMpp } from './scales'
class MapInterface {
  constructor ({ store, name, worker }) {
    let self = this
    self.tileProviders = tileProviders
    self._constants = {
      tileProviders
    }
    self._map = null
    self._L = L
    self.worker = worker
    self._store = store
    self._layers = []
    self._refreshEvents = []
    self.loaded = false
  }
  close () {
    let self = this
    self._map = null
    self.loaded = false
  }
  async loadProvider (map, providers) {
    let self = this
    let promises = []
    map.eachLayer(layer => {
      if (layer instanceof L.TileLayer) {
        if (providers.some(p => p.url === layer._url)) {
          providers = providers.filter(p => p.url !== layer._url)
          console.log('layer:exists', layer)
        } else {
          layer.remove()
        }
      }
    })
    for (const provider of providers) {
      let layer = L
        .tileLayer
        .offline(
          provider.url,
          self.worker.api(), {
            pane: 'offline',
            attribution: provider.attribution,
            ...provider.offline
          })
      let promise = new Promise((resolve, reject) => {
        self.loaded = true
        layer.on('add', resolve)
      })
      promises.push(promise)
      self._layers.push(layer)
      layer.addTo(map)
    }
    self._refreshEvents = await Promise.all(promises)
  }
  async refresh (map, providers) {
    let self = this
    self._map = map
    if (providers) {
      let tileProviders = providers.reduce((acc, p) => {
        let tileProvider = self.tileProviders.find(t => t.name.startsWith(p + '-'))
        if (tileProvider) acc.push(tileProvider)
        return acc
      }, [])
      if (tileProviders.length) return self.loadProvider(map, tileProviders)
    }
    return self.loadProvider(map, [self.tileProviders[0]])
  }
  api () {
    let self = this
    return {
      pixelToDistance,
      scales,
      calcMpp,
      loaded: () => self.loaded,
      getCenter: () => self.map.getCenter(),
      getBounds: () => self.map.getBounds(),
      getZoom: () => self.map.getZoom(),
      refreshMap: () => self.map.invalidateSize(),
      refreshLayers: () => self.refresh(self.map),
      fitLatLng (latLng) {
        if (!Array.isArray(latLng)) latLng = [latLng]
        return this.fitBounds(self.L.latLngBounds(latLng))
      },
      fitBounds: (newBounds) => self.map.fitBounds(newBounds, {
        duration: 1000,
        noMoveStart: true
      })
    }
  }
  get refreshEvents () {
    return self._refreshEvents
  }
  get layers () {
    let self = this
    self._layers = self._layers
      .filter(l => self.map.hasLayer(l))
    return self._layers
  }
  get client () {
    return this._client
  }
  get store () {
    return this._store
  }
  get L () {
    return this._L
  }
  get constants () {
    return this._constants
  }
  calcMpp (map) {
    return calcMpp(map || this.map)
  }
  get scales () {
    return scales
  }
  get map () {
    return this._map
  }
}

export default MapInterface
