export default {
  Tags: {
    fields: {
      tags: {
        id: 1,
        type: 'string',
        repeated: true
      }
    }
  }
}
