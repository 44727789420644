<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    :viewBox="`${viewBoxX} ${viewBoxY} ${width} ${height}`"
    :height="height"
    :width="width"
  >
    <metadata>
      <rdf:RDF>
        <cc:Work rdf:about>
          <dc:format>image/svg+xml</dc:format>
          <dc:title></dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <text
      xml:space="preserve"
      style="fill-opacity:1;stroke:none;stroke-width:0.14159435"
      x="0"
      y="0"

    >
      <tspan :x="hashLocation.x" :y="hashLocation.y">#</tspan>
      <tspan :x="charLocation.x" :y="charLocation.y">{{hashType}}</tspan>
    </text>
  </svg>
</template>

<script>
export default {
  name: 'hashIcon',
  props: {
    hashType: {
      type: String,
      default: 'i'
    },
    viewBoxX: {
      type: Number,
      default: 0
    },
    viewBoxY: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 8
    },
    width: {
      type: Number,
      default: 16
    }
  },
  computed: {
    hashLocation () {
      let { width, height } = this
      return {
        x: width / 2,
        y: height
      }
    },
    charLocation () {
      let { width, height } = this
      return {
        x: 0,
        y: height
      }
    }
  }
}
</script>

<style lang="stylus">
.tertiary {
  fill: $tertiary;
}

.info {
  fill: $info;
}

.positive {
  fill: $positive;
}

.secondary {
  fill: $secondary;
}

.primary {
  fill: $primary;
}

@keyframes example {
  from {
    fill: $info;
  }

  to {
    fill: $dark;
  }
}

.pulse-ready {
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
</style>
