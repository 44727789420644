export default {
  Timestamp: {
    fields: {
      exist: {
        type: 'string',
        id: 1
      },
      begin: {
        type: 'string',
        id: 2
      },
      pivot: {
        type: 'string',
        id: 3
      },
      cease: {
        type: 'string',
        id: 4
      }
    }
  }
}
