import { render, staticRenderFns } from "./north-icon.vue?vue&type=template&id=49eaa995&scoped=true&"
import script from "./north-icon.vue?vue&type=script&lang=js&"
export * from "./north-icon.vue?vue&type=script&lang=js&"
import style0 from "./north-icon.vue?vue&type=style&index=0&id=49eaa995&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49eaa995",
  null
  
)

export default component.exports
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBadge});
