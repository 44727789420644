import docrRecordItems from './components/docrRecordItems'
import {
  SCHEMA_SPEC,
  PRIMARY_KEY,
  SCHEMA_NAME,
  WORKER_NAME
} from './constants'

import {
  DocrWorkerStateInterface
} from './DocrWorkerStateInterface'

const Worker = require(`worker-loader?name=docr-worker.js!./worker.js`)

export const plugin = ({ app, router, Vue, store, schemaSpec }) => {
  let worker = new DocrWorkerStateInterface(Worker, {
    vm: store._vm,
    store,
    storeKey: SCHEMA_NAME,
    schemaName: SCHEMA_NAME,
    primaryKey: PRIMARY_KEY,
    schemaSpec
  })
  Vue.prototype[WORKER_NAME] = worker
  Vue.component('docr-record-items', docrRecordItems)
  return worker
}
export store from './store'
export const schemaSpecMerge = { [SCHEMA_NAME]: SCHEMA_SPEC }
export const schemaName = SCHEMA_NAME
export const workerName = WORKER_NAME
