export default {
  props: {
    pmPosition: {
      type: String,
      default: 'topleft'
    }
  },
  watch: {
    showEditor (val, oldVal) {
      if (!this.pm) return
      return val
        ? this.addControls()
        : this.removeControls()
    }
  },
  data () {
    return {
      showEditor: false,
      pmLayers: [],
      missingLayers: []
    }
  },
  mounted () {
    this.$on('toggle:editor', () => (this.showEditor = !this.showEditor))
    if (process.env.DEV) {
      this.showEditor = true
    }
    this.$once('leaflet:load', () => {
      if (this.showEditor) {
        this.addControls()
      }
      this.map.on('pm:create', this.handleCreate)
      this.map.on('pm:drawstart', this.handleDrawstart)
      this.map.on('pm:drawend', this.handleDrawend)
    })
  },
  beforeDestroy () {
    if (this.map) {
      this.map.off('pm:drawstart', this.handleDrawstart)
      this.map.off('pm:drawend', this.handleDrawend)
      this.map.off('pm:create', this.handleCreate)
    }
  },
  methods: {
    handleDrawstart (...args) {
      log: 'handleDrawstart', args
    },
    handleDrawend (...args) {
      log: 'handleDrawend', args
    },
    handleCreate ({ layer, shape }) {
      log: `handleCreate[${shape}]`, layer, shape
      if (layer.setStyle) layer.setStyle({ color: 'green' })
      layer.on('remove', () => {
        let newLayers = this.pmLayers.filter(l => l.layer._leaflet_id !== layer._leaflet_id)
        this.pmLayers = newLayers
      })
      this.pmLayers = [ ...this.pmLayers, { layer, shape } ]
    },
    addControls () {
      this.pm.addControls(this.pmControlOpts)
      this.pm.Toolbar.drawContainer.classList.add('no-print')
      this.pm.Toolbar.editContainer.classList.add('no-print')
      this.pm.Toolbar.editContainer.classList.add('pm-edit-container')
    },
    removeControls () {
      this.pm.removeControls()
    }
  },
  computed: {
    pmControlOpts () {
      return {
        position: this.pmPosition
      }
    },
    pm () { return this.map && this.map.pm }
  }
}
