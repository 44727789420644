import {
  SCHEMA_NAME,
  WORKER_NAME,
  SYNC_DEFAULTS
} from '../constants'

export function getSyncProperties (context, { idHash }) {
  let self = this
  return new Promise(async (resolve, reject) => {
    if (!idHash) reject()
    let key = `sync:id:${idHash}`
    let properties = await localforage.getItem(key)
    let currentProperties = Object.assign({}, SYNC_DEFAULTS, properties)
    self.set('wire/sync@properties', currentProperties)
    localforage.setItem(key, currentProperties)
    resolve(currentProperties)
  })
}
export function setSyncProperties (context, { properties, idHash }) {
  let self = this
  return new Promise(async (resolve, reject) => {
    let key = `sync:id:${idHash}`
    let currentProperties = await localforage.getItem(key)
    currentProperties = {
      ...currentProperties,
      ...properties
    }
    await localforage.setItem(key, currentProperties)
    self.set('wire/sync@properties', currentProperties)
    resolve(currentProperties)
  })
}
