import { Type, Presence } from 'sculp'
import {
  timestampEnum,
  shareEnum,
  encryptEnum,
  licenseEnum
} from './constants'
import * as checks from '../checks'
import * as regexps from '../regexps'
import {
  hexStringSHA256
} from '../crypto/transforms'
const validUrl = require('valid-url')
import {
  timehashLib
} from '../libs'
export const schema = {
  type: Type.OBJECT,
  properties: {
    infohash: {
      type: Type.STRING_NET,
      initial: '',
      $length: 40,
      $regexp: regexps.infohash,
      $presence: Presence.REQUIRED,
      transform: (v) => v ? v.toLowerCase() : '',
      meta: 'data Torrent for geodocr'
    },
    parent: {
      type: Type.STRING_NET,
      initial: '',
      $length: 40,
      $regexp: regexps.infohash,
      $presence: Presence.REQUIRED,
      transform: (v) => v ? v.toLowerCase() : '',
      meta: 'infohash parrent of torrent'
    },
    geohash: {
      type: Type.STRING_NET,
      initial: 'x',
      $lengthmin: 1,
      meta: 'Geohash location',
      transform: (v) => v ? v.toLowerCase() : '',
      $regexp: regexps.geohash,
      $presence: Presence.REQUIRED
    },
    url: {
      type: Type.STRING_NET,
      initial: 'https://geodocr.com',
      $validUrl: (fa) => {
        let value = fa() || ''
        return validUrl.isUri(value) ? undefined : 'Not a valid URI'
      },
      $presence: Presence.REQUIRED
    },
    share: {
      type: Type.STRING_NET,
      initial: shareEnum[0],
      transform: (v) => {
        return shareEnum.indexOf(v) === -1 ? shareEnum[0] : v
      },
      $presence: Presence.REQUIRED,
      meta: 'Enumeration of share state [PRIVATE, PUBLIC_SELECT, PUBLIC_ALL]'
    },
    encrypt: {
      type: Type.STRING_NET,
      initial: encryptEnum[0],
      transform: (v) => {
        return encryptEnum.indexOf(v) === -1 ? encryptEnum[0] : v
      },
      $presence: Presence.REQUIRED,
      meta: 'Enumeration of share state [SHA256, SHA512, ...]'
    },
    artifacts: {
      type: Type.ANY_OBJECT,
      initial: {},
      $presence: Presence.REQUIRED
    },
    metadata: {
      type: Type.OBJECT,
      $presence: Presence.REQUIRED,
      properties: {
        name: {
          initial: '',
          type: Type.STRING_NET,
          meta: 'File name',
          $presence: Presence.REQUIRED
        },
        size: {
          type: Type.NUMBER,
          initial: 0,
          precision: 0,
          meta: 'File size in Bytes',
          $presence: Presence.REQUIRED
        },
        mimetype: {
          type: Type.STRING_NET,
          initial: '',
          meta: 'Mime types for decoding/encoding',
          $presence: Presence.REQUIRED
        },
        description: {
          type: Type.STRING_NET,
          initial: 'A Geodocr File',
          meta: 'Description of geodocr [Markdown]',
          $presence: Presence.REQUIRED
        },
        tags: {
          type: Type.ARRAY,
          items: Type.STRING_NET,
          initial: [],
          removeEmpty: false,
          $presence: Presence.REQUIRED,
          transform: (v) => v ? v.map(tag => tag ? tag.toLowerCase() : '') : [],
          meta: 'Tags for related geodocr materials [text]'
        },
        shares: {
          type: Type.ARRAY,
          items: Type.STRING_NET,
          initial: [],
          removeEmpty: false,
          transform: (v) => {
            v = v || []
            return v.reduce((agg, child) => {
              if (child && checks.sha256(child)) {
                agg.push(child)
              }
              return agg
            }, [])
          },
          meta: 'Permitted public identity shares [sha256]'
        },
        children: {
          type: Type.ARRAY,
          items: Type.STRING_NET,
          removeEmpty: true,
          initial: [],
          transform: (v) => {
            v = v || []
            return v.reduce((agg, child) => {
              if (child && checks.infohash(child)) {
                agg.push(child)
              }
              return agg
            }, [])
          },
          meta: 'Children torrent infohashes [sha256]'
        }
      }
    },
    attribution: {
      type: Type.OBJECT,
      properties: {
        identity: {
          type: Type.STRING_NET,
          initial: hexStringSHA256('Anonymous GEODOCR v1'),
          $length: 64,
          $presence: Presence.REQUIRED,
          $regexp: regexps.sha256,
          meta: 'Public identity shares [sha256]'
        },
        email: {
          type: Type.STRING_NET,
          initial: '',
          $regexp: regexps.email,
          meta: ' '
        },
        publicKey: {
          initial: '',
          type: Type.STRING_NET
        },
        author: {
          initial: '',
          type: Type.STRING_NET
        },
        license: {
          initial: licenseEnum[0],
          transform: (v) => {
            return licenseEnum.indexOf(v) === -1
              ? licenseEnum[0]
              : v
          },
          type: Type.STRING_NET,
          meta: 'Enumeration of license state [CR, CC0, ...]'
        }
      }
    },
    timestamp: {
      type: Type.OBJECT,
      properties: (() => {
        return timestampEnum.reduce((agg, prop) => {
          agg[prop] = {
            type: Type.STRING_NET,
            $presence: Presence.REQUIRED,
            initial: timehashLib.encodems(new Date()),
            $max: 10,
            $min: 1
          }
          return agg
        }, {})
      })()
    }
  }
}
