import { Platform, uid } from 'quasar'
import ConfigDeviceId from './components/deviceId/ConfigDeviceId'
import { utils } from './utils'
// leave the export, even if you don't use it
// import 'public/js/localforage.min.js'
export default async ({ app, router, store, Vue }) => {
  let deviceId = store.get('layout/deviceId')
  deviceId.name = deviceId.name || `${Platform.is.name} on ${Platform.is.platform}`
  deviceId.uid = deviceId.uid || uid()
  deviceId.deviceHash = dataHashDigestHex(deviceId.uid)
  store.set('layout/deviceId', deviceId)
  Vue.component('config-device-id', ConfigDeviceId)
  utils.fireLoadingEvent(document)
}
