import {
  routeWindowedIdentity,
  routePrintParams,
  routeImageParams,
  routeGeohashParams,
  routeAddressParams
} from './params'
import {
  indexWindowedIdentity
} from './paths'
const routes = [
  {
    path: '/',
    component: () => import('layouts/LayoutV2.vue'),
    children: [{
      name: 'index',
      path: '',
      component: () => import('pages/PageIndexV2.vue')
    },
    {
      name: 'indexWindowedIdentity',
      path: indexWindowedIdentity(),
      component: () => import('pages/PageIndexV2.vue'),
      props: routeWindowedIdentity
    },
    {
      name: 'indexGeohash',
      path: '/:geohash([0123456789bcdefghjkmnpqrstuvwxyz]{1,12})',
      component: () => import('pages/PageIndexV2.vue'),
      props: routeGeohashParams
    },
    {
      name: 'indexMedia',
      path: '/i/:hid([A-Za-z0-9_-]{86})',
      component: () => import('pages/PageIndexV2.vue'),
      props: routeImageParams
    },
    {
      name: 'indexAddress',
      path: '/a/:address?',
      component: () => import('pages/PageIndexV2.vue'),
      props: routeAddressParams
    }]
  }, {
    name: 'print-layout',
    path: '/print',
    component: () => import('layouts/LayoutPrint.vue'),
    children: [
      {
        name: 'print-doc-id',
        path: ':docId',
        props: routePrintParams,
        component: () => import('pages/PrintIndex.vue')
      }
    ]
  }
]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('pages/Error404.vue')
  })
}

export default routes
