<template>
  <div>
    <q-badge v-if="selection" align="middle" floating color="dark">
      {{selection}}
    </q-badge>
    <svg
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      :viewBox="`0 0 ${width} ${height}`"
      :height="height"
      :width="width"
    >
      <metadata>
        <rdf:RDF>
          <cc:Work rdf:about>
            <dc:format>image/svg+xml</dc:format>
            <dc:title></dc:title>
          </cc:Work>
        </rdf:RDF>
      </metadata>
      <defs>
        <g id="tick">
          <path
            :d="`M-${radius / 4} 0 L0 ${radius / 16} L${radius / 4} 0 L0 ${radius / 2} Z`"
            stroke="lightgrey"
            :stroke-width="this.radius / 64"
          />
        </g>
        <g id="half">
          <path :d="`M${-radius / 4} 0 a1 1 0 0 0 ${radius / 2} 0 Z`"
          stroke="grey"/>
        </g>
        <filter id="drop-shadow">
          <feGaussianBlur in="SourceAlpha" stdDeviation="2.2"/>
          <feOffset
            :dx="this.radius / 64"
            :dy="this.radius / 64"
            result="offsetblur"/>
          <feFlood flood-color="rgba(56,56,56,0.6)"/>
          <feComposite in2="offsetblur" operator="in"/>
          <feMerge>
            <feMergeNode/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
        <filter id="drop-shadow-selected">
          <feGaussianBlur in="SourceAlpha" stdDeviation="2.2"/>
          <feOffset
            :dx="this.radius / 64"
            :dy="this.radius / 64"
            result="offsetblur"/>
          <feFlood flood-color="rgba(0,0,0,0.9)"/>
          <feComposite in2="offsetblur" operator="in"/>
          <feMerge>
            <feMergeNode/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      <g :transform="`scale(0.8)`" style="transform-origin: 50% 50%;">
        <circle
          :cx="radius"
          :cy="radius"
          :r="radius / 1.45"
          stroke="lightgrey"
          :stroke-width="radius / 256"
          filter="url(#drop-shadow)"
        />
        <g @click="e => getDirection(e, 'U')"
          transform="scale(1.2)"
          style="transform-origin: 50% 50%;"
          :class="'U' === selection ? 'pulse-ready cursor-pointer' : 'cursor-pointer'">
          <use xlink:href="#half"
            :transform="`rotate(180, ${radius}, ${radius}) translate(0,1)`"
            :x="radius"
            :y="radius"
          />
          <text :style="fontStyleHalf">
            <tspan
              style="dominant-baseline:text-after-edge;"
              :y="radius"
              :x="radius"
            >
            U
            </tspan>
          </text>
        </g>
        <g @click="e => getDirection(e, 'D')"
          transform="scale(1.2)"
          style="transform-origin: 50% 50%;"
          :class="'D' === selection ? 'pulse-ready cursor-pointer' : 'cursor-pointer'">
          <use xlink:href="#half"
            :transform="`translate(0,1)`"
            :x="radius"
            :y="radius"
          />
          <text :style="fontStyleHalf">
            <tspan
              style="dominant-baseline:text-before-edge;alignment-baseline:bottom;text-anchor: middle;"
              :y="radius"
              :x="radius"
            >
            D
            </tspan>
          </text>
        </g>
        <template v-for="([key, position], index) in pointsLocationsInner">
          <g @click="e => getDirection(e, key)"
            :class="key === selection ? 'pulse-ready cursor-pointer' : 'cursor-pointer'"
            :key="`points-group-${key}-${index}`">
            <use xlink:href="#tick"
              :filter="key === selection ? 'url(#drop-shadow-selected)' : 'url(#drop-shadow)'"
              :transform="`rotate(${-points.get(key)},${calcCircle(points.get(key), radius / 1.5).x},${calcCircle(points.get(key), radius / 1.5).y})`"
              :key="`ticks-${key}-${index}`"
              :x="calcCircle(points.get(key), radius / 1.5).x"
              :y="calcCircle(points.get(key), radius / 1.5).y"
            />
            <g :key="`points-location-${key}-${index}`"
              v-if="pointsRotate.get(key) === 0"
              :transform="`rotate(${pointsRotate.get(key)},${position.x},${position.y})`">
              <text>
                <tspan
                  :style="fontStyle"
                  :y="position.y"
                  :x="position.x"
                >
                {{key}}
                </tspan>
              </text>
            </g>
            <g :key="`points-location-${key}-${index}`"
              v-else
              :transform="`rotate(${pointsRotate.get(key)},${calcCircle(points.get(key), radius / 1.5).x},${calcCircle(points.get(key), radius / 1.5).y})`">
              <text>
                <tspan
                  :style="fontStyle"
                  :y="calcCircle(points.get(key), radius / 1.5).y"
                  :x="calcCircle(points.get(key), radius / 1.5).x"
                >
                {{key}}
                </tspan>
              </text>
            </g>
          </g>
        </template>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'northIcon',
  props: {
    value: {
      type: String,
      default: ''
    },
    hashType: {
      type: String,
      default: 'i'
    },
    viewBoxX: {
      type: Number,
      default: 0
    },
    viewBoxY: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 128
    },
    width: {
      type: Number,
      default: 128
    }
  },
  mounted () {
    this.selection = this.value
  },
  data () {
    return {
      selection: null,
      pointsRotate: new Map([
        ['N', 0],
        ['se', 45],
        ['W', 0],
        ['ne', -45],
        ['N', 0],
        ['nw', 45],
        ['W', 0],
        ['sw', -45],
        ['S', 0]
      ]),
      points: new Map([
        ['N', 0],
        ['se', 45],
        ['E', 90],
        ['ne', 135],
        ['N', 180],
        ['nw', 225],
        ['W', 270],
        ['sw', 315],
        ['S', 360]
      ])
    }
  },
  methods: {
    getDirection (e, key) {
      this.selection = key
      this.$nextTick(() => {
        this.$emit('set', key)
      })
    },
    calcRadians (degrees) {
      return degrees * Math.PI / 180
    },
    calcCircle (degrees, radius) {
      let { height, calcRadians } = this
      let radians = calcRadians(degrees)
      return {
        x: this.radius + (radius || this.radius) * Math.sin(radians),
        y: this.radius + (radius || this.radius) * Math.cos(radians)
      }
    },
    calcCircleDegrees (start, stop, step = 1) {
      return Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)
    }
  },
  computed: {
    radius () {
      return this.height / 2
    },
    degree5circlePointsInner () {
      let m = new Map()
      for (const degree of this.calcCircleDegrees(0, 360, 5)) {
        m.set(degree, this.calcCircle(degree, this.radius / 2))
      }
      return m
    },
    degree10circlePointsInner () {
      let m = new Map()
      for (const degree of this.calcCircleDegrees(0, 360, 10)) {
        m.set(degree, this.calcCircle(degree, this.radius / 2))
      }
      return m
    },
    degree5circlePoints () {
      let m = new Map()
      for (const degree of this.calcCircleDegrees(0, 360, 5)) {
        m.set(degree, this.calcCircle(degree))
      }
      return m
    },
    degree10circlePoints () {
      let m = new Map()
      for (const degree of this.calcCircleDegrees(0, 360, 10)) {
        m.set(degree, this.calcCircle(degree))
      }
      return m
    },
    pointsLocationsOutter () {
      let m = new Map()
      for (const [k, v] of this.points) {
        m.set(k, this.calcCircle(v, this.radius * 2))
      }
      return m
    },
    pointsLocationsInner () {
      let m = new Map()
      for (const [k, v] of this.points) {
        m.set(k, this.calcCircle(v, this.radius / 2))
      }
      return m
    },
    pointsLocations () {
      let m = new Map()
      for (const [k, v] of this.points) {
        m.set(k, this.calcCircle(v), this.radius / 1.4)
      }
      return m
    },
    fontSizeHalf () {
      return `${this.height / 128}em`
    },
    fontSize () {
      return `${this.height / 64}em`
    },
    fontStyleHalf () {
      return {
        'fill': '#ffffff',
        'stroke-width': this.radius / 128,
        'stroke': 'black',
        'font-weight': 'bold',
        'dominant-baseline': 'central',
        'alignment-baseline': 'middle',
        'text-anchor': 'middle',
        'font-size': this.fontSizeHalf
      }
    },
    fontStyle () {
      return {
        'fill': '#ffffff',
        'stroke': 'black',
        'font-weight': 'bold',
        'stroke-width': this.radius / 64,
        'dominant-baseline': 'central',
        'alignment-baseline': 'middle',
        'text-anchor': 'middle',
        'font-size': this.fontSize
      }
    },
    topCenter () {
      let { radius } = this
      return {
        x: radius,
        y: 0
      }
    },
    bottomCenter () {
      let { radius, height } = this
      return {
        x: radius,
        y: height
      }
    },
    charLocation () {
      let { height } = this
      return {
        x: 0,
        y: height
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

@keyframes example
  from
    fill $info
  to
    fill $accent

.pulse-ready
  animation-name example
  animation-duration 3s
  animation-fill-mode forwards
</style>
