<template>
  <l-marker ref="marker"
    v-if="geohashLatLng"
    :lat-lng="geohashLatLng"
  >
    <l-icon ref="icon"
      :key="defaultKey"
      :iconSize="iconSize"
      :iconAnchor="iconAnchor"
    >
      <l-icon-wrapper
        v-on:ready="iconReady"
        component="dummy"
      >
        <div class="icons-top-row row justify-center">
          <template v-for="(item, index) in topButtons">
            <q-btn v-if="item"
              :key="`btn-${item}-${index}`"
              :icon="`img:icons/icon-${item}.svg`"
              @click="$emit('click', {
                name: $options.name,
                selected: item,
                transferDataValid,
                mapView
              })"
              :color="color"
              size="lg"
              dense
              flat
            />
            <q-icon v-else
              :key="`icon-${item}-${index}`"
              name="img:icons/marker-default.svg"
              class="q-mx-sm invisible"
              :color="color"
              size="lg"
              dense
            />
          </template>
        </div>
        <div class="icons-middle-row row justify-center">
          <template v-for="(item, index) in middleButtons">
            <q-btn v-if="item"
              :key="`btn-${item}-${index}`"
              :icon="`img:icons/icon-${item}.svg`"
              @click="$emit('click', {
                name: $options.name,
                selected: item,
                transferDataValid,
                mapView
              })"
              :color="color"
              size="lg"
              dense
              flat
            />
            <q-icon v-else
              :key="`icon-${item}-${index}`"
              name="img:icons/marker-default.svg"
              class="q-mx-sm invisible"
              :color="color"
              size="lg"
              dense
            />
          </template>
        </div>
        <div class="icons-bottom-row row justify-center">
          <template v-for="(item, index)  in bottomButtons">
            <q-btn v-if="item"
              :key="`btn-${item}-${index}`"
              :icon="`img:icons/icon-${item}.svg`"
              @click="$emit('click', {
                name: $options.name,
                selected: item,
                transferDataValid,
                mapView
              })"
              :color="color"
              size="lg"
              dense
              flat
            />
            <q-icon v-else
              :key="`icon-${item}-${index}`"
              name="img:icons/marker-default.svg"
              class="q-mx-sm invisible"
              :color="color"
              size="lg"
              dense
            />
          </template>
        </div>
      </l-icon-wrapper>
    </l-icon>
  </l-marker>
</template>

<script>
import Vue from 'vue'
import {
  defaultIcon
} from '.'
export default {
  name: 'searchIcon',
  extends: { ...defaultIcon },
  props: {
    middleButtons: {
      type: Array,
      default () {
        return [
          'view',
          null,
          'browse'
        ]
      }
    },
    bottomButtons: {
      type: Array,
      default () {
        return [
          null,
          null,
          null
        ]
      }
    },
    topButtons: {
      type: Array,
      default () {
        return [
          null,
          'docr',
          null
        ]
      }
    },
    color: {
      type: String,
      default: 'neutral'
    },
    iconAnchor: {
      type: Array,
      default: () => [90, 68]
    },
    iconSize: {
      type: Array,
      default: () => [0, 0]
    }
  },
  methods: {
    iconReady (component, selector) {
      let rect = component.$el.getBoundingClientRect()
      component.$el.style.width = `180px`
      component.$el.style.height = `240px`
    }
  }
}
</script>

<style lang="stylus">
.icons-top-row
  background-color $accent-4
  border-top-left-radius 25px
  border-top-right-radius 25px
  border-style ridge
  border-bottom-width 0px
  border-top-width 1px

.icons-middle-row
  background-image url("/icons/marker-default.svg")
  background-repeat no-repeat
  background-position center center
  background-color $accent-4
  border-style ridge
  border-top-width 0px
  border-bottom-width 0px
  border-left-width 2px
  border-right-width 2px

.icons-middle-row-center
  border-style solid
  border-width 1px

.icons-bottom-row
  background-color $accent-4
  border-bottom-left-radius 25px
  border-bottom-right-radius 25px
  border-style ridge
  border-top-width 0px
  border-bottom-width 1px

</style>
