<template>
  <svg width="24" height="24" version="1.1" viewBox="0 0 6.35 6.35" xmlns="http://www.w3.org/2000/svg">
   <g transform="translate(0 -290.65)">
    <path ref="outline"
      v-bind="outlineStyleDynamic"
      vector-effect="non-scaling-stroke"
      d="m3.175 296.52s-2.2485-3.76-1.5875-4.7625c0.66105-1.0025 2.514-1.0025 3.175 0 0.66105 1.0025-1.5875 4.7625-1.5875 4.7625z" />
      <path ref="body"
        v-bind="bodyStyleDynamic"
        vector-effect="non-scaling-stroke"
        d="m3.175 296.47s-2.2485-3.76-1.5875-4.7625c0.66105-1.0025 2.514-1.0025 3.175 0 0.66105 1.0025-1.5875 4.7625-1.5875 4.7625z"
      />
    <circle ref="circle"
      v-bind="circleStyleDynamic"
      vector-effect="non-scaling-stroke"
      cx="3.201"
      cy="292.34"
      r=".75595"
    />
   </g>
  </svg>
</template>

<script>
export default {
  name: 'DefaultMarker',
  props: {
    bodyStyle: {
      type: Object,
      default () {
        return {
          'fill': '#8baab0',
          'stroke': '#246673',
          'strike-width': '.25'
        }
      }
    },
    outlineStyle: {
      type: Object,
      default () {
        return {
          'fill': 'none',
          'stroke': '#f7fcfc',
          'strike-width': '.50'
        }
      }
    },
    circleStyle: {
      type: Object,
      default () {
        return {
          'fill': '#085462'
        }
      }
    }
  },
  computed: {
    circleStyleDynamic () {
      return Object.assign({}, {
        'fill': '#085462'
      }, this.circleStyle)
    },
    bodyStyleDynamic () {
      return Object.assign({}, {
        'fill': '#8baab0',
        'stroke': '#246673',
        'strike-width': '.25px'
      }, this.bodyStyle)
    },
    outlineStyleDynamic () {
      return Object.assign({}, {
        'fill': 'none',
        'stroke': '#f7fcfc',
        'strike-width': '.50px'
      }, this.outlineStyle)
    }
  }
}
</script>
