<template>
  <div>
    <template v-for="(item, index) in configFields">
      <q-input :value="deviceId[item.field]"
        :key="`input-${index}`"
        @input="val => updateDeviceId(val, item.field)"
        color="white"
        debounce="500"
        :label="item.label"
        :disable="item.disable"
        :standout="item.disable"
        :outlined="!item.disable"
        :autofocus="item.autofocus"
      />
    </template>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'ConfigDeviceId',
  props: {
    config: Array
  },
  data () {
    return {
      configFields: [
        {
          'autofocus': true,
          'label': 'Device Name',
          'disable': false,
          'field': 'name'
        },
        {
          'label': 'Device Uid (local)',
          'disable': process.env.PROD,
          'field': 'uid'
        },
        {
          'label': 'Device Hash (anonymous)',
          'disable': process.env.PROD,
          'field': 'deviceHash'
        }
      ]
    }
  },
  methods: {
    async updateDeviceId (val, field) {
      this.deviceId = Object.assign({}, this.deviceId, { [field]: val })
    }
  },
  computed: {
    deviceId: sync('layout/deviceId')
  }
}
</script>
