const toRoot = (line) => {
  let {
    docr,
    title,
    searchText,
    geohash
  } = line
  return {
    docr,
    title,
    searchText,
    geohash
  }
}
const toTimestamp = (line) => {
  let {
    begin,
    pivot,
    cease,
    exist
  } = line
  return {
    begin,
    pivot,
    cease,
    exist
  }
}
const toMetadata = (line) => {
  let {
    tags,
    resources
  } = line
  return {
    tags,
    resources
  }
}

const abLine = (line) => {
  let root = toRoot(line)
  root.timestamp = toTimestamp(line)
  root.metadata = toMetadata(line)
  return root
}

export default abLine
