<template>
  <q-bar class="full-width q-px-xs row justify-between">
    <q-btn @click="page -= 1"
      :class="page === 1 ? 'invisible' : ''"
      icon="fa fa-chevron-left"
      dense
      flat
    />
    <q-space/>
    <input v-model.trim="page" type="number" class="full-height input-page"/>
    <q-btn-dropdown ref="dropdown"
      color="accent"
      label="#"
      dense
      flat
    >
      <div class="full-width">
        <q-icon name="fa fa-comment" class="q-mx-sm"/>
        <template v-for="(limit) in messageChunkSizes">
          <q-btn :label="limit"
            class="text-bold"
            :color="pageLimit === limit ? 'positive' : 'secondary'"
            @click="pageLimit = limit"
            :key="limit"
            flat
          />
        </template>
      </div>
    </q-btn-dropdown>
    <q-space/>
    <q-btn @click="page += 1"
      icon="fa fa-chevron-right"
      :class="page === pageMax ? 'invisible' : ''"
      dense
      flat
    />
  </q-bar>
</template>

<script>
import messagesMixin from './messagesMixin'
import { sync } from 'vuex-pathify'
export default {
  name: 'wireMessagesControls',
  mixins: [
    messagesMixin
  ],
  props: {
    query: Object
  },
  computed: {
    orderBy: {
      get () {
        return this.messagesSettings.orderBy
      }
    }
  },
  asyncComputed: {
    async messageCount () {
      return this.$WireWorker
        .countData({
          query: this.query
        }).then(({ result }) => result)
    },
    async messageKeys () {
      return this.$WireWorker
        .getKeys({
          reverse: true,
          orderBy: this.orderBy,
          query: this.query,
          offset: this.pageOffset,
          limit: this.pageLimit
        }).then(({ result }) => result)
    }
  }
}
</script>

<style lang="stylus">
input.input-page::-webkit-outer-spin-button,
input.input-page::-webkit-inner-spin-button
  -webkit-appearance none
  margin 0

input.input-page[type=number]
  -moz-appearance textfield

.input-page
  margin: 0
  background-color $accent
  border-radius 30% 30% 30% 30%
  border 2px solid $secondary
  max-width 2em
  max-height 2em
  font-size 0.8em
  font-weight bold
  text-align center
</style>
