import { Type, Presence } from 'sculp'
export const enums = [
  'rotate(0deg)',
  'rotate(45deg)',
  'rotate(90deg)',
  'rotate(135deg)',
  'rotate(180deg)',
  'rotate(205deg)',
  'rotate(270deg)',
  'rotate(315deg)'
]
export const schema = {
  type: Type.STRING_NET,
  initial: enums[0],
  transform (v) {
    return enums.indexOf(v) === -1
      ? enums[0]
      : v
  },
  $presence: Presence.REQUIRED,
  meta: 'Enumeration of flip classes'
}
