import {
  WORKER_NAME,
  SCHEMA_NAME,
  SCHEMA_SPEC,
  PRIMARY_KEY,
  STORE_KEY,
  COLUMNS
} from '../constants'

export default {
  dbDoc: null,
  dbDocOthers: {},
  dbDocStats: {},
  timestamp: new Date().valueOf(),
  newRecordHandler: null,
  newRecordCount: 0,
  peers: {},
  files: {},
  sync: {
    synced: null,
    meta: {},
    properties: {}
  },
  [STORE_KEY]: { // storeKey
    schemaSpec: SCHEMA_SPEC,
    workername: WORKER_NAME,
    schema: SCHEMA_NAME, // dexiejs table
    columns: COLUMNS,
    key: PRIMARY_KEY, // primary key for the offline dexie.jks
    sum: null, // required props on storekey
    ready: false, // is set once the schema is ready
    db: '__local' // dexiejs database
  }
}
