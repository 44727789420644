import {
  DexieWorkerInterface
} from '../DexieWorkerInterface'
export class IdentityWorkerHandlersInterface extends DexieWorkerInterface {
  async createWebPush ({
    idHash,
    jwtToken,
    deviceHash,
    subscription
  }, opts = {
    clientMutationId: 'createWebPush'
  }) {
    let message = {
      handler: 'createWebPush',
      idHash,
      jwtToken,
      subscription,
      deviceHash,
      ...opts
    }
    let { result } = await this.message(message)
    if (result.error) {
      console.warn(result.error)
      return null
    }
    return result
  }
  async updateWebPush ({
    nodeId,
    jwtToken,
    subscription
  }, opts = {
    clientMutationId: 'updateWebPush'
  }) {
    let message = {
      handler: 'updateWebPush',
      nodeId,
      jwtToken,
      subscription,
      ...opts
    }
    let { result } = await this.message(message)
    if (result.error) {
      console.warn(result.error)
      return null
    }
    return result
  }
  async webPushByIdHashAndDeviceHash ({
    idHash,
    jwtToken,
    deviceHash
  }, opts = {
    clientMutationId: 'webPushByIdHashAndDeviceHash'
  }) {
    let message = {
      handler: 'webPushByIdHashAndDeviceHash',
      idHash,
      jwtToken,
      deviceHash,
      ...opts
    }
    let { result } = await this.message(message)
    if (result.error) {
      console.warn(result.error)
      return null
    }
    return result
  }
  async webPushNotificationSync ({
    idHash,
    idRoute,
    deviceHash,
    jwtToken,
    deviceName
  }, opts = {
    clientMutationId: 'webPushNotificationSync'
  }) {
    let message = {
      handler: 'webPushNotificationSync',
      idHash,
      idRoute,
      jwtToken,
      deviceHash,
      message: {
        handler: 'sendSyncNotificationRequest',
        tag: 'syncRequest',
        from: {
          idHash,
          idRoute,
          deviceHash,
          deviceName
        }
      },
      ...opts
    }
    let { result } = await this.message(message)
    if (result.error) {
      console.warn(result.error)
      return null
    }
    return result
  }
  async deleteWebPush ({
    idHash,
    jwtToken,
    deviceHash
  }, opts = {
    clientMutationId: 'createUser'
  }) {
    let message = {
      handler: 'deleteWebPush',
      idHash,
      jwtToken,
      deviceHash,
      ...opts
    }
    let { result } = await this.message(message)
    if (result.error) {
      console.warn(result.error)
      return null
    }
    return result
  }
  async createUser (identity, opts = {
    clientMutationId: 'createUser'
  }) {
    let message = {
      handler: 'createUser',
      db: opts.dbName || this.dbName,
      schema: opts.schemaName || this.schemaName,
      identity,
      ...opts
    }
    let { result, data } = await this.message(message)
    return {
      result: result[0],
      data: data[0]
    }
  }
  async verifyIdHash ({
    identity,
    jwtToken,
    idHash
  }, opts = {}) {
    let message = {
      handler: 'verifyIdHash',
      identity,
      jwtToken,
      idHash,
      ...opts
    }
    let { result } = await this.message(message)
    if (result.error) {
      console.warn(result.error)
      return null
    }
    return result
  }
}
