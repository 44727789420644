import wirePeers from './components/wirePeers'
import wireConnectSync from './components/wireConnectSync'
import wireSendMessage from './components/wireSendMessage'
import wireMessagesLog from './components/wireMessagesLog'
import wireMessagesControls from './components/wireMessagesControls'
import {
  SCHEMA_SPEC,
  PRIMARY_KEY,
  SCHEMA_NAME,
  WORKER_NAME
} from './constants'

import {
  WireWorkerInterface
} from './WireWorkerInterface'
import {
  workerHandlers
} from 'geodocr-dexie'

const Worker = require(`worker-loader?name=wire-worker.js!./worker.js`)

export const plugin = ({ app, router, Vue, store, schemaSpec }) => {
  let worker = new WireWorkerInterface(Worker, {
    vm: store._vm,
    store,
    storeKey: SCHEMA_NAME,
    schemaName: SCHEMA_NAME,
    primaryKey: PRIMARY_KEY,
    schemaSpec
  })
  Vue.prototype[WORKER_NAME] = worker
  Vue.component('wire-peers', wirePeers)
  Vue.component('wire-send-message', wireSendMessage)
  Vue.component('wire-messages-log', wireMessagesLog)
  Vue.component('wire-connect-sync', wireConnectSync)
  Vue.component('wire-messages-controls', wireMessagesControls)
  return worker
}
export store from './store'
export const schemaSpecMerge = { [SCHEMA_NAME]: SCHEMA_SPEC }
export const schemaName = SCHEMA_NAME
export const workerName = WORKER_NAME
