export default {
  WirePayload: {
    fields: {
      type: {
        type: 'string',
        id: 1
      },
      message: {
        type: 'string',
        id: 2
      },
      icon: {
        type: 'string',
        id: 3
      },
      deviceName: {
        type: 'string',
        id: 4
      }
    }
  },
  WireRecord: {
    fields: {
      type: {
        type: 'string',
        id: 1
      },
      timestamp: {
        type: 'fixed64',
        id: 2
      },
      idHash: {
        type: 'string',
        id: 3
      },
      deviceHash: {
        type: 'string',
        id: 4
      },
      peerId: {
        type: 'string',
        id: 5
      },
      roomId: {
        type: 'string',
        id: 6
      },
      key: {
        type: 'string',
        id: 7
      },
      payload: {
        type: 'WirePayload',
        id: 8
      }
    }
  }
}
