import {
  DexieWorkerInterface
} from '../DexieWorkerInterface'
const cachedNamedCrs = {}
const defaultSpatialCrsArgs = {
  first: null,
  bypassCache: false,
  crsType: 'proj4',
  refType: 'epsg'
}
export class DexieWorkerCrsInterface extends DexieWorkerInterface {
  // allSpatialCrs
  async allSpatialCrs (args = defaultSpatialCrsArgs,
    opts = {
      schemaName: null,
      dbName: null
    }) {
    let self = this
    args = Object.assign({}, defaultSpatialCrsArgs, args)
    let message = {
      ...args,
      handler: 'allSpatialCrs',
      db: opts.dbName || this.dbName,
      schema: opts.schemaName || this.schemaName
    }
    let { result } = await this.message(message)
    return result
  }
  async calculateExtent (coord) {
    let self = this
    let message = {
      coord,
      handler: 'calculateExtent'
    }
    let { result } = await this.message(message)
    return result
  }
  async decodeNamedCrs (crs) {
    let self = this
    let release = await self.readLock(crs)
    if (cachedNamedCrs[crs]) {
      release()
      return cachedNamedCrs[crs]
    }
    let message = {
      crs,
      handler: 'decodeNamedCrs'
    }
    let { result } = await this.message(message)
    cachedNamedCrs[crs] = result
    release()
    return result
  }
  async encodeNamedCrs (crs) {
    let self = this
    let message = {
      crs,
      handler: 'encodeNamedCrs'
    }
    let { result } = await this.message(message)
    return result
  }
  async availableNamedCrs (filter) {
    let self = this
    let message = {
      filter,
      handler: 'availableNamedCrs'
    }
    let { result } = await this.message(message)
    return result
  }
  async transformInverse ({
    getExtent,
    proj4To,
    proj4From,
    coord
  }) {
    let self = this
    if (isObject(coord)) {
      coord = [coord.lng, coord.lat]
    }
    let message = {
      getExtent,
      proj4To,
      proj4From,
      coord,
      handler: 'transformInverse'
    }
    let { result } = await this.message(message)
    return result
  }
  async transformForward ({
    getExtent,
    proj4To,
    proj4From,
    coord
  }) {
    let self = this
    if (isObject(coord)) {
      coord = [coord.lng, coord.lat]
    }
    let message = {
      getExtent,
      proj4To,
      proj4From,
      coord,
      handler: 'transformForward'
    }
    let { result } = await this.message(message)
    return result
  }
  async translateForward ({
    getExtent,
    translations,
    coord
  }) {
    let self = this
    if (isObject(coord)) {
      coord = [coord.lng, coord.lat]
    }
    let message = {
      getExtent,
      translations,
      coord,
      handler: 'translateForward'
    }
    let { result } = await this.message(message)
    return result
  }
  async translateInverse ({
    getExtent,
    translations,
    coord
  }) {
    let self = this
    if (isObject(coord)) {
      coord = [coord.lng, coord.lat]
    }
    let message = {
      getExtent,
      translations,
      coord,
      handler: 'translateInverse'
    }
    let { result } = await this.message(message)
    return result
  }
}
