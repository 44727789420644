<template>
  <q-item :active="active" :style="messageStyle">
    <q-item-section>
      <q-item-label overline>
        {{localTimestamp}}
        <span :style="timeStyle">
          [{{timehash}}]
        </span>
      </q-item-label>
      <q-item-label>
        <q-icon :name="item.payload.icon"/>
        {{item.payload.deviceName}}
      </q-item-label>
      <q-separator/>
      <q-item-label caption>
        {{item.payload.message}}
      </q-item-label>
    </q-item-section>
  </q-item>
</template>

<script>
import { format } from 'date-fns'
export default {
  name: 'WireUserMessage',
  props: {
    item: Object,
    active: Boolean,
    messageStyle: Object
  },
  computed: {
    timehash () {
      return this.$timehash.encodems(this.item.timestamp)
    },
    timeColorHours () {
      return `#${this.timehash.slice(0, 6)}`
    },
    timeColorHalfhours () {
      return `#${this.timehash.slice(1, 7)}`
    },
    timeColorMinutes () {
      return `#${this.timehash.slice(2, 8)}`
    },
    timeColorHalfMinutes () {
      return `#${this.timehash.slice(3, 9)}`
    },
    timeColorSeconds () {
      return `#${this.timehash.slice(4, 10)}`
    },
    timeColorBackground () {
      let {
        timeColorHours,
        timeColorHalfhours,
        timeColorMinutes,
        timeColorHalfMinutes,
        timeColorSeconds
      } = this
      return `linear-gradient(to right, ${timeColorSeconds} 20%, ${timeColorHalfMinutes} 20%, ${timeColorHalfMinutes} 40%, ${timeColorMinutes} 40%, ${timeColorMinutes} 60%, ${timeColorHalfhours} 60%, ${timeColorHalfhours} 80%, ${timeColorHours} 80%) 5`
    },
    localTimestamp () {
      return format(
        new Date(this.item.timestamp),
        'yyyy-MM-dd HH:mm:ss'
      )
    },
    timeStyle () {
      return {
        'border-image': this.timeColorBackground,
        'border-bottom': '1px solid',
        'border-left-style': 'solid',
        'border-left-width': 'thick',
        'border-left-color': this.timeColorSeconds
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
pre
  white-space pre-wrap
  white-space -moz-pre-wrap
  white-space -pre-wrap
  white-space -o-pre-wrap
  word-wrap break-word

</style>
