import mediaRecordItems from './components/mediaRecordItems'
import {
  SCHEMA_SPEC,
  PRIMARY_KEY,
  SCHEMA_NAME,
  WORKER_NAME
} from './constants'
import {
  MediaWorkerInterface
} from './MediaWorkerInterface'
const Worker = require(`worker-loader?name=media-worker.js!./worker.js`)

export const plugin = ({ app, router, Vue, store, schemaSpec }) => {
  let worker = new MediaWorkerInterface(Worker, {
    vm: store._vm,
    workerCount: navigator.hardwareConcurrency,
    store,
    storeKey: SCHEMA_NAME,
    schemaName: SCHEMA_NAME,
    primaryKey: PRIMARY_KEY,
    schemaSpec
  })
  Vue.prototype[WORKER_NAME] = worker

  Vue.component('media-record-items', mediaRecordItems)
  return worker
}
export fields from './fields'
export store from './store'
export const schemaSpecMerge = { [SCHEMA_NAME]: SCHEMA_SPEC }
export const schemaName = SCHEMA_NAME
export const workerName = WORKER_NAME
