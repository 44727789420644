export const timestampEnum = [
  'exist',
  'begin',
  'pivot',
  'cease'
]
export const shareEnum = [
  'PRIVATE',
  'PUBLIC_SELECT',
  'PUBLIC_ALL'
]
export const encryptEnum = [
  'CLEAR',
  'SHA256',
  'MD5',
  'RIPEMD',
  'SHA1',
  'SHA224',
  'SHA384',
  'SHA512'
]
export const licenseEnum = [
  'CR',
  'CC0',
  'CC-BY',
  'CC-BY-NC',
  'CC-BY-NC-ND',
  'CC-BY-NC-SA',
  'CC-BY-SA',
  'CC-BY-ND'
]
export const namedCrsKandiyohi = {
  name: 'Kandiyohi County Landfill System',
  epsg: 'EPSG:103641',
  proj4: '+proj=lcc +lat_1=44.96666666666667 +lat_2=45.33333333333334 +lat_0=44.89138888888889 +lon_0=-94.75 +x_0=152400.3048006096 +y_0=30480.06096012192 +a=6378498.189 +b=6357112.29214201 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs',
  units: 'us-ft',
  matrix: {
    name: 'Landfill Coordinates',
    translations: [{
      translate: [-433244.9375, -242642.375]
    },
    {
      rotateDeg: -0.21
    },
    {
      translate: [5000.0, 5000.0]
    }]
  }
}
export const namedCrsDefault = {
  name: 'WGS 84 (long/lat)',
  epsg: 'EPSG:4326',
  proj4: '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees',
  units: 'degrees',
  matrix: {}
}
