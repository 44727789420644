<template>
  <div :class="dynamicClassContainer"
    :style="dynamicStyle"
  >
    <l-map @leaflet:load="mapLoaded"
      v-if="mapCenter"
      ref="map"
      :maxZoom="maxZoom"
      :minZoom="minZoom"
      :options="dynamicMapOptions"
      :zoom="mapZoom"
      :center="mapCenter"
    >
      <slot name="base"
        :center="mapCenter"
        :zoom="mapZoom"
      ></slot>
      <slot name="features"
        :center="mapCenter"
        :zoom="mapZoom"
      ></slot>
      <slot
        :center="mapCenter"
        :zoom="mapZoom"
      ></slot>
    </l-map>
    <div class="absolute-bottom-left z-max no-print">
      <q-btn icon="fa fa-layer-group" color="primary" label="Base Layers">
        <q-menu>
          <q-list style="min-width: 100px" class="text-bold bg-secondary">
            <template v-for="(item) in $leaflet.tileProviders">
              <q-item @click="$emit('layer:provider', item)"
                :active="baseLayer.some(l => item.name.startsWith(l + '-'))"
                :key="`tile-layer-${item.name}`"
                v-close-popup
                clickable>
                <q-item-section avatar>
                  <q-icon color="primary" name="fa fa-globe-americas" />
                </q-item-section>
                <q-item-section>{{item.title}}</q-item-section>
                <q-item-section avatar>
                  <q-icon v-if="baseLayer.some(l => item.name.startsWith(l + '-'))"
                    color="positive"
                    name="fa fa-check-square"
                  />
                  <q-icon v-else
                    color="info"
                    name="fa fa-square"
                  />
                </q-item-section>
              </q-item>
            </template>
          </q-list>
        </q-menu>
      </q-btn>
      <q-fab icon="img:icons/add-map-items.svg"
        direction="right"
        color="icon"
        flat
      >
        <q-fab-action @click="$emit('toggle:editor')"
          icon="fa fa-drafting-compass"
          color="primary"
        />
        <q-fab-action @click="locate"
          color="primary"
          icon="fa fa-street-view"
        >
          <q-icon name="fa fa-crosshairs" size="xs" />
        </q-fab-action>
      </q-fab>
    </div>
    <div class="z-max no-print absolute-top-right flex column"
    style="top:32px">
      <pm-layer-control
        :layers="pmLayers"
        :parentVm="parentVm"
        class="q-ma-sm"
        :map="map"
      />
      <pm-missing-control
        :layers="missingLayers"
        :parentVm="parentVm"
        class="q-ma-sm"
        :map="map"
      />
    </div>
    <div class="absolute-bottom-right q-my-md row"
      style="z-index:500;">
      <select-standard-scale v-if="map"
        @scale="scale => $emit('scale', scale)"
        :map="map"
        :mapUnits="mapUnits"
        :scaleLockTarget.sync="scaleModel"
        :scaleLock.sync="scaleLockModel"
        :paperUnits="paperUnits"
        :paperUnitScaleSize="paperUnitScaleSize"
      />
      <img src="icons/north-arrow.svg" height="64" class="q-px-sm"/>
    </div>
    <q-banner class="absolute-bottom-left text-bold z-max q-ma-none flash-message-border"
      v-if="attentionMessage"
      inline-actions
      dense
    >
      {{attentionMessage}}
      <template v-slot:action>
        <q-btn flat @click="attentionMessageCancel"
          color="negative"
          label="Cancel"
          dense
        />
      </template>
    </q-banner>
    <q-resize-observer
      :debounce="500"
      @resize="refreshMapSize"
    />
  </div>
</template>

<script>
import geohash from 'geodocr-geohash'
import { debounce } from 'quasar'
import { get } from 'vuex-pathify'
import { defaultMapOptions } from '../constants'
import pmLayerControl from './controls/pmLayerControl'
import pmMissingControl from './controls/pmMissingControl'
import mixinLeafletGeoman from './mixins/mixinLeafletGeoman'
import selectStandardScale from './controls/selectStandardScale'
export default {
  name: 'GeodocrMainMap',
  components: {
    pmLayerControl,
    selectStandardScale,
    pmMissingControl
  },
  mixins: [
    mixinLeafletGeoman
  ],
  props: {
    baseLayer: {
      type: Array,
      default: () => ['OSM']
    },
    mapUnits: {
      type: String,
      default: 'ft'
    },
    scale: Number,
    scaleLock: Boolean,
    paperUnits: {
      type: String,
      default: 'in'
    },
    paperUnitScaleSize: {
      type: Number,
      default: 1
    },
    parentVm: Object,
    editorPopupComponent: {
      type: String,
      default: () => 'metadata-collector'
    },
    containerStyle: {
      type: Object,
      default: () => { return {} }
    },
    containerClasses: {
      type: Array,
      default: () => { return [] }
    },
    mapOptions: {
      type: Object,
      default: defaultMapOptions
    },
    minZoom: {
      type: Number,
      default () {
        return 2
      }
    },
    maxZoom: {
      type: Number,
      default () {
        return 26
      }
    },
    zoom: {
      type: Number,
      default () {
        return 3
      }
    },
    center: {
      type: Object,
      default () {
        return {
          lat: 17.7501482, // Marianas Trench [17.7501482,142.4988842]
          lng: 142.4988842
        }
      }
    }
  },
  data () {
    return {
      map: null,
      attentionMessage: null,
      attentionMessageCancel: null,
      direction: 'west',
      mapZoom: null,
      mapBounds: null,
      mapCenter: null,
      tileProviders: [],
      tileLayers: [],
      headerStyle: {},
      footerStyle: {}
    }
  },
  watch: {
    zoom (val, oldVal) {
      if (val !== undefined) {
        this.mapZoom = val
      }
    },
    center (val, oldVal) {
      if (val) {
        this.map.flyTo(val)
        this.mapCenter = val
      }
    }
  },
  mounted () {
    let vm = this
    vm.mapCenter = vm.center
    vm.mapZoom = vm.zoom
    vm.refreshMapSize = debounce(vm.refreshMapSize, 500)
    vm.$on('refresh', vm.refreshMapSize)
    vm.$on('refresh:base', vm.refreshMapBase)
  },
  methods: {
    locateSuccess (e) {
      let { L } = this.$leaflet
      let radius = e.accuracy
      L.marker(e.latlng)
        .addTo(this.map)
        .bindPopup('You are within ' + radius + ' meters from this point')
        .openPopup()
      L.circle(e.latlng, radius).addTo(this.map)
    },
    locateError () {},
    locate () {
      if (this.map) {
        this.map.locate({
          setView: true,
          minZoom: this.minZoom,
          maxZoom: this.maxZoom
        })
      }
    },
    refreshMapSize () {
      if (this.map) this.map.invalidateSize()
    },
    refreshMapBase () {
      if (this.map) this.$leaflet.refresh(this.map, this.baseLayer)
    },
    mapLoaded () {
      let vm = this
      vm.map = vm.$refs.map.mapObject
      // fix weird rendering bug with leaflet-map-pane at 400z
      vm.map.getPane('overlayPane').style.zIndex = 401
      vm.map.createPane('offline')
      vm.$emit('leaflet:load', vm.map)
      vm.map.on('remove', () => {
        console.log('remove', vm.map)
      })
      vm.map.on('locationerror', this.locateError)
      vm.map.on('locationfound', this.locateSuccess)
      vm.map.on('moveend', this.moveendHandler)
      vm.map.on('click', e => vm.$emit('click', e))
      vm.map.on('dblclick', e => vm.$emit('dblclick', e))
      vm.map.on('move', e => {
        let center = vm.map.getCenter()
        let bounds = vm.map.getBounds()
        if (vm.mapCenter) {
          let change = vm.mapCenter.lng - center.lng
          vm.direction = change > 0 ? 'west' : 'east'
          if (Math.abs(change) > 300) vm.$emit(`move:${vm.direction}`, change)
        }
        vm.mapCenter = center
        vm.mapBounds = bounds
      })
      vm.$leaflet.refresh(vm.map, vm.baseLayer)
      vm.$emit('update', {
        center: vm.map.getCenter(),
        bounds: vm.map.getBounds(),
        zoom: vm.map.getZoom()
      })
    },
    moveendHandler (e) {
      // quickfix https://github.com/Leaflet/Leaflet/issues/2125
      let vm = this
      vm.map.off('moveend', this.moveendHandler)
      vm.updateTiles(e.target)
      vm.$emit('update', {
        center: vm.map.getCenter(),
        bounds: vm.map.getBounds(),
        zoom: vm.map.getZoom()
      })
      vm.$nextTick(() => {
        vm.map.on('moveend', this.moveendHandler)
      })
      vm.mapZoom = vm.map.getZoom()
    },
    updateTiles (e) {
      let vm = this
      let bounds = vm.map.getBounds()
      let newBounds = vm.$leaflet.L.CRS.EPSG3857.wrapLatLngBounds(bounds)
      let ne = bounds.getNorthEast()
      if (
        ne.lng - newBounds.getNorthEast().lng === 360 ||
        ne.lng - newBounds.getNorthEast().lng === -360
      ) {
        vm.map.fitBounds(newBounds, {
          duration: 0,
          noMoveStart: true
        })
      }
    }
  },
  computed: {
    scaleModel: {
      get () { return this.scale },
      set (val) {
        this.$emit('update:scale', val)
      }
    },
    scaleLockModel: {
      get () { return this.scaleLock },
      set (val) {
        this.$emit('update:scaleLock', val)
      }
    },
    routeGeohash () {
      return (this.$route && this.$route.params.geohash)
        ? this.$route.params.geohash
        : null
    },
    dynamicMapOptions () {
      return Object.assign({}, this.mapOptions, defaultMapOptions())
    },
    dynamicStyle () {
      return Object.assign({
        'border-width': '1px',
        'border-style': 'groove',
        'border-color': 'black'
      }, this.containerStyle)
    },
    dynamicClassContainer () {
      return [
        'leaflet-main-map',
        'shadow-4'
      ].concat(this.containerClasses)
    }
  },
  beforeDestroy () {
    this.$leaflet.close()
  }
}
</script>

<style lang="stylus">
.leaflet-container
  font-size 1em
  font-family "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace!important
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  font-weight 500
  letter-spacing 0.0325em

.geohash-bounding-box-text
  background-color $neutral-1

.leaflet-overlay-pane /* fixes 1.6.0 */
  z-index 401

.leaflet-left .leaflet-control
    margin-left 10px
    margin-top 40px

.geodocr-map-tooltip
  width 120px
  overflow-wrap break-word
  word-wrap break-word
  word-break break-all
  white-space -moz-pre-wrap !important  /* Mozilla, since 1999 */
  white-space -pre-wrap      /* Opera 4-6 */
  white-space -o-pre-wrap    /* Opera 7 */
  white-space pre-wrap       /* css-3 */
  word-wrap break-word       /* Internet Explorer 5.5+ */
  white-space -webkit-pre-wrap /* Newer versions of Chrome/Safari*/
  word-break break-all
  white-space normal

.flash-message-border
  outline 2px dashed $primary-1
  box-shadow 0 0 0 2px $warning
  animation 1s animateBorder infinite
@keyframes animateBorder
  to
    outline-color $warning
    box-shadow 0 0 0 3px $primary-5

</style>
