import {
  flipEnums,
  rotateEnums,
  flipValidate,
  rotateValidate
} from '../../models/images'

import {
  DxfWorkerInterface
} from './DxfWorkerInterface'

export const defaultOpts = {
  vm: null,
  store: null,
  schemaSpec: null,
  schemaName: null
}

export class MediaWorkerInterface extends DxfWorkerInterface {
  constructor (Worker, opts = defaultOpts) {
    super(Worker, opts)
  }
  async resizeImage (file, opts = {
    height: 240
  }) {
    let message = {
      handler: 'resizeImage',
      file,
      ...opts
    }
    let { result } = await this.message(message)
    return result
  }
  async getTags (buffer, opts = {
    precision: 10
  }) {
    let message = {
      handler: 'getGeotags',
      buffer,
      ...opts
    }
    let { result } = await this.message(message)
    return result
  }
  api () {
    let self = this
    return {
      flipEnums,
      flipValidate,
      rotateEnums,
      rotateValidate,
      dxfLayerEntities: self.dxfLayerEntities.bind(self),
      dxfBlockEntities: self.dxfBlockEntities.bind(self),
      dxfEntities: self.dxfEntities.bind(self),
      dxfLoad: self.dxfLoad.bind(self),
      getBuffer: self.getBuffer.bind(self),
      fetchBlob: self.fetchBlob.bind(self),
      fetchBuffer: self.fetchBuffer.bind(self),
      resizeImage: self.resizeImage.bind(self),
      selections: () => {
        return Object.keys(self.store.get(`${self.storeKey}/selections@images`))
      },
      getImageSelection: (hid) => {
        let selection = self.store.get(`${self.storeKey}/selections@images`)
        return selection[hid] || false
      },
      setImageSelection: (hid, value) => {
        let selection = {
          ...self.store.get(`${self.storeKey}/selections@images`)
        }
        selection[hid] = value
        self.store.set(`${self.storeKey}/selections@images`, selection)
      },
      getTags: (buffer) => self.getTags(buffer),
      dig (query) {
        return self.digData({
          dig: query
        })
      },
      digTitle (query) {
        return self.digData({
          dig: { title: query }
        })
      },
      async getGeohash (buffer, opts = {
        precision: 10
      }) {
        let result = await self.getTags(buffer)
        return result && result[0]
          ? result[0].geohash
          : null
      }
    }
  }
}
