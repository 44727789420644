import { validate } from 'sculp'
class DataWrapper {
  constructor (
    state,
    data,
    model,
    remotes = [],
    keys = {
      uid: undefined,
      hid: undefined,
      jid: undefined,
      publicJwk: undefined,
      privateJwk: undefined
    }
  ) {
    let self = this
    self.ab = {
      'json': d => JSON.parse(d),
      'object': d => JSON.parse(JSON.stringify(d)),
      'jsonline': (d, model) => model.abLine(JSON.parse(d)),
      'line': (d, model) => JSON.parse(JSON.stringify(model.abLine(d))),
      'jsonEncrypted': (d, model, keys) => JSON.parse(model.decrypt(d, keys.publicJwk)),
      'lineEncrypted': (d, model, keys) => model.abLine(JSON.parse(model.decrypt(d, keys.publicJwk)))
    }
    self.to = {
      'json': d => JSON.stringify(d),
      'object': d => JSON.parse(JSON.stringify(d)),
      'jsonline': (d, model) => JSON.stringify(model.toLine(d)),
      'line': (d, model) => JSON.parse(JSON.stringify(model.toLine(d))),
      'jsonEncrypted': (d, model, keys) => model.encrypt(JSON.stringify(d), keys.privateJwk),
      'lineEncrypted': (d, model, keys) => model.encrypt(JSON.stringify(model.toLine(d)), keys.privateJwk)
    }
    self.dirty = false
    self._model = model
    self._keys = keys
    self._data = self.ab[state](data, self._model, self._keys)
    self._state = 'object'
    self._remotes = remotes
    self.promises = []
  }
  toLine () {
    let self = this
    return self.to.line(self._data, self._model)
  }
  abLine (value) {
    let self = this
    let data = self.ab.line(value, self._model)
    let valid = self._setDataLocal(data)
    if (valid) self._setDataRemote(data)
  }
  toJsonline () {
    let self = this
    return self.to.jsonline(self._data, self.model)
  }
  abJsonline (value) {
    let self = this
    let data = self.ab.jsonline(value, self.model)
    let valid = self._setDataLocal(data)
    if (valid) self._setDataRemote(data)
  }
  toLineEncrypted () {
    let self = this
    return self.to.lineEncrypted(self._data)
  }
  abLineEncrypted (value) {
    let self = this
    let data = self.ab.lineEncrypted(value, self.model, self.keys)
    let valid = self._setDataLocal(data)
    if (valid) self._setDataRemote(data)
  }
  toJsonEncrypted () {
    let self = this
    return self.to.jsonEncrypted(self._data)
  }
  abJsonEncrypted (value) {
    let self = this
    let data = self.ab.jsonEncrypted(value, self.model, self.keys)
    let valid = self._setDataLocal(data)
    if (valid) self._setDataRemote(data)
  }
  toJson () {
    let self = this
    return self.to.json(self._data)
  }
  abJson (value) {
    let self = this
    let data = self.ab.json(value, self.model)
    let valid = self._setDataLocal(data)
    if (valid) self._setDataRemote(data)
  }
  toObject () {
    let self = this
    return self.to.object(self._data)
  }
  abObject (value) {
    let self = this
    let data = self.ab.object(value, self.model)
    let valid = self._setDataLocal(data)
    if (valid) self._setDataRemote(data)
  }
  validate (value) {
    return validate(value, this.model.schema)
  }
  async remoteResponses () {
    let self = this
    let responses = await Promise.all(self.promises)
    self.promises = []
    return responses
  }
  _setDataLocal (value) {
    let self = this
    if (self.validate(value)) {
      self._data = value
      self.invalid = false
    } else {
      console.warning('NotValid', value)
      self.invalid = true
    }
    return self.invalid
  }
  _setDataRemote (value) {
    let self = this
    for (const remote of self._remotes) {
      let promise = new Promise(async (resolve, reject) => {
        let response = await remote.send(self[remote.sendDataAs](), self.keys())
        resolve(response)
      })
      self.promises.push(promise)
    }
  }
  set data (value) {
    let self = this
    let invalid = self._setDataLocal(value)
    if (!invalid && self._remotes.length > 0) self._setDataRemote(value)
  }
  get clone () {
    return Object.assign(Object.create(this), this)
  }
  get uid () {
    let self = this
    let { uid } = self._keys
    if (!uid) {
      uid = self.model.key.uid(self._data)
      self._keys.uid = uid
    }
    return uid
  }
  hid () {
    let self = this
    let { hid } = self._keys
    hid = self.model.key.hid(self._data)
    self._keys.hid = hid
    return hid
  }
  get privateJwk () {
    let self = this
    let { privateJwk } = self._keys
    return privateJwk
  }
  get publicJwk () {
    let self = this
    let { publicJwk } = self._keys
    return publicJwk
  }
  keys () {
    return {
      hid: this.hid(),
      uid: this.uid,
      publicJwk: this.publicJwk,
      privateJwk: this.privateJwk
    }
  }
  get state () {
    return this._state
  }
  get model () {
    return this._model
  }
}

export default DataWrapper
