export default {
  IdentityAttributes: {
    fields: {
      name: {
        type: 'string',
        id: 1
      },
      email: {
        type: 'string',
        id: 2
      },
      telephone: {
        type: 'string',
        id: 3
      },
      projectName: {
        type: 'string',
        id: 4
      }
    }
  },
  IdentityRecord: {
    fields: {
      type: {
        type: 'string',
        id: 1
      },
      idHash: {
        type: 'string',
        id: 2
      },
      exist: {
        type: 'string',
        id: 3
      },
      identity: {
        type: 'IdentityAttributes',
        id: 4
      },
      userSecretKey: {
        type: 'string',
        id: 5
      },
      serverPublicKey: {
        type: 'string',
        id: 6
      },
      jwtToken: {
        type: 'string',
        id: 7
      }
    }
  }
}
