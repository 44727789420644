const DEFAULT_CONTENT_TEXT = (opts = { text: null }) => {
  return {
    'type': 'paragraph',
    'content': [
      {
        'type': 'text',
        'text': opts.text || 'With a docr, you can share your world'
      }
    ]
  }
}
const UNKNOWN_CONTENT_TITLE = () => {
  return {
    'type': 'heading',
    'attrs': {
      'level': 2
    },
    'content': [{
      'type': 'text',
      'text': '[Title Not Set]'
    }]
  }
}
const DEFAULT_CONTENT_TITLE = (opts = { text: null }) => {
  return {
    'type': 'heading',
    'attrs': {
      'level': 2
    },
    'content': [{
      'type': 'text',
      'text': opts.text || 'This is a Docr Title'
    }]
  }
}
const DEFAULT_CONTENT = ({ docAttrs, titleText, bodyText }) => {
  return {
    'type': 'doc',
    attrs: docAttrs,
    'content': [
      DEFAULT_CONTENT_TITLE({ text: titleText }),
      DEFAULT_CONTENT_TEXT({ text: bodyText })
    ]
  }
}

export default {
  DEFAULT_CONTENT_TEXT,
  DEFAULT_CONTENT_TITLE,
  UNKNOWN_CONTENT_TITLE,
  DEFAULT_CONTENT
}
