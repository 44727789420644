import Wire from './protobuf/wire'
import Tags from './protobuf/tags'
import Docr from './protobuf/docr'
import Media from './protobuf/media'
import Packet from './protobuf/packet'
import Identity from './protobuf/identity'
import Transform from './protobuf/transform'
import Timestamp from './protobuf/timestamp'
import TileLayers from './protobuf/tilelayers'
const rootModel = {
  nested: {
    ...Wire,
    ...Tags,
    ...Docr,
    ...Media,
    ...Packet,
    ...Identity,
    ...Transform,
    ...Timestamp,
    ...TileLayers
  }
}
const root = protobuf.Root.fromJSON(rootModel)

export default root
