export const SCHEMA_NAME = 'docr'
export const STORE_KEY = 'dexieOffline'
export const WORKER_NAME = '$DocrWorker'
// prosemirror spec [type, attrs, content]
export const SCHEMA_SPEC = '$$uid, uid, &hid, [uid+hid+geohash+cease], [uid+geohash+cease], source, type, attrs, content, searchText, title, *tags, resources, geohash, begin, pivot, cease, exist'
export const HASH_COLUMNS = ['uid', 'hid', 'geohash', 'cease']

export const COLUMNS = SCHEMA_SPEC.split(', ').reduce((acc, val) => {
  val = val.replace(/(\*|\+|&)/ig, '')
  acc.push(val)
  return acc
}, [])

export const PRIMARY_KEY = COLUMNS[0]
