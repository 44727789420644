import { uid } from 'quasar'
import { Type, Presence } from 'sculp'
import initials from './initials'
import * as checks from '../checks'
import * as regexps from '../regexps'
import { schema as timestamp } from '../timestamps/schema'
import { DEFAULT_CONTENT } from './constants'
export const schema = {
  type: Type.OBJECT,
  $presence: Presence.REQUIRED,
  properties: {
    uid: {
      meta: 'Primary doc uid',
      type: Type.STRING,
      $presence: Presence.REQUIRED
    },
    type: {
      meta: 'Primary doc type',
      initial: DEFAULT_CONTENT.type,
      type: Type.STRING,
      $presence: Presence.REQUIRED
    },
    attrs: {
      meta: 'Primary doc attrs',
      type: Type.OBJECT,
      initial: DEFAULT_CONTENT.attrs,
      $presence: Presence.REQUIRED,
      properties: {
        docId: {
          type: Type.STRING,
          $presence: Presence.REQUIRED
        }
      }
    },
    content: {
      meta: 'Primary doc content',
      type: Type.ARRAY,
      initial: DEFAULT_CONTENT.content,
      $presence: Presence.REQUIRED,
      items: Type.OBJECT
    },
    title: { // document title in prosemirror format
      meta: 'Docr title [prosemirror format]',
      type: Type.OBJECT,
      $presence: Presence.REQUIRED,
      initial: initials.DEFAULT_CONTENT_TITLE,
      removeEmpty: false,
      properties: {
        type: {
          type: Type.STRING,
          $presence: Presence.REQUIRED
        },
        attrs: {
          type: Type.OBJECT,
          $presence: Presence.REQUIRED,
          properties: {
            level: {
              type: Type.NUMBER,
              $presence: Presence.REQUIRED
            }
          }
        },
        content: {
          type: Type.ARRAY,
          $presence: Presence.REQUIRED,
          items: Type.OBJECT
        }
      }
    },
    searchText: { meta: 'searchable text',
      type: Type.STRING,
      initial: '',
      $presence: Presence.REQUIRED
    },
    geohash: { meta: 'Primary Geohash DATA',
      type: Type.STRING_NET,
      initial: 'x',
      $lengthmin: 1,
      transform: (v) => v ? v.toLowerCase() : '',
      $regexp: regexps.geohash,
      $presence: Presence.REQUIRED
    },
    tags: { meta: 'Tags for related docr materials [text]',
      type: Type.ARRAY,
      items: Type.STRING_NET,
      initial: [],
      removeEmpty: false,
      $presence: Presence.REQUIRED,
      transform: (v) => v
        ? v.map(tag => tag ? tag.toLowerCase() : '')
        : []
    },
    resources: { meta: 'Child resource hashes [sha512]',
      type: Type.ARRAY,
      items: Type.STRING_NET,
      removeEmpty: true,
      initial: [],
      transform: (v) => {
        v = v || []
        return v.reduce((agg, child) => {
          if (child && checks.sha512(child)) {
            agg.push(child)
          }
          return agg
        }, [])
      }
    },
    ...timestamp.properties
  }
}
