export const scales = [
  10,
  20,
  30,
  40,
  50,
  60,
  100,
  120,
  200,
  240,
  300,
  480,
  500,
  600,
  960,
  1000,
  1200,
  2000,
  2400,
  3000,
  4800,
  5000,
  6000,
  9600,
  10000,
  12000,
  20000,
  24000,
  25000,
  50000,
  62500,
  63360,
  100000,
  125000,
  250000,
  500000,
  1000000
]

export const calcMpp = (map) => {
  let y = map.getSize().y,
    x = map.getSize().x
  let maxMeters = map
    .containerPointToLatLng([0, y])
    .distanceTo(
      map.containerPointToLatLng([ x, y ])
    )
  let mpp = maxMeters / x
  return mpp
}
