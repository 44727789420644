export const SCHEMA_NAME = 'media'
export const STORE_KEY = 'dexieOffline'
export const WORKER_NAME = '$MediaWorker'

export const SCHEMA_SPEC = '&hid, [hid+filename], [hid+filename+geohash], [hid+filename+cease], [hid+filename+geohash+cease], filename, mimetype, properties, *tags, geohash, searchText, title, url, share, encrypt, attribution, begin, pivot, cease, exist'
export const HASH_COLUMNS = ['hid', 'filename', 'geohash', 'cease'] // NOTE: STRINGS REQUIRED

export const COLUMNS = SCHEMA_SPEC.split(', ').reduce((acc, val) => {
  val = val.replace(/(\*|\+|&)/ig, '')
  acc.push(val)
  return acc
}, [])

export const PRIMARY_KEY = COLUMNS[0]
