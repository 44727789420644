<template>
  <div>
    <l-marker ref="marker"
      v-if="geohashLatLng"
      :lat-lng="geohashLatLng"
    >
      <l-icon ref="icon"
        :key="defaultKey"
        :iconSize="iconSize"
        :iconAnchor="iconAnchor"
      >
        <l-icon-wrapper
          ref="wrapper"
          :parentVm="parentVm"
          :component="component"
          :componentProps="componentProps"
        >
          <slot>
            <q-btn icon="fa fa-question"
              @click="$q.notify('default-icon')"
              :color="color"
              outline
              flat
            />
          </slot>
        </l-icon-wrapper>
      </l-icon>
    </l-marker>
  </div>
</template>

<script>
import { uid } from 'quasar'

import defaultComponent from './defaultComponent'
import {
  findRealParent
} from 'vue2-leaflet'
export default {
  name: 'defaultIcon',
  props: {
    defaultKey: {
      type: String,
      default: () => `key-${uid()}`
    },
    uid: {
      type: String,
      default: () => uid()
    },
    featureIndex: Number,
    nodeComponent: Object,
    node: Object,
    nodePos: Number,
    pmIgnore: {
      type: Boolean,
      default: true
    },
    component: {
      type: [Object, String],
      default: () => 'dummy'
    },
    componentProps: {
      type: Object
    },
    icon: {
      type: String,
      default: 'fa fa-map-pin'
    },
    iconAnchor: {
      type: Array,
      default: () => [3, 8]
    },
    iconSize: {
      type: Array,
      default: () => [10, 5]
    },
    label: String,
    markerClick: {
      type: Function
    },
    latLng: {
      type: [Array, Object]
    },
    geohash: {
      type: String,
      default: () => ''
    },
    mapView: {
      type: Object
    },
    count: {
      type: Boolean,
      default: () => false
    },
    stroke: {
      type: Boolean,
      default: () => true
    },
    fillColor: {
      type: String,
      default: () => '#26283733'
    },
    color: {
      type: String,
      default: () => 'primary'
    },
    interactive: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    onAdd (e) {
      log: 'onAdd', e
    }
  },
  computed: {
    parentVm () {
      return this
    },
    parentContainer () {
      return findRealParent(this.$parent)
    },
    geohashPrecise () {
      return (this.geohashPrecision && this.geohash)
        ? this.geohash.slice(0, this.geohashPrecision)
        : (this.validGeometryType === 'Point')
          ? this.validGeometry.slice(0, this.geohashPrecision)
          : null
    },
    geohashPrecision () {
      return (this.zoom)
        ? this.$geohash.constants.zToGh[this.mapView.zoom]
        : this.$geohash.constants.GEOHASH_PRECISION
    },
    locations () {
      let vm = this
      let [ bottom, left, top, right ] = this.geohashBounds
      let middleY = (top + bottom) / 2
      let middleX = (left + right) / 2
      return {
        'top': [top, middleX],
        'top-left': [top, left],
        'top-right': [top, right],
        'middle-center': [middleY, middleX],
        'left': [middleY, left],
        'right': [middleY, right],
        'bottom': [bottom, middleX],
        'bottom-left': [bottom, left],
        'bottom-right': [bottom, right]
      }
    },
    bounds () {
      return (this.geohashBounds)
        ? (() => {
          let [ minX, minY, maxX, maxY ] = this.geohashBounds
          return [
            [minX, minY],
            [maxX, maxY]
          ]
        })()
        : null
    },
    validGeometryType () {
      return (this.validGeometry)
        ? this.$geohash.decode.checks.getGeohashJsonType(this.validGeometry)
        : null
    },
    validGeometry () {
      return (this.transferDataValid)
        ? this.$geohash.unpackGeometry(this.transferDataValid.data)
        : null
    },
    geohashLatLng () {
      return (this.geohash)
        ? this.$geohash.decode.atomic.decode(this.geohash, this.geohash.length)
        : (this.validGeometryType === 'Point')
          ? this.$geohash.decode.atomic.decode(this.validGeometry, this.validGeometry.length)
          : this.latLng
    },
    geohashBounds () {
      return (this.geohash && this.geohashPrecision)
        ? this.$geohash._.decode_bbox(this.geohashPrecise)
        : null
    }
  }
}
</script>

<style>
</style>
