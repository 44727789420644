import {
  WORKER_NAME,
  SCHEMA_NAME
} from '../constants'

export function countLeaflet (context) {
  let worker = this._vm[WORKER_NAME]
  return new Promise(async (resolve, reject) => {
    let { result: count } = await worker.countData()
    this.set(`${SCHEMA_NAME}/results@count`, count)
    resolve(count)
  })
}
export function toggleListener (_, settings) {
  // function assumes no record exists
  let worker = this._vm[WORKER_NAME]
  return worker.toggleListener(settings)
}
