<template>
  <q-list separator>
    <transition
      appear
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <q-item v-show="syncMeta.length === 0">
        <q-item-section avatar>
          <q-icon name="fa fa-user-slash"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>
            No Peers Connected
          </q-item-label>
        </q-item-section>
      </q-item>
    </transition>
    <template v-if="syncMeta" >
      <q-item v-for="([key, item]) in syncMeta"
        :key="`peer-${item.deviceHash}-${item.begin}-${item.cease}-${item.exist}`"
      >
        <q-item-section avatar :style="itemStyle(item.deviceHash)">
          <q-icon name="fa fa-user"/>
          <q-badge v-if="item.deviceHash === deviceHash" color="positive">self</q-badge>
        </q-item-section>
        <q-item-section class="flex column">
          <q-item-label class="row">
            <q-badge color="primary">exist[{{item.exist}}]</q-badge>
            <q-badge color="positive">begin[{{item.begin}}]</q-badge>
            <q-badge color="negative">cease[{{item.cease}}]</q-badge>
            {{item.deviceHash}}
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-list>
</template>
<script>
import wirePeerItem from './wirePeerItem'
import { get } from 'vuex-pathify'
export default {
  name: 'WirePeers',
  components: {
    wirePeerItem
  },
  methods: {
    getPeerColor (key) {
      return `#${key.slice(0, 6)}`
    },
    getRoomPeerColor (deviceHash) {
      return this.idHashDeviceColor(deviceHash)
    },
    itemStyle (deviceHash) {
      return {
        'border-right-style': 'solid',
        'border-right-width': 'thick',
        'border-color': this.getRoomPeerColor(deviceHash)
      }
    }
  },
  computed: {
    idHashDeviceColor: get('identity/idHashDeviceColor'),
    deviceHash: get('layout/deviceId@deviceHash'),
    syncProperties: get('wire/sync@properties'),
    syncMeta: get('wire/sync@meta')
  }
}
</script>
