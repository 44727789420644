import docrModel from './docr'
import identityModel from './identity'
import timeModel from './timestamps'
import geodocrModel from './geodocr'
import * as imageflips from './images/flips'
import { timehash } from './timestamps/schema'
import * as imageRotations from './images/rotations'

import * as enums from './enums'

import DataWrapper from './DataWrapper'
import { validate, getInitial } from 'sculp'

export const SCHEMA_DOCR = 'docr'
export const SCHEMA_TIME = 'timestamp'
export const SCHEMA_GEODOCR = 'geodocr'
export const SCHEMA_IDENTITY = 'identity'

export const SCHEMA_FLIPS = 'imgFlip'
export const SCHEMA_ROTATIONS = 'imgRotate'

export const SCHEMA_TIMEHASH = 'timehash'

class Modeler {
  constructor (
    remotes = [],
    cryptor = {
      jidData: {}
    }
  ) {
    this.enums = { ...enums }
    this._model = {
      [SCHEMA_TIME]: timeModel,
      [SCHEMA_DOCR]: docrModel,
      [SCHEMA_FLIPS]: imageflips,
      [SCHEMA_GEODOCR]: geodocrModel,
      [SCHEMA_IDENTITY]: identityModel,
      [SCHEMA_ROTATIONS]: imageRotations,
      [SCHEMA_TIMEHASH]: timehash
    }
    this._remotes = remotes
    this._cryptor = cryptor
  }
  setRuntime ({
    app,
    store
  }) {
    this._app = app
    this._store = store
  }
  valid (ob, schema) {
    return validate(ob, this._model[schema])
  }
  fake (schema) {
    return (this._model[schema])
      ? this._model[schema].fake()
      : null
  }
  new (schema, prefix = null) {
    let attrs = getInitial(this._model[schema].schema)
    if (prefix) {
      attrs = Object.entries(attrs)
        .reduce((acc, [key, value]) => {
          acc[`${prefix}${key}`] = value
          return acc
        }, {})
    }
    return attrs
  }
  wrapGeodocr (
    state,
    data,
    keys = {
      uid: undefined,
      hid: undefined
    }) {
    let self = this
    return new DataWrapper(
      state,
      data,
      this._model[SCHEMA_GEODOCR],
      {
        ...self._cryptor.jidData,
        ...keys
      }
    )
  }
  wrapDocr (
    state,
    data,
    remotes = [],
    keys = {
      uid: undefined,
      hid: undefined
    }) {
    let self = this
    return new DataWrapper(
      state,
      data,
      self._model[SCHEMA_DOCR],
      remotes,
      {
        ...self._cryptor.jidData,
        ...keys
      }
    )
  }
  model (schema = undefined) {
    return schema
      ? this._model[schema]
      : this._model
  }
  el (schema = undefined) {
    return this.model(schema)
  }
  get remotes () {
    return this._remotes
  }
  get store () {
    return this._store
  }
  get app () {
    return this._app
  }
}

export default Modeler
