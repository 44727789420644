let defaultOpts = {
  workerCount: 1
}
export default class WorkerInterface {
  constructor (
    Worker, events, opts = defaultOpts
  ) {
    let self = this
    self.Worker = Worker
    self.opts = Object.assign({}, defaultOpts, opts)
    console.log('WorkerInterface', self.opts.workerCount)
    self.workerCount = self.opts.workerCount
    self.renew()
    this.events = events
  }
  $on (event, handler) { return this.events.$on(event, handler) }
  $off (event, handler) { return this.events.$off(event, handler) }
  $emit (event, ...data) { return this.events.$emit(event, ...data) }
  get store () {
    return this.opts.store
  }
  getRandomInt (max) {
    return Math.floor(Math.random() * Math.floor(max))
  }
  _onerror (event) {
    this.onerror
      ? this.onerror(event)
      : console.warn('BaseWorker:onerror', event)
  }
  _onmessage (event) {
    this.onmessage
      ? this.onmessage(event.data)
      : console.warn('BaseWorker:onmessage', event)
  }
  workerQueue () {
    let self = this
    let workers = Array(this.workerCount).fill().map(_ => new self.Worker())
    for (const worker of workers) {
      worker.onmessage = (event) => self._onmessage(event, self)
      worker.onerror = (event) => self._onerror(event, self)
    }
    return {
      workers,
      postMessage (data, channels) {
        let worker = this.workers.shift()
        if (!worker) {
          return afterEvent(() => {
            worker = this.workers.shift()
            return worker
          }, null, () => {
            worker.postMessage(data, channels)
            return this.workers.push(worker)
          })
        } else {
          worker.postMessage(data, channels)
          return this.workers.push(worker)
        }
      }
    }
  }
  renew () {
    let self = this
    self.worker = self.workerQueue()
    /*
    let { Worker } = self
    self.worker = new Worker()
    self.worker.onmessage = (event) => self._onmessage(event, self)
    self.worker.onerror = (event) => self._onerror(event, self)
    */
    return self.worker
  }
  terminate () {
    self.worker.terminate()
    self.renew()
  }
  channel (handler) {
    let self = this
    let messageChannel = new MessageChannel()
    messageChannel.port1.onmessage = (event) => {
      let { data } = event
      // log: data.handler, data
      if (handler) {
        handler(data)
      } else {
        self._onmessage(event)
      }
    }
    return messageChannel
  }
  messageAll (data, handler) {
    let self = this
    return Promise.all(Array(this.workerCount)
      .fill()
      .map(_ => self.message(data, handler)))
  }
  message (data, handler) {
    let self = this
    if (data.broadcast && typeof resolve === 'undefined') {
      return self.worker.postMessage(data)
    } else if (typeof handler === 'undefined') {
      return new Promise((resolve, reject) => {
        return self.worker.postMessage(data, [self.channel(resolve).port2])
      })
    } else {
      return self.worker.postMessage(data, [self.channel(handler).port2])
    }
  }
  swChannel (handler) {
    let self = this
    let messageChannel = new MessageChannel()
    messageChannel.port1.onmessage = (event) => {
      let { data } = event
      // log: data.handler, data
      if (handler) {
        handler(data)
      } else {
        self._onmessage(event)
      }
    }
    return messageChannel
  }
  async swMessage (data, handler) {
    let self = this
    let registration = await navigator.serviceWorker.ready
    console.warn(registration.active)
    if (data.broadcast && typeof resolve === 'undefined') {
      return registration.active.postMessage(data)
    } else if (typeof handler === 'undefined') {
      return new Promise((resolve, reject) => {
        return registration.active.postMessage(data, [self.swChannel(resolve).port2])
      })
    } else {
      return registration.active.postMessage(data, [self.swChannel(handler).port2])
    }
  }
}
