import { L } from './libs'
import { offlineTileLayer } from './offlineTilePlugin'

offlineTileLayer(L)

import mainMap from './components/mainMap'
import geohashBounds from './components/geohashBounds'
import MapInterface from './MapInterface'

import {
  dummy,
  imageIcon,
  defaultIcon,
  searchIcon
} from './components/icons'

import {
  LMap,
  LIcon,
  LPopup,
  LMarker,
  LControl,
  LGeoJson,
  LPolyline,
  LRectangle,
  LTileLayer,
  LLayerGroup,
  LFeatureGroup,
  LCircleMarker
} from 'vue2-leaflet'

import 'leaflet-defaulticon-compatibility'
import defaultMarker from './static/default-marker.svg'
L.Marker.mergeOptions({
  icon: L.divIcon({
    className: 'marker',
    iconAnchor: [12, 64],
    html: `
      <svg width="24" height="24" version="1.1" viewBox="0 0 6.35 6.35" xmlns="http://www.w3.org/2000/svg">
         <g transform="translate(0 -290.65)" class="default-group">
            <path class="default-outline" d="m3.175 296.52s-2.2485-3.76-1.5875-4.7625c0.66105-1.0025 2.514-1.0025 3.175 0 0.66105 1.0025-1.5875 4.7625-1.5875 4.7625z" fill="none" stroke="#f7fcfc" stroke-width=".52917"/>
            <path class="default-figure" d="m3.175 296.47s-2.2485-3.76-1.5875-4.7625c0.66105-1.0025 2.514-1.0025 3.175 0 0.66105 1.0025-1.5875 4.7625-1.5875 4.7625z" fill="#8baab0" stroke="#246673" stroke-width=".26458px"/>
            <circle class="default-circle" cx="3.201" cy="292.34" r=".75595" fill="#085462" style="paint-order:normal" />
         </g>
      </svg>
    `
  })
})
import { utils } from '../utils'
// import something here
export default ({ app, router, Vue, store }) => {
  document.arrive('#q-app > div > header', function () {
    let vueInstance = document.querySelector('#q-app').__vue__
    vueInstance.$emit('quasar:header', this)
  })
  /*
  store.registerModule(storeName, storeModule)
  const storeName = 'leaflet'
  import GeohashCursor from './plugins/geohashHandler'
  import storeModule from './store'
  L.GeohashCursor = GeohashCursor({
    app, router, Vue, store, storeName
  })

  L.Map.addInitHook('addHandler', 'geohashCursor', L.GeohashCursor)
  */
  // map.geohashCursor.enable()
  Vue.component('l-map', LMap)
  Vue.component('l-icon', LIcon)
  Vue.component('l-popup', LPopup)
  Vue.component('l-marker', LMarker)
  Vue.component('l-control', LControl)
  Vue.component('l-geojson', LGeoJson)
  Vue.component('l-polyline', LPolyline)
  Vue.component('l-rectangle', LRectangle)
  Vue.component('l-tile-layer', LTileLayer)
  Vue.component('l-layer-group', LLayerGroup)
  Vue.component('l-feature-group', LFeatureGroup)
  Vue.component('l-circle-marker', LCircleMarker)
  Vue.component('dummy', dummy)
  Vue.component('geodocr-main-map', mainMap)
  Vue.component('geodocr-icon-default', defaultIcon)
  Vue.component('geodocr-icon-search', searchIcon)
  Vue.component('geodocr-icon-image', imageIcon)
  Vue.component('geodocr-map-bounds', geohashBounds)

  Vue.prototype.$leaflet = new MapInterface({
    store,
    worker: store._vm.$dexieWorkers.leaflet,
    name: 'leaflet'
  })
  utils.fireLoadingEvent(document)
}

export { L }
