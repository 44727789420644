// import { CRS } from 'leaflet'
export const defaultMapOptions = () => {
  return {
    // crs: CRS.EPSG4326,
    // tms: true,
    zoomSnap: 0.0000000001,
    zoomDelta: 0.25,
    zoomControl: false,
    attributionControl: true,
    worldCopyJump: true,
    doubleClickZoom: false,
    minZoom: 0,
    maxZoom: 24
  }
}
