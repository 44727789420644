<template>
  <div>
     {{localTimestamp(timehash)}}
    <div>
      <small>{{recordKey}} [{{timehash}}]</small>
    </div>
  </div>
</template>
<script>
import { format } from 'date-fns'
export default {
  name: 'TimehashDisplay',
  props: {
    recordKey: String,
    disable: Boolean,
    timehash: String
  },
  methods: {
    localTimestamp (timehash) {
      let timestamp = this.$timehash.decodems(timehash)
      return format(
        new Date(timestamp),
        'yyyy-MM-dd HH:mm:ss'
      )
    }
  }
}
</script>
