import {
  IdentityWorkerNotificationsInterface
} from './IdentityWorkerNotificationsInterface'
import {
  Dialog,
  Notify,
  exportFile
} from 'quasar'
export class IdentityWorkerPromptsInterface extends IdentityWorkerNotificationsInterface {
  importErrorPrompt (error) {
    return Dialog.create({
      title: 'Import Error',
      message: error.message,
      ok: true,
      persistent: true
    })
  }
  switchUserPrompt (record, fn, opts = {
    title: 'Switch User?'
  }) {
    let self = this
    return Dialog.create({
      message: `User: ${record.identity.name} [${record.identity.email}]`,
      ok: true,
      cancel: true,
      persistent: true,
      ...opts
    }).onOk(() => {
      self.store.set('identity/uid', record.uid)
      self.store.set('identity/idHash', record.idHash)
      self.store.set('identity/jwtToken', record.jwtToken)
      self.$emit('switched:identity', record)
      if (fn) fn()
    })
  }
  overwriteUserPrompt (record, opts = {
    title: 'Overwrite User?'
  }) {
    return Dialog.create({
      message: `Name: ${record.identity.name} [${record.identity.email}]`,
      ok: true,
      cancel: true,
      persistent: true,
      ...opts
    })
  }
  importUserPrompt (record, opts = {
    title: 'Import User?'
  }) {
    return Dialog.create({
      message: `Name: ${record.identity.name} [${record.identity.email}]`,
      ok: true,
      cancel: true,
      persistent: true,
      ...opts
    })
  }
  deleteUserPrompt (record, opts = {
    title: 'Delete Confirmation Required'
  }) {
    return Dialog.create({
      message: 'To Delete This Identity, Type in Identity Email',
      prompt: {
        model: '',
        isValid: val => record.identity.email === val, // << here is the magic
        type: 'text' // optional
      },
      cancel: true,
      persistent: true,
      ...opts
    })
  }
  passwordPrompt (message, opts = {
    title: 'Password Required'
  }) {
    return Dialog.create({
      message,
      prompt: {
        model: '',
        isValid: val => val.length > 1, // << here is the magic
        type: 'password' // optional
      },
      cancel: true,
      persistent: true,
      ...opts
    })
  }
}
