import { uid } from 'quasar'
const defaultView = {
  props: {},
  open: true,
  parent: null,
  position: null,
  name: 'menu'
}
export default {
  pageOffset: 0,
  pageSize: {
    height: 0,
    width: 0
  },
  pageFixtures: [],
  serverVersion: {
    name: null,
    version: null,
    description: null
  },
  deviceId: {},
  map: {
    baseLayer: ['OSM']
  },
  wire: {
    tabSelection: 'peers',
    messagesSettings: {
      key: 'idHash',
      orderBy: 'timestamp',
      reverse: true,
      limit: 5,
      offset: 0
    },
    newRecordCount: 0
  },
  identity: {
    tabSelection: 'create',
    noPromptDelete: false, // require prompt to delete
    identityType: 'simple', // ['simple', 'complex']
    identitySecurityWarning: true // show warning
  },
  docr: {
    addItemsToDocrPopup: true,
    browseSettings: {
      key: 'uid',
      orderBy: 'cease',
      reverse: true,
      limit: 5,
      offset: 0
    }
  },
  media: {
    browseSettings: {
      key: 'hid',
      orderBy: 'cease',
      reverse: true,
      limit: 5,
      offset: 0
    }
  },
  views: {
    browserTabId: uid(), // overwritten by editor @ boot
    live: [],
    default (attrs = defaultView) {
      let view = Object
        .assign({}, defaultView, attrs)
      return {
        name: view.name,
        loading: false,
        props: view.props,
        open: view.open,
        parent: view.parent,
        position: view.position, // ['before', 'after']
        uid: view.uid || uid()
      }
    }
  },
  splitter: {
    model: 50,
    modelSensitivity: 15,
    limits: [0, 100],
    horizon: false
  },
  showNetwork: false,
  showMap: true,
  showBrowser: true,
  showViewer: true,
  showDocr: true,
  splitLayout: true,
  splitLeftRight: true
}
