import {
  IdentityWorkerPromptsInterface
} from './IdentityWorkerPromptsInterface'
import {
  Dialog,
  Notify,
  exportFile
} from 'quasar'

export const defaultOpts = {
  vm: null,
  store: null,
  schemaSpec: null,
  schemaName: null
}
import VuexPersistence from 'vuex-persist'
const vuexIdentity = new VuexPersistence({
  key: 'IdentityWorkerInterface',
  storage: window.localStorage,
  modules: ['identity']
})
export class IdentityWorkerInterface extends IdentityWorkerPromptsInterface {
  constructor (Worker, opts = defaultOpts) {
    super(Worker, opts)
    let self = this
    vuexIdentity.plugin(self.store)
    if ('serviceWorker' in navigator) {
      let existingHandler = navigator.serviceWorker.onmessage
      navigator.serviceWorker.onmessage = (event) => {
        self.handleServiceWorkerEvents(event.data, event)
        if (existingHandler) existingHandler(event)
      }
    }
  }
  handleServiceWorkerEvents (data, event) {
    let self = this
    console.log('handleServiceWorkerEvents', data, event, self)
    let {
      handler
    } = data
    if (handler === 'sendSyncNotificationRequest') return self.toastSyncRequest(data)
  }
  async getSyncNotificationProperties (idHash) {
    let self = this
    let key = `notification:id:${idHash}`
    let properties = await localforage.getItem(key)
    let currentProperties = properties || {
      allowSyncNotification: false
    }
    self.store.set('identity/notifications@properties', currentProperties)
    localforage.setItem(key, currentProperties)
    return currentProperties
  }
  async setSyncNotificationPermissions (idHash, value) {
    let self = this
    let key = `notification:id:${idHash}`
    let currentProperties = await localforage.getItem(key)
    if (value === undefined) value = !!currentProperties[key]
    currentProperties = {
      ...currentProperties,
      allowSyncNotification: value
    }
    await self.fixData({
      key: idHash,
      changes: { allowSyncNotification: value }
    })
    await localforage.setItem(key, currentProperties)
    self.store.set('identity/notifications@properties', currentProperties)
  }
  toastSyncRequest (data) {
    let self = this
    return Notify.create({
      message: `Sync Requested [${data.handler}]`,
      caption: `Device: ${data.from.deviceName}`,
      timeout: 10000,
      type: 'positive',
      color: 'accept',
      actions: [{
        icon: 'fa fa-flag',
        label: 'Switch Identity',
        color: 'green',
        handler: async () => {
          let {
            result: [identity, ...others]
          } = await self.getData({
            values: data.from.idHash
          }, {
            key: 'idHash',
            fields: ['uid', 'jwtToken']
          })
          if (identity) {
            this.store.set('identity/idHash', data.from.idHash)
            this.store.set('identity/uid', identity.uid)
            this.store.set('identity/jwtToken', identity.jwtToken)
          } else {
            Notify.create({ message: 'Identity Not Found. Turning Off Sync Notifications!' })
            self.setSyncNotificationPermissions(data.from.idHash, false)
          }
        }
      }, {
        icon: 'fa fa-bell-slash',
        label: 'Silence',
        color: 'warning',
        handler: () => self.setSyncNotificationPermissions(data.from.idHash, false)
      }]
    })
  }

  async passwordDecode (recordB64, password) {
    let message = {
      handler: 'passwordDecrypt',
      encryptedB64: recordB64,
      password
    }
    let { result } = await this.message(message)
    if (result) recordB64 = result
    else throw new Error('DecryptFailed')
    message.handler = 'importUser'
    message.recordB64 = recordB64
    return this.message(message)
  }
  async exportUserFile (uid, opts = {
    password: null,
    startDownload: true
  }) {
    let self = this
    let record = await self.getData({ values: [uid] })
    record = record.result.find(r => r.uid === uid)
    let message = {
      handler: 'exportUser',
      record
    }
    let { result } = await this.message(message)
    if (opts.password) {
      let message = {
        handler: 'passwordEncrypt',
        password: opts.password,
        b64: result.recordB64
      }
      let {
        result: recordB64
      } = await this.message(message)
      result.recordB64 = recordB64
      result.hasPassword = true
    }
    if (opts.startDownload) {
      self.createUserFile({ record, data: result })
    }
    return result
  }
  async importUserRecord ({
    uid,
    idHash,
    record,
    validRecord,
    resolve
  }) {
    let self = this
    let exists = await self.keyStore().getItem(uid)
    if (exists) {
      self.overwriteUserPrompt(record)
        .onOk(() => {
          self.keyStore().fixItem(uid, record, () => {
            self.switchUserPrompt(record, resolve)
          })
        })
        .onCancel(() => {
          throw new Error('UserCanceledImport')
        })
    } else if (
      validRecord.idHash === idHash &&
      validRecord.uid === uid
    ) {
      self.importUserPrompt(record)
        .onOk(() => {
          self.keyStore().setItem(record)
          self.switchUserPrompt(record, resolve)
        })
        .onCancel(() => {
          throw new Error('UserCanceledImport')
        })
    } else {
      throw new Error('PublicUidOrIdHashNotMatching')
    }
  }
  async importUserFile (file) {
    let self = this
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.onload = async readerEvent => {
        let wrappedRecord = readerEvent.target.result
        try {
          wrappedRecord = JSON.parse(wrappedRecord)
          let validRecord = this.vm.$mod.valid(wrappedRecord, 'identity')
          if (validRecord) {
            let recordB64 = validRecord.recordB64
            if (validRecord.hasPassword) {
              self.passwordPrompt(`Identity File: ${file.name}`)
                .onOk(async (password) => {
                  let {
                    result: {
                      uid,
                      idHash,
                      record
                    }
                  } = await self.passwordDecode(recordB64, password)
                  self.importUserRecord({
                    uid,
                    idHash,
                    record,
                    validRecord,
                    resolve
                  })
                })
                .onCancel(() => {
                  throw new Error('UserCanceledPassword')
                })
            } else {
              let {
                result: {
                  uid,
                  idHash,
                  record
                }
              } = await self.importUser(recordB64)
              self.importUserRecord({
                uid,
                idHash,
                record,
                validRecord,
                resolve
              })
            }
          } else {
            throw new Error('NotAValidRecord')
          }
        } catch (e) {
          self.importErrorPrompt(e)
          reject()
        }
      }
      reader.readAsText(file)
    })
  }
  createUserFile ({ record, data }) {
    let {
      identity: {
        name,
        email,
        telephone,
        projectName
      }
    } = record

    let first = email
    if (name) first += `-${name}`
    if (projectName) first += `-${projectName}`
    if (telephone) first += `-${telephone}`

    let filenameBase = `geodocr-${first}`
    filenameBase = filenameBase.replace(/[\s@,.]/g, '-')
    exportFile(
      `${filenameBase}.json`,
      JSON.stringify(data),
      'application/json'
    )
  }
  async importUser (recordB64) {
    let message = {
      handler: 'importUser',
      recordB64
    }
    return this.message(message)
  }
  async deleteLocalUser (record, noPromptDelete = false) {
    if (noPromptDelete) {
      await this.nixData({
        values: [record.uid]
      })
      this.store.set('identity/uid', null)
      this.store.set('identity/idHash', null)
      this.store.set('identity/jwtToken', null)
      return this.$emit('deleted:identity', record)
    } else {
      return this.deleteUserPrompt(record)
        .onOk(async () => {
          await this.nixData({
            values: [record.uid]
          })
          this.store.set('identity/uid', null)
          this.store.set('identity/idHash', null)
          this.store.set('identity/jwtToken', null)
          this.$emit('deleted:identity', record)
        })
    }
  }
}
