<template>
  <q-btn @click="showPopupEditor = !showPopupEditor"
    color="warning"
    class="z-max no-print text-red"
    icon="fa fa-exclamation-triangle"
    minifab
    :disable="map && !layers.length">
    <q-badge floating color="positive">
      {{layers.length}}
    </q-badge>
    <q-dialog v-model="showPopupEditor"
      position="bottom"
      seamless
    >
      <div class="q-ma-sm relative-position" style="max-height:300px">
        <q-bar class="bg-primary text-bold absolute-top">
          <div>Locate Missing Features</div>
          <q-space/>
          <q-btn class="q-pa-sm self-start"
            @click="showPopupEditor = false"
            icon="fa fa-times"
            color="negative"
            size="xs"
            dense
            flat
          />
        </q-bar>
        <q-list bordered separator class="bg-dark q-pt-xl" >
          <template v-for="({ shape, node }, index) in layers">
            <q-item :key="`${shape}-${index}`"
              @click="placeShape({ shape, node, index })"
              :style="getComponentStyle({ shape, node })"
              clickable>
              <q-item-section avatar>
                <q-icon :name="`fa fa-${node.component.icon}`" size="sm"/>
              </q-item-section>
              <q-item-section>
                <q-item-label>
                  <span v-if="node.component.imageIndex">#{{node.component.imageIndex}}</span> - {{node.component.title}}
                </q-item-label>
                <q-item-label caption lines="1">
                  <div :class="`control-icon leaflet-pm-icon-${icons[shape]}`">
                    {{shape}}
                    <q-btn v-if="node.component.cachedContent"
                      @click.stop="node.component.cachedContent"
                      label="confirm"
                      color="positive"
                      flat
                      dense>
                      <default-marker-component
                        :bodyStyle="{ 'stroke': `#${getDataColor(node.component)}` }"
                        :circleStyle="{ 'fill': `#${getDataColor(node.component)}` }"
                      />
                    </q-btn>
                  </div>
                </q-item-label>
              </q-item-section>
              <q-item-section avatar>
                <q-btn @click.stop="focusNode(node)"
                  class="full-height"
                  icon="fa fa-search"
                  flat
                  dense
                />
              </q-item-section>
            </q-item>
          </template>
        </q-list>
      </div>
    </q-dialog>
  </q-btn>
</template>

<script>
import defaultMarkerComponent from '../default-marker-component'
export default {
  name: 'PmMissingControl',
  components: {
    defaultMarkerComponent
  },
  props: {
    map: Object,
    parentVm: Object,
    layers: Array,
    position: {
      default: 'topright',
      type: String
    }
  },
  data () {
    return {
      showPopupEditor: false,
      replacementLayers: [],
      icons: {
        'CircleMarker': 'circle-marker',
        'Circle': 'circle',
        'Line': 'polyline',
        'Polygon': 'polygon',
        'Rectangle': 'rectangle',
        'Marker': 'marker'
      }
    }
  },
  updated () {
    let vm = this
    if (!vm.layers || vm.layers.length === 0) {
      vm.showPopupEditor = false
    }
  },
  methods: {
    dataHashDigestHex,
    focusNode (node) {
      node.component.zoomIn = true
      return node.component.focusSelf()
    },
    getDataColor (component) {
      return dataHashDigestHex(component.hashKey).slice(0, 6)
    },
    getComponentStyle ({ shape, node }) {
      let { component } = node
      return {
        'border-left-style': 'solid',
        'border-left-width': 'thick',
        'border-color': `#${this.getDataColor(component)}`
      }
    },
    placeShape ({ shape, node, index }) {
      let vm = this
      let { icons } = vm
      console.log('placeShape', shape, node, vm.map.pm.Draw)
      vm.$parent.attentionMessage = `Place ${shape}`
      if (vm.map.pm.Draw[shape]) {
        if (vm.parentVm.focusSelf) vm.parentVm.focusSelf()
        vm.map.once('pm:create', ({ layer }) => {
          console.log('pm:create', layer)
          let element = layer.getElement()
          let circle = element.querySelector('.default-circle')
          circle.style.fill = `#${vm.getDataColor(node.component)}`
          let figure = element.querySelector('.default-figure')
          figure.style.stroke = `#${vm.getDataColor(node.component)}`
          if (vm.$parent.attentionMessageCancel) {
            vm.$parent.attentionMessageCancel()
          }
          node.component.cachedContent = () => {
            node.component.geohash = vm.$geohash.fromLatLng(layer.getLatLng())
            layer.remove()
          }
        })
        vm.$parent.attentionMessageCancel = () => {
          vm.$parent.attentionMessageCancel = null
          vm.$parent.attentionMessage = null
          vm.map.pm.Draw[shape].disable()
        }
        vm.map.pm.Draw[shape].enable()
      }
    }
  }
}
</script>
