<template>
  <q-list bordered class="rounded-borders" separator dense>
    <template v-for="(record, rIndex) of dirtyRecords">
      <q-expansion-item v-if="record[primaryKey]"
        :key="`record-${record[primaryKey]}`"
        class="bg-secondary"
        dense
      >
        <template v-slot:header class="full-width">
          <q-item class="q-px-none col-grow" dense>
            <q-item-section side class="q-pr-xs">
              <q-checkbox v-model="selection" :val="record[primaryKey]"/>
            </q-item-section>
            <q-item-section side class="q-pr-xs">
              <q-icon :name="primaryIcon" size="xs" dense/>
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{record.filename}}
              </q-item-label>
            </q-item-section>
            <q-item-section side class="q-pr-xs">
              <q-icon v-show="record.geohash"
                name="img:icons/icon-map.svg"
                size="xs"
              >
                <q-tooltip>[{{record.geohash}}]</q-tooltip>
              </q-icon>
            </q-item-section>
          </q-item>
        </template>
        <q-bar v-if="record"
          class="bg-dark full-width justify-between"
          dense
        >
          <q-btn class="col-shrink"
            :key="`menu-${record[primaryKey]}`"
            icon="fa fa-ellipsis-v"
            label="Actions"
            size="sm"
            flat
          />
          <transition-group appear
            enter-active-class="animated fadeInRight"
            leave-active-class="animated fadeOutRight"
          >
            <template v-if="saving">
              <q-spinner-grid :key="`saving-${record[primaryKey]}`" />
              <div class="float-right" :key="`saving-action-${record[primaryKey]}`">
                &nbsp;Saving
              </div>
            </template>
          </transition-group>
          <q-menu>
            <q-list dense class="full-width">
              <nix-record-item
                :finishHandler="nixedItem"
                :worker="$MediaWorker"
                :record="record"
                :primaryKey="primaryKey"
              />
            </q-list>
          </q-menu>
        </q-bar>
        <template v-for="([ key, handlers ], index) of fields">
          <q-item :key="`property-${record[primaryKey]}-${key}`"
            class="bg-primary q-px-xs col-grow"
            :inset-level="0"
          >
            <q-item-section side>
              <q-btn v-if="isBlob(record[key])"
                @click="showDialog(rIndex, true)"
                :icon="handlers.icon"
                outline
              />
              <q-icon v-else
                :name="handlers.icon"
              />
            </q-item-section>
            <q-item-section v-if="handlers && handlers.component">
              <component class="full-width"
                @input="value => handleInput({ key, value, oldVal: record[key], record, index: rIndex })"
                v-bind="handlers.props(key, record[key])"
                :is="handlers.component"
              >
              </component>
            </q-item-section>
            <q-expansion-item v-else-if="isBlob(record[key])"
              :caption="`${record[key].size} Bytes`"
              :label="record[key].type"
              class="full-width"
              expand-separator
            >
              <q-img style="max-width: 400px; height: 200px;"
                @click="showDialog(rIndex, true)"
                :src="handlers.src(record[primaryKey], record[key])"
                contain
              />
              <q-dialog :value="showDialogBlob[rIndex]"
                class="relative-position"
                :key="`dialog-${record[primaryKey]}-${key}-${index}`"
                maximized
              >
                <media-display
                  :recordKey="key"
                  :url="handlers.src(record[primaryKey], record[key])"
                >
                  <template v-slot:before>
                    <close-dialog-button
                      @close="showDialog(rIndex, false)"
                      class="z-max"
                    />
                  </template>
                </media-display>
              </q-dialog>
            </q-expansion-item>
            <q-item-section v-else-if="!handlers.hidden" :no-wrap="false">
              <q-item-label lines="3" style="overflow-wrap: break-word;">
                {{record[key]}}
              </q-item-label>
              <q-item-label caption>{{key}}</q-item-label>
            </q-item-section>
            <q-icon v-if="handlers.key"
              class="float-right"
              name="fa fa-fingerprint"
            />
          </q-item>
        </template>
      </q-expansion-item>
    </template>
  </q-list>
</template>
<script>
import nixRecordItem from '../../components/nix-record-item'
import { debounce } from 'quasar'
import fields from '../fields'
export default {
  name: 'MediaRecordItems',
  components: {
    nixRecordItem
  },
  props: {
    recordSelection: {
      type: Array,
      default: () => ([])
    },
    records: {
      type: Array,
      default: () => ([])
    }
  },
  watch: {
    records (val, oldVal) {
      if (isArray(val)) this.dirtyRecords = [...val]
    }
  },
  data () {
    return {
      saving: false,
      showDialogBlob: [],
      dirtyRecords: [],
      isDirty: {},
      changes: {}
    }
  },
  mounted () {
    if (isArray(this.records)) {
      this.dirtyRecords = [...this.records]
    }
    this.autoSave = debounce(this.autoSave, 5000)
  },
  methods: {
    isBlob: isBlob,
    isString: isString,
    async autoSave (changes) {
      this.saving = true
      let fix = Object.entries(changes)
        .reduce((acc, [key, value]) => {
          acc.push({
            key,
            changes: value
          })
          return acc
        }, [])
      await this.$MediaWorker.fixData(fix)
      await wait(500)
      this.changes = {}
      this.$emit('reload', changes)
      this.saving = false
    },
    nixedItem (record) {
      this.dirtyRecords = this.dirtyRecords
        .filter(r => r[this.primaryKey] !== record[this.primaryKey])
      this.$emit('reload', record)
    },
    showDialog (index, val) {
      let dialogs = [...this.showDialogBlob]
      dialogs[index] = val
      this.showDialogBlob = dialogs
    },
    handleInput ({ key, value, oldVal, record, index }) {
      let primary = record[this.primaryKey]
      let change = { [key]: value }
      let currentRecord = this.dirtyRecords[index]
      currentRecord[key] = value

      let records = [ ...this.dirtyRecords ]
      records[index] = currentRecord
      this.dirtyRecords = records

      if (this.changes[primary]) {
        this.changes[primary] = Object.assign({}, this.changes[primary], change)
      } else {
        this.changes[primary] = change
      }
      this.autoSave(this.changes)
    }
  },
  computed: {
    selection: {
      get () {
        return this.recordSelection
      },
      set (val) {
        this.$emit('update:recordSelection', val)
      }
    },
    primaryIcon () {
      return fields._icon
    },
    primaryKey () {
      return fields._primary
    },
    fields () {
      return Object
        .entries(fields)
        .filter(([key, value]) => !key.startsWith('_'))
        .filter(([key, value]) => !value.hidden)
    }
  }
}
</script>
