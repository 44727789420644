import { uid } from 'quasar'
import {
  MenuBar,
  MenuLists,
  MenuHeadings,
  SimpleEditor,
  EditorMenuBar,
  EditorContent,
  EditorInterface
} from 'geodocr-editor'
import { utils } from '../utils'
import focusLock from 'vue-focus-lock'
import getEditorInterface from './getEditorInterface'

export default ({ Vue, router, store, app }) => {
  let vm = store._vm

  let browserTabId = uid()
  store.set('layout/views@browserTabId', browserTabId)

  Vue.prototype.$editor = getEditorInterface({
    vm,
    Vue,
    store,
    uid: browserTabId
  })
  vm.$dexieWorkers.docr.toggleListener({
    uid: browserTabId,
    subscribe: true
  })
  Vue.component('focus-lock', focusLock)
  Vue.component('menu-bar', MenuBar)
  Vue.component('menu-lists', MenuLists)
  Vue.component('menu-headings', MenuHeadings)
  Vue.component('editor-menu-bar', EditorMenuBar)
  Vue.component('editor-content', EditorContent)
  Vue.component('simple-editor', SimpleEditor)
  utils.fireLoadingEvent(document)
}
