import { licenseItems } from '../../models/enums'
import { cacheBlobUrls } from '../../persistence'
import gh from 'geodocr-geohash'
export default {
  _primary: 'hid',
  _icon: 'fa fa-file-image',
  title: {
    label: ({ key, value }) => value,
    icon: 'fa fa-heading',
    component: 'q-input',
    props (key, val) {
      return {
        label: 'Title',
        standout: true,
        value: val
      }
    }
  },
  blob: {
    icon: 'fa fa-image',
    src (key, blob) {
      return cacheBlobUrls.set(key, blob)
    }
  },
  tags: {
    icon: 'fa fa-tags',
    component: 'q-select',
    props (key, val) {
      if (!val) val = []
      return {
        key: `tags-${key}-${val.join('-')}`,
        label: 'Tags',
        standout: true,
        multiple: true,
        'use-input': true,
        'use-chips': true,
        'hide-dropdown-icon': true,
        'new-value-mode': 'add-unique',
        value: val
      }
    }
  },
  geohash: {
    label ({ key, value, precision }) {
      let [ lat, lng ] = gh.toLatLng(value, precision)
      return `${value} [${lng}, ${lat}]`
    },
    key: true,
    component: 'geohash-display',
    icon: 'fa fa-hashtag',
    props (key, value) {
      let label
      if (value) {
        let { lat, lng } = gh.toLatLng(value)
        label = `${value} [${lng}, ${lat}]`
      } else {
        label = 'Not Found'
      }
      return {
        recordKey: key,
        geohash: value,
        label
      }
    }
  },
  filename: {
    key: true,
    component: 'q-chip',
    icon: 'fa fa-file',
    props (key, val) {
      return {
        dense: true,
        label: val
      }
    }
  },
  attribution: {
    icon: 'fab fa-creative-commons',
    label (key, value) {
      return licenseItems[value]
    },
    component: 'select-license-type',
    props (key, value) {
      return {
        licenseType: value
      }
    }
  },
  mimetype: {
    hidden: true,
    icon: 'fa fa-code'
  },
  properties: {
    icon: 'fa fa-list-alt',
    hidden: true
  },
  exist: {
    component: 'timehash-display',
    icon: 'fa fa-clock',
    props (key, val) {
      return {
        recordKey: key,
        timehash: val,
        disable: true
      }
    }
  },
  begin: {
    component: 'timehash-display',
    icon: 'fa fa-hourglass-start',
    props (key, val) {
      return {
        recordKey: key,
        timehash: val,
        disable: true
      }
    }
  },
  pivot: {
    component: 'timehash-display',
    icon: 'fa fa-hourglass-half',
    props (key, val) {
      return {
        recordKey: key,
        timehash: val,
        disable: true
      }
    }
  },
  cease: {
    key: true,
    component: 'timehash-display',
    icon: 'fa fa-hourglass-end',
    props (key, val) {
      return {
        recordKey: key,
        timehash: val,
        disable: false
      }
    }
  },
  share: {
    icon: 'fa fa-user-tag',
    hidden: true
  },
  encrypt: {
    icon: 'fa fa-hashtag',
    disabled: true,
    hidden: true
  },
  searchText: {
    icon: 'fa fa-search',
    disabled: true,
    hidden: true
  },
  url: {
    icon: 'fa fa-link',
    disabled: true,
    hidden: true
  },
  hid: {
    pk: true,
    key: true,
    disabled: true,
    icon: 'fa fa-key'
  }
}
