export const SCHEMA_NAME = 'wire'
export const STORE_KEY = 'dexieOffline'
export const WORKER_NAME = '$WireWorker'
export const SCHEMA_SPEC = 'timestamp, idHash, deviceHash, peerId, roomId, type, key, payload'
export const HASH_COLUMNS = ['timestamp']

export const COLUMNS = SCHEMA_SPEC.split(', ').reduce((acc, val) => {
  val = val.replace(/(\*|\+|&|\$)/ig, '')
  acc.push(val)
  return acc
}, [])

export const PRIMARY_KEY = COLUMNS[0]

export const SYNC_DEFAULTS = {
  syncOn: false,
  syncDb: ['wire'] // ['docr', 'identity', 'media']
}
