<template>
  <q-btn icon="fa fa-globe" :outline="outline" color="warning" :size="size" flat dense>
    <span class="fa fa-cogs" style="font-size:1.5em"></span>
    <q-popup-proxy v-close-popup ref="popup">
      <div>
        <q-bar class="text-bold row justify-around">
          LOAD MORE&nbsp;
          <q-btn @click="() => loadCrs({ refType: 'epsg' })"
            label="epsg"
            color="info"
            size="sm"
            :loading="loadCrsMessage === 'epsg'"
            outline
            dense
          />
          <q-btn @click="() => loadCrs({ refType: 'esri' })"
            label="esri"
            color="info"
            size="sm"
            :loading="loadCrsMessage === 'esri'"
            outline
            dense
          />
          <q-btn @click="() => loadCrs({ refType: 'sr-org' })"
            label="sr-org"
            color="info"
            size="sm"
            :loading="loadCrsMessage === 'sr-org'"
            outline
            dense
          />
        </q-bar>
        <div class="q-px-sm text-bold">
          Reference: <a href="https://spatialreference.org"
            title="spatialreference site"
            target="_blank">https://spatialreference.org</a>
        </div>
        <q-select class="text-bold full-width"
          fit
          :label="selectedCrsModel ? `Selected: ${selectedCrsModel.label}` : null"
          popup-content-class="bg-secondary text-bold text-white"
          :options="availableCrsOptions"
          prefix="Display Coordinates"
          v-model="selectedCrsModel"
          input-class="full-width"
          label-color="secondary"
          style="width: 250px"
          input-debounce="0"
          @filter="filterFn"
          bg-color="dark"
          color="primary"
          @input="setCrs"
          hide-selected
          use-input
          filled
          dense
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
    </q-popup-proxy>
  </q-btn>
</template>
<script>
export default {
  name: 'SelectNamedCrs',
  props: {
    crsEncoded: String,
    outline: Boolean,
    size: {
      default: 'lg',
      type: String
    }
  },
  data () {
    return {
      selectedCrsModel: null,
      loadCrsMessage: null,
      availableCrsOptions: [],
      availableCrs: []
    }
  },
  async mounted () {
    if (this.crsEncoded) {
      try {
        this.$dexieWorkers.leaflet
          .decodeNamedCrs(this.crsEncoded)
          .then((result) => {
            console.log('decodeNamedCrs', result)
            this.selectedCrsModel = {
              label: result.name,
              value: result.name
            }
          })
      } catch (e) {
        log: e.message
      }
    }
    this.availableCrs = await this.buildNamedCrs()
    let opts = this.availableCrs.map(n => {
      return {
        label: n.name,
        value: n.name
      }
    })
    this.availableCrsOptions = opts
  },
  methods: {
    async loadCrs ({ refType }) {
      let vm = this
      vm.loadCrsMessage = refType
      let allSpatialCrs = await vm.$dexieWorkers.leaflet
        .allSpatialCrs({ refType, bypassCache: true })
      if (!allSpatialCrs.data) {
        vm.loadCrsMessage = null
        return
      }
      let { data: { nodes, keys } } = allSpatialCrs
      let cache = []
      if (nodes && nodes.length > 0) {
        cache = nodes.map(n => vm.$dexieWorkers.leaflet.nodeToNamedCrs(n, n.matrix))
      }
      let existingKeys = cache.map(n => n.name)
      let update = vm.availableCrs
        .filter(n => existingKeys.indexOf(n.name) === -1)
        .concat(cache)
      vm.availableCrs = update
      let opts = update.map(n => {
        return {
          label: n.name,
          value: n.name
        }
      })
      this.availableCrsOptions = opts
      vm.loadCrsMessage = null
    },
    async buildNamedCrs () {
      await this.$dexieWorkers.leaflet.isReady()
      let builtIn = await this.$dexieWorkers.leaflet.availableNamedCrs()
      let allSpatialCrs = await this.$dexieWorkers.leaflet.allSpatialCrs()
      if (!allSpatialCrs.data) return builtIn
      let { data: { nodes } } = allSpatialCrs
      let builtInCache = []
      if (nodes && nodes.length > 0) {
        builtInCache = nodes.map(n => this.$dexieWorkers.leaflet.nodeToNamedCrs(n, n.matrix))
      }
      return builtIn.concat(builtInCache)
    },
    async setCrs ({ value }) {
      let selected = this.availableCrs.find(n => n.name === value)
      let encoded = await this.$dexieWorkers.leaflet.encodeNamedCrs(selected)
      this.$emit('selected:encoded', encoded)
    },
    filterFn (val, update) {
      let filteredResults = this.$dexieWorkers.leaflet.filterAvailableCrs({ filter: val, crs: this.availableCrs })
      let opts = filteredResults.map(n => {
        return {
          label: n.name,
          value: n.name
        }
      })
      this.availableCrsOptions = opts
      update()
    }
  }
}
</script>
