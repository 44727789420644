/* eslint-disable */
let isHttp = false
if (document.URL) {
  isHttp = document.URL.slice(0, 5) === 'http:'
}

export const tileProviders = [{
    name: 'OSM-GeodocrProxy',
    title: 'Open Street Map',
    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://tiles.geodocr.com/wmts/osm_grid/OSM/{z}/{x}/{y}/tile',
    offline: {
      minZoom: 3,
      maxZoom: 26,
      crossOrigin: true
    }
  }, {
    name: 'MTILER-GeodocrProxy',
    title: 'Global Aerial',
    attribution: '&copy; <a href="https://maptiler.com" target="_blank">MapTiler</a> &copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors</a>',
    url: 'https://tiles.geodocr.com/wmts/osm_grid/MTILER/{z}/{x}/{y}/tile',
    offline: {
      minZoom: 3,
      maxZoom: 26,
      crossOrigin: true
    }
  }, {
    name: 'GEBCO-GeodocrProxy',
    title: 'GEBCO - General Bathymetric Chart of the Oceans',
    attribution: '&copy; <a target="_blank" href="https://www.gebco.net">Imagery reproduced from the GEBCO_2019 Grid, version 20150318</a>',
    url: 'https://tiles.geodocr.com/wmts/usgs_grid/GEBCO/{z}/{x}/{y}/tile',
    offline: {
      minZoom: 3,
      maxZoom: 26,
      crossOrigin: true
    }
  }, {
    name: 'USGS-GeodocrProxy',
    title: 'USA National Map Aerials - Low Resolution',
    attribution: '&copy; <a target="_blank" href="https://basemap.nationalmap.gov">USGS The National Map: Orthoimagery.</a>',
    url: 'https://tiles.geodocr.com/wmts/usgs_grid/USGS/{z}/{x}/{y}/tile',
    offline: {
      minZoom: 3,
      maxZoom: 26,
      crossOrigin: true
    }
  }, {
    name: 'NAIP-GeodocrProxy',
    title: 'USA National Map Aerials/NAIP - High Resolution',
    attribution: '&copy; <a target="_blank" href="https://basemap.nationalmap.gov"> USGS NAIP Imagery service from The National Map (TNM).</a>',
    url: 'https://tiles.geodocr.com/wmts/usgs_grid/NAIP/{z}/{x}/{y}/tile',
    offline: {
      minZoom: 3,
      maxZoom: 26,
      crossOrigin: true
    }
  }
]
