<template>
  <q-btn-dropdown outline>
    <template v-slot:label>
      <div class="text-caption">
        <q-img :src="`licenses/${licenseType}-icon.svg`"
          style="width: 34px"
        />
        License [{{licenseType}}]
      </div>
    </template>
    <q-list>
      <q-item @click.stop="$emit('input', type)"
        v-for="([type, label], index) in licenseItems"
        :key="`item-${index}`"
        v-close-popup
        clickable
      >
        <q-item-section avatar>
          <q-img :src="`licenses/${type}-icon.svg`"
            style="width: 64px"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label caption>{{label}}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>
<script>
import { licenseItems } from '../models/enums'
export default {
  name: 'SelectLicenseType',
  props: {
    licenseType: {
      type: String,
      default: () => Object.keys(licenseItems)[0]
    }
  },
  computed: {
    licenseItems () {
      return Object.entries(licenseItems)
    }
  }
}
</script>
