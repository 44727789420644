<template>
  <l-feature-group ref="group">
    <l-polyline v-if="geohashLatLng && labelLatLng"
      :options="{ pmIgnore }"
      :lat-lngs="[geohashLatLng, labelLatLng]"
      :weight="2"
      :color="calloutColor">
    </l-polyline>
    <l-circle-marker v-if="geohashLatLng"
      v-on:dblclick="dblClickLocation"
      ref="circleMarker"
      :lat-lng="geohashLatLng"
      :fill-color="calloutColor"
      :color="calloutColor"
      :opacity=".6"
      :weight="2"
      :radius="6"
    >
    </l-circle-marker>
    <l-marker ref="marker"
      @click="onClick"
      @ready="onReady"
      @moveend="moveend"
      v-if="geohashLatLng"
      :options="{ pmIgnore }"
      :draggable="!isEditing"
      :lat-lng="labelLatLng || geohashLatLng"
    >
      <l-icon ref="icon"
        :key="defaultKey"
        :iconSize="iconSize"
        :iconAnchor="iconAnchor">
        <l-icon-wrapper component="dummy"
          class="block-inline"
          :style="wrapperStyle"
        ><div ref="iconContainer">
          <q-badge color="black" class="q-pa-xs">
            <span class="text-bold">{{featureIndex}}</span>
            <q-icon name="fa fa-arrows-alt" class="print-only invisible"/>
            <q-icon name="fa fa-arrows-alt" class="no-print"/>
            <q-icon ref="icon"
              class="q-ml-xs"
              :name="runtimeIcon"
              color="white"
              size="1em"
            />
          </q-badge>
        </div></l-icon-wrapper>
      </l-icon>
    </l-marker>
  </l-feature-group>
</template>

<script>
import rhumbBearing from '@turf/rhumb-bearing'
import { colors } from 'quasar'
import Vue from 'vue'
import {
  defaultIcon
} from '.'
export default {
  name: 'ImageIcon',
  extends: defaultIcon,
  data () {
    return {
      isEditing: false,
      mountFn: null,
      mapContainerRect: null,
      map: null
    }
  },
  methods: {
    onClick () {
      if (!this.isEditing) {
        this.$emit('click')
      }
    },
    onReady (event) {
      let vm = this
      vm.map = vm.$refs.group.mapObject._map
      vm.mapContainerRect = getBoundingClientRect(vm.map._container)
      vm.map.on('pm:edit', () => {
        log: 'pm:edit', this, vm
        vm.isEditing = true
      })
      vm.map.on('pm:disable', () => {
        vm.isEditing = false
      })
      let container = event.getElement()
      afterEvent(() => this.$refs.circleMarker, null, () => {
        let { mapObject: layer } = vm.$refs.circleMarker
        layer.on('pm:dragend', vm.onChangeLocation)
      })
    },
    onChangeLocation (event) {
      let { target } = event
      let latLng = target.getLatLng()
      let geohash = this.fromLatLng(latLng, this.geohash.length)
      this.nodeComponent.geohash = geohash
    },
    dblClickLocation () {
      this.nodeComponent.geohashLabel = ''
    },
    moveend (event) {
      let { target } = event
      let latLng = target.getLatLng()
      let geohash = this.fromLatLng(latLng, this.geohash.length)
      this.nodeComponent.geohashLabel = geohash
    }
  },
  computed: {
    rectPoints () {
      return this.rectBounds
        ? Object.entries(this.rectBounds)
          .reduce((acc, [key, value]) => {
            acc[key] = [value.lat, value.lng]
            return acc
          }, {})
        : null
    },
    anchorPoint () {
      return this.labelLatLng
    },
    anchorLabelAt () {
      let { bearing } = this
      if (bearing.between(-180, -157.5)) return 'north'
      if (bearing.between(-157.5, -112.5)) return 'northEast'
      if (bearing.between(-112.5, -67.5)) return 'east'
      if (bearing.between(-67.5, -22.5)) return 'southEast'
      if (bearing.between(-22.5, 22.5)) return 'south'
      if (bearing.between(22.5, 67.5)) return 'southWest'
      if (bearing.between(67.5, 112.5)) return 'west'
      if (bearing.between(112.5, 157.5)) return 'northWest'
      return 'north'
    },
    uidIconFor () {
      let {
        $options,
        uid,
        featureIndex
      } = this
      return `${$options.name}-${uid}-${featureIndex}`
    },
    calloutColor () {
      return colors.getBrand('primary')
    },
    bearing () {
      if (!this.labelLatLng) return 0
      let {
        geohashLatLng: {
          lat: fromLat,
          lng: fromLng
        },
        rectBounds: {
          center: {
            lat: toLat,
            lng: toLng
          }
        }
      } = this
      return rhumbBearing([fromLng, fromLat], [toLng, toLat])
    },
    labelLatLng () {
      let { geohashLabel, geohash } = this.nodeComponent
      if (!this.validGeohash(geohash)) return null
      let latLng = this.toLatLng(geohashLabel || geohash)
      return latLng
    },
    api () {
      return this.nodeComponent.api
    },
    wrapperStyle () {
      return {} // 'width:100px'
    },
    runtimeIcon () {
      return `fa fa-${this.nodeComponent.icon}`
    },
    fromLatLng () {
      return this.api.geohash.fromLatLng
    },
    toLatLng () {
      return this.api.geohash.toLatLng
    },
    validGeohash () {
      return this.api.geohash.decode.checks.isPoint
    }
  }
}
</script>

<style lang="stylus">
.icons-top-row
  background-color $accent-4
  border-top-left-radius 25px
  border-top-right-radius 25px
  border-style ridge
  border-bottom-width 0px
  border-top-width 1px

.icons-middle-row
  background-image url("/icons/marker-default.svg")
  background-repeat no-repeat
  background-position center center
  background-color $accent-4
  border-style ridge
  border-top-width 0px
  border-bottom-width 0px
  border-left-width 2px
  border-right-width 2px

.icons-middle-row-center
  border-style solid
  border-width 1px

.icons-bottom-row
  background-color $accent-4
  border-bottom-left-radius 25px
  border-bottom-right-radius 25px
  border-style ridge
  border-top-width 0px
  border-bottom-width 1px

</style>
