import { render, staticRenderFns } from "./docrRecordItems.vue?vue&type=template&id=511b11b8&"
import script from "./docrRecordItems.vue?vue&type=script&lang=js&"
export * from "./docrRecordItems.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerGrid from 'quasar/src/components/spinner/QSpinnerGrid.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QList,QExpansionItem,QItem,QItemSection,QCheckbox,QIcon,QItemLabel,QTooltip,QBar,QBtn,QSpinnerGrid,QMenu,QImg,QDialog});
