import mortice from 'mortice'
import calcRoomId from './calcRoomId'
import calcRoomPeerId from './calcRoomPeerId'
import calcRoomDeviceId from './calcRoomDeviceId'
import pb from '../../../models/protobufRoot'
import { WebrtcProvider } from 'y-webrtc'

const defaultOpts = {}

export class WireConductor {
  constructor (store, { idHash, deviceHash, properties }) {
    this.store = store
    this.idHash = idHash
    this.properties = properties
    this.deviceHash = deviceHash
    this.manifest = new Y.Doc()
    this.provider = new WebrtcProvider(calcRoomId(this.idHash), this.manifest, { /* signaling: ['wss://io.geodocr.com'] */ })
    this._onManifestUpdate = this.onManifestUpdate.bind(this)
    this.manifest.on('update', this._onManifestUpdate)
    this.room = null
    this.channels = {
      manifest: {}
    }
  }
  channelOpen (peer) {
    return new Promise((resolve, reject) => afterEvent(() => self.channels.manifest[peer.id], null, resolve))
  }
  handlers () {
    let self = this
    return {
      elect (elect, peer) {
        let { manifest } = self
        manifest.transact((tr) => {
          self.electionMap.set(peer.id, elect)
        }, { peerId: peer.id, type: 'elect', peer })
      },
      meta (meta, peer) {
        let { manifest } = self
        manifest.transact((tr) => {
          self.manifestChannel(peer)
          self.metaMap.set(meta.rdId, meta)
        }, { peerId: peer.id, type: 'meta:received', peer })
      },
      joinedRoom (roomId) {
        console.assert(self.roomId === roomId, `${self.roomId} :: ${roomId}`)
      },
      receivedPeerData (type, data, peer) {
        if (this[type]) this[type](data, peer)
      },
      setRoom (room) {
        let { manifest } = self
        self.room = room
        self.store.set('wire/sync@room', room)
        self.store.set('wire/sync@rdId', self.rdId)
        manifest.transact((tr) => {
          self.metaMap.set(self.rdId, self.roomMeta)
        }, { peerId: room.you, type: 'meta:self', peer: room.clients[room.you] })
      },
      channelOpen (channel, peer, event) {
        if (channel.label === 'manifest') {
          self.channels.manifest[peer.id] = channel
        }
        self.manifestChannel(peer)
      },
      async createdPeer (peer) {
        let { roomId, manifest, rdId } = self
        let peers = self.getPeers()
        let ids = peers.map(p => p.id).sort()
        self.manifestChannel(peer)
        self.roomClient.shout('meta', self.roomMeta)
      },
      removedPeer (peer) {
        let { roomId, manifest } = self
        let meta = [...self.metaMap.values()].find(v => v.id === peer.id)
        manifest.transact((tr) => {
          let { lock } = self.syncNegotiation[peer.id]
          if (lock) lock()
          if (meta && self.metaMap.has(meta.rdId)) {
            self.metaMap.delete(meta.rdId)
          }
          self.electionMap.delete(peer.id)
          delete self.channels.manifest[peer.id]
          delete self.syncNegotiation[peer.id]
        }, { peerId: peer.id, type: 'removedPeer', peer })
      }
    }
  }
  onManifestUpdate (update, origin, manifest) {
    let sync = this.syncNegotiation[origin.peerId]
    if (sync) {
      let { lock } = sync
      console.log(`onManifestUpdate[${origin.type}]:${origin.peerId}`)
    }
    if (origin.type === 'cease') {
      this.store.set('wire/sync@meta', [])
      this.store.set('wire/sync@room', {})
      return manifest.destroy()
    }
  }
  onMetaUpdate ({ changes, target: meta }, { origin }) {
    this.store.set('wire/sync@meta', [...meta.entries()])
    if (origin.type === 'meta:received') {
      console.log(`onMetaUpdate[${origin.type}]:${origin.peerId}`, meta, changes, origin)
    }
    if (origin.type === 'removedPeer') {
      console.log(`onMetaUpdate[${origin.type}]:${origin.peerId}`, meta, changes, origin)
    }
  }
  onElectionUpdate ({ changes, target: election }, { origin }) {
    let values = new Set([...election.values()].map(v => v.rdId))
    console.log('hasConsensus:check', values)
  }
  get electionMap () {
    let election = this.manifest.getMap('election')
    if (!this._onElectionUpdate) {
      this._onElectionUpdate = this.onElectionUpdate.bind(this)
      election.observe(this._onElectionUpdate)
    }
    return election
  }
  get metaMap () {
    let meta = this.manifest.getMap('meta')
    if (!this._onMetaUpdate) {
      this._onMetaUpdate = this.onMetaUpdate.bind(this)
      meta.observe(this._onMetaUpdate)
    }
    return meta
  }
  getPeers () {
    return this.roomClient.getPeers()
  }
  readLock (id) {
    return mortice(id).readLock()
  }
  ceaseRoom () {
    let self = this
    let { manifest, clients } = self
    manifest.transact((tr) => {
      self.electionMap.forEach((val, key) => {
        self.electionMap.delete(key)
      })
      self.metaMap.forEach((val, key) => {
        self.metaMap.delete(key)
      })
      self.roomClient.quit()
    }, { peerId: self.id, type: 'cease', peer: clients[self.id] })
  }
  createChannel ({ peer, name, protocol, onmessage, release }) {
    let channel = peer.getDataChannel(name, {
      protocol,
      maxPacketLifeTime: 1000
    })
    channel.binaryType = 'arraybuffer'
    channel.onopen = async () => {
      let confirm = await release
      confirm()
    }
    channel.onmessage = onmessage

    return channel
  }
  get manifestChannel () {
    let self = this
    return peer => this.createChannel({
      onmessage: (event) => self.manifestMessage(peer, event),
      release: this.readLock(`channel-${name}`),
      protocol: 'manifest-sync',
      name: 'manifest',
      peer
    })
  }
  get roomMeta () {
    let self = this
    return {
      id: self.id,
      rdId: self.rdId,
      exist: self.exist,
      roomId: self.roomId
    }
  }
  get roomClient () {
    return this.room.webrtc
  }
  get id () {
    return this.room.you
  }
  get exist () {
    return this.room.exist
  }
  get roomId () {
    return this.room.roomId
  }
  get clients () {
    return this.room.clients
  }
  get rdId () {
    let { roomId, deviceHash } = this
    return calcRoomDeviceId({ roomId, deviceHash })
  }
}
