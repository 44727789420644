<template>
  <q-btn @click="showPopupEditor = !showPopupEditor"
    color="primary"
    class="z-max no-print"
    icon="fa fa-microchip"
    minifab
    :disable="!layers.length">
    <q-badge floating color="positive">
      {{layers.length}}
    </q-badge>
    <q-dialog v-model="showPopupEditor"
      position="bottom"
      seamless
    >
      <div class="q-ma-sm relative-position" style="max-height:300px">
        <q-bar class="bg-primary text-bold absolute-top">
          <q-icon name="fa fa-microchip"/>
          <div>Temporary Memory Features</div>
          <q-space/>
          <q-btn class="q-pa-sm self-start"
            @click="showPopupEditor = false"
            icon="fa fa-times"
            color="negative"
            size="xs"
            dense
            flat
          />
        </q-bar>
        <q-list bordered separator class="bg-dark q-pt-xl" >
          <template v-for="({ layer, shape }, index) in layers">
            <pm-layer-item :key="`shape-${index}-${layer._leaflet_id}`"
              @add="dispatchDropEvent"
              :featureIndex="index"
              :map="map"
              :layer="layer"
              :shape="shape"
            />
          </template>
        </q-list>
      </div>
    </q-dialog>
  </q-btn>
</template>

<script>
import pmLayerItem from './pmLayerItem'
function FakeDataTransfer (file) {
  this.dropEffect = 'all'
  this.effectAllowed = 'all'
  this.files = Array.isArray(file)
    ? file
    : [file]
  this.types = ['Files']
  this.getData = function () {
    return file
  }
  this.files = [file]
}
export default {
  name: 'PmLayerControl',
  components: {
    pmLayerItem
  },
  props: {
    map: Object,
    layers: Array,
    position: {
      default: 'topright',
      type: String
    }
  },
  data () {
    return {
      showPopupEditor: false
    }
  },
  mounted () {
    log: this.$geohash
  },
  updated () {
    let vm = this
    if (!vm.layers || vm.layers.length === 0) {
      vm.showPopupEditor = false
    }
  },
  methods: {
    geojsonFileFromFeature (feature) {
      let json = JSON.stringify(feature.geojson)
      let file = new File([json], `${feature.featureName}.json`, {
        type: 'application/json'
      })
      return file
    },
    dispatchDropEvent (feature) {
      let vm = this
      let fileToDrop = vm.geojsonFileFromFeature(feature)
      let dropEvent = new DragEvent('drop', { bubbles: true })
      Object.defineProperty(dropEvent, 'dataTransfer', {
        value: new FakeDataTransfer(fileToDrop)
      })
      Object.defineProperty(dropEvent, 'currentTarget', {
        value: vm.parentDocumentProseMirror
      })
      vm.parentDocumentProseMirror.dispatchEvent(dropEvent)
    }
  },
  computed: {
    parentDocumentProseMirror () {
      let c = 'ProseMirror'
      let element = this.$el.parentElement
      while (element && !element.classList.contains(c)) {
        element = element.parentElement
      }
      return element
    }
  }
}
</script>
