import app from '../../package.json'
import { axios } from './axios'
import { utils } from './utils'
export default async ({ Vue }) => {
  let {
    name,
    version,
    description
  } = app
  Vue.prototype.$versioning = {
    appVersion: {
      name,
      version,
      description
    },
    serverVersion: {
      name: null,
      version: null,
      description: null
    }
  }
  let resp
  if (process.env.DEV) {
    resp = await axios.get(`${process.env.STAGING_ENDPOINT_URL}/package.json`)
  } else {
    resp = await axios.get('package.json')
  }
  if (resp.data) {
    let {
      name,
      version,
      description
    } = resp.data
    let serverVersion = {
      name,
      version,
      description
    }
    Object.assign(
      Vue.prototype.$versioning.serverVersion,
      serverVersion
    )
  }
  utils.fireLoadingEvent(document)
}
