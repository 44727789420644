import LioWebRTC from 'liowebrtc'
export default function webrtc (roomId, config, handlers) {
  let webrtc = new LioWebRTC(config)
  return new Promise((resolve, reject) => {
    webrtc.on('iceFailed', reject)
    webrtc.on('connectivityError', reject)
    // webrtc.on('createdPeer', (peer) => self.createdPeer({ roomId, peer }))
    // webrtc.on('removedPeer', (peer) => self.removedPeer({ roomId, peer }))
    webrtc.on('*', (...args) => {
      let [ eventName, ...arg ] = args
      if (handlers[eventName]) {
        // console.log(`handled:${eventName}`, ...arg)
        handlers[eventName](...arg)
      } else {
        // console.log(`nohandler:${eventName}`, ...arg)
      }
    })
    webrtc.on('ready', (session) => {
      webrtc.off('iceFailed', reject)
      webrtc.off('connectivityError', reject)
      webrtc.joinRoom(roomId, async (err, { you, clients }) => {
        if (err) reject()
        let room = {
          exist: dayjs().tz('Universal').unix(),
          clients,
          roomId,
          webrtc,
          you
        }
        if (handlers.setRoom) handlers.setRoom(room)
        resolve(room)
      })
    })
  })
}
