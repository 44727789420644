export default {
  props: {
    geohash: {
      type: String,
      default: () => 'z'
    },
    precision: {
      type: Number,
      default: () => 1
    },
    zoom: {
      type: Number,
      default: () => 1
    }
  },
  methods: {
    createRectangleFromBounds (bounds) {
      let ne = bounds.getNorthEast()
      let sw = bounds.getSouthWest()
      return [
        [sw.lat, sw.lng],
        [ne.lat, ne.lng]
      ]
    },
    createGeohashFeatures (geohashes) {
      if (!(geohashes instanceof Array)) {
        geohashes = [geohashes]
      }
      return geohashes.reduce((agg, geohash) => {
        let center = this.$geohash.decode(geohash, this.geohashPrecision)
        let bounds = this.$geohash.decodeBounds(geohash, this.geohashPrecision)
        let rectangle = this.createRectangleFromBounds(bounds)
        let neighbors = this.$geohash.neighbourGeohashes(geohash, this.geohashPrecision)
        agg.push({
          center,
          bounds,
          geohash,
          neighbors,
          rectangle,
          isCenter: geohash === this.geohashPrecise
        })
        return agg
      }, [])
    },
    fillGeohashFeaturesLng (lngFeatures, mapBounds) {
      let centers = lngFeatures.reduce((agg, feature) => {
        let { bounds, center } = feature
        let width = Math.abs(bounds.getEast() - bounds.getWest())
        let featuresWest = this.$geohash.gridWesting(
          center.lat,
          center.lng,
          width,
          mapBounds
        )
        let featuresEast = this.$geohash.gridWesting(
          center.lat,
          center.lng,
          -1 * width,
          mapBounds
        )
        let addFeatures = featuresEast
          .concat(featuresWest)
          .map(featureCenter => {
            let geohash = this.$geohash.encode(featureCenter.wrap(), this.geohashPrecision)
            let newBounds = this.$geohash.normalizeBounds([
              bounds.getSouth(), // minLat
              featureCenter.lng + width / 2, // minLng
              bounds.getNorth(), // maxLat
              featureCenter.lng - width / 2 // maxLng
            ])
            let rectangle = this.createRectangleFromBounds(newBounds)
            let neighbors = this.$geohash.neighbourGeohashes(geohash, this.geohashPrecision)
            return {
              geohash,
              neighbors,
              rectangle,
              bounds: newBounds,
              center: featureCenter,
              isCenter: false
            }
          })
        return agg.concat(addFeatures)
      }, [])
      return centers
    }
  },
  computed: {
    eastBorderLine: {
      get () {
        if (this.geohashPreciseBounds) {
          let lng = this.geohashPreciseBounds.getEast()
          let northLat = this.geohashPreciseBounds.getNorth()
          let southLat = this.geohashPreciseBounds.getSouth()
          return [
            [northLat, lng],
            [southLat, lng]
          ]
        } else {
          return null
        }
      }
    },
    westBorderLine: {
      get () {
        if (this.geohashPreciseBounds) {
          let lng = this.geohashPreciseBounds.getWest()
          let northLat = this.geohashPreciseBounds.getNorth()
          let southLat = this.geohashPreciseBounds.getSouth()
          return [
            [northLat, lng],
            [southLat, lng]
          ]
        } else {
          return null
        }
      }
    },
    northBorderLine: {
      get () {
        if (this.geohashPreciseBounds) {
          let lat = this.geohashPreciseBounds.getNorth()
          let westLng = this.geohashPreciseBounds.getWest()
          let eastLng = this.geohashPreciseBounds.getEast()
          return [
            [lat, westLng],
            [lat, eastLng]
          ]
        } else {
          return null
        }
      }
    },
    southBorderLine: {
      get () {
        if (this.geohashPreciseBounds) {
          let lat = this.geohashPreciseBounds.getSouth()
          let westLng = this.geohashPreciseBounds.getWest()
          let eastLng = this.geohashPreciseBounds.getEast()
          return [
            [lat, westLng],
            [lat, eastLng]
          ]
        } else {
          return null
        }
      }
    },
    geohashBoundsMapExtentLng: {
      get () {
        if (this.geohashPreciseBoundsMapExtentLng) {
          return this.createRectangleFromBounds(this.geohashPreciseBoundsMapExtentLng)
        } else {
          return null
        }
      }
    },
    geohashBoundsRectangle: {
      get () {
        if (this.geohashPreciseBounds) {
          return this.createRectangleFromBounds(this.geohashPreciseBounds)
        } else {
          return null
        }
      }
    },
    geohashPreciseBoundsMapExtentLng: {
      get () {
        if (this.map && this.geohashPreciseBounds) {
          let mapBounds = this.map.getBounds()
          let northLat = mapBounds.getNorth()
          let southLat = mapBounds.getSouth()
          let eastLong = this.geohashPreciseBounds.getEast()
          let westLong = this.geohashPreciseBounds.getWest()
          let ne = this.$geohash.normalize([northLat, eastLong])
          let sw = this.$geohash.normalize([southLat, westLong])
          return this.geohashPreciseBounds
            .extend(ne)
            .extend(sw)
            .pad(-0.05)
        } else {
          return null
        }
      }
    },
    geohashPreciseBoundsMapExtentLngGeohashes () {
      return (this.geohashPreciseBoundsMapExtentLng)
        ? this.$geohash.bboxes(
          this.geohashPreciseBoundsMapExtentLng,
          this.geohashPrecision
        )
        : null
    },
    geohashPreciseBoundsMapExtentLngFeatures () {
      return (this.geohashPreciseBoundsMapExtentLngGeohashes)
        ? this.createGeohashFeatures(
          this.geohashPreciseBoundsMapExtentLngGeohashes
        )
        : null
    },
    geohashPreciseBoundsMapExtentLngFeaturesFill () {
      return (this.map && this.geohashPreciseBoundsMapExtentLngFeatures)
        ? this.fillGeohashFeaturesLng(
          this.geohashPreciseBoundsMapExtentLngFeatures,
          this.map.getBounds()
        )
        : null
    },
    geohashPreciseBounds () {
      return (this.geohashPrecise)
        ? this.$geohash.decodeBounds(this.geohashPrecise)
        : null
    },
    geohashPreciseCenter () {
      return (this.geohashPrecise && this.geohashPrecision)
        ? this.$geohash.decode(this.geohashPrecise, this.geohashPrecision)
        : [0, 0]
    },
    geohashPrecise () {
      return (this.geohash)
        ? this.geohash.slice(0, this.geohashPrecision)
        : this.geohash.slice(0, 1)
    },
    geohashPrecision () {
      return (this.zoom || this.geohash)
        ? this.$geohash.getPrecisionFromZoom(this.zoom)
        : 1
    }
  }
}
