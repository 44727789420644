/*
export function pageStyle (state) {
  let { pageOffset } = state
  return {
    height: `calc(100vh - ${pageOffset}px)`
  }
}
export function sectionSize (state) {
  let {
    pageOffset,
    pageSize: {
      height,
      width
    },
    splitter: {
      model,
      horizon,
      limits
    }
  } = state
  let ration = (limits[1] - model) / limits[1]
  return sectionOffset => {
    return {
      after: {
        pageOffset,
        sectionOffset,
        width: horizon ? width : width * (ration),
        height: horizon
          ? (height - pageOffset - sectionOffset || 0) * ration
          : height - pageOffset - sectionOffset || 0
      },
      before: {
        pageOffset,
        sectionOffset,
        width: horizon ? width : width * (1 - ration),
        height: horizon
          ? (height - pageOffset - sectionOffset || 0) * (1 - ration)
          : height - pageOffset - sectionOffset || 0
      }
    }
  }
}
*/
export function viewsLiveReverse (state) {
  return state.views.live.slice().reverse()
}
export function sectionViewAfter (state) {
  let view = viewsLiveReverse(state)
    .find(v => v.open && v.position === 'after')
  return view || state.views.default()
}
export function sectionViewBefore (state) {
  let view = viewsLiveReverse(state)
    .find(v => v.open && v.position === 'before')
  return view || state.views.default()
}
/*
export function viewAfterStyle (state) {
  let {
    pageOffset,
    splitter: {
      model,
      horizon,
      limits
    }
  } = state
  let ration = (limits[1] - model) / limits[1]
  return sectionOffset => (horizon)
    ? {
      minHeight: `calc((100vh - ${pageOffset}px - ${sectionOffset || 0}px) * ${ration})`
    }
    : {
      minHeight: `calc(100vh - ${pageOffset}px - ${sectionOffset || 0}px)`
    }
}
export function viewBeforeStyle (state) {
  let {
    pageOffset,
    splitter: {
      model,
      horizon,
      limits
    }
  } = state
  let ration = (limits[1] - model) / limits[1]
  return sectionOffset => (horizon)
    ? {
      minHeight: `calc((100vh - ${pageOffset}px - ${sectionOffset || 0}px) * ${1 - ration})`
    }
    : {
      minHeight: `calc(100vh - ${pageOffset}px - ${sectionOffset || 0}px)`
    }
}
*/
export function miniFlipIcon (state) {
  return (state.splitter.horizon)
    ? 'img:icons/mini-flip-vertical.svg'
    : 'img:icons/mini-flip-horizontal.svg'
}
export function splitterModel (state) {
  let { model, modelSensitivity, limits } = state.splitter
  model = parseInt(model || 0)
  return model < modelSensitivity
    ? limits[0]
    : model > (limits[1] - modelSensitivity)
      ? limits[1]
      : model
}
export function splitterAtTopLeft (state) {
  let limit = state.splitter.limits[1]
  return state.splitter.model < parseInt(limit * 0.01)
}
export function splitterAtBottomRight (state) {
  let limit = state.splitter.limits[1]
  return state.splitter.model > parseInt(limit * 0.99)
}
export function splitterIsRightEdge (state) {
  return !state.splitter.horizon &&
    splitterAtBottomRight(state)
}
export function splitterIsBottomEdge (state) {
  return state.splitter.horizon &&
    splitterAtBottomRight(state)
}
export function splitterIsLeftEdge (state) {
  return !state.splitter.horizon &&
    splitterAtTopLeft(state)
}
export function splitterIsTopEdge (state) {
  return state.splitter.horizon &&
    splitterAtTopLeft(state)
}
export function splitterRatioHorizontal (state) {
  return position => {
    let {
      splitter: {
        model,
        horizon,
        limits
      }
    } = state
    let ratio = 1
    if (!horizon) {
      ratio = (limits[1] - model) / limits[1]
      ratio = position === 'before'
        ? 1 - ratio
        : ratio
    }
    return ratio
  }
}
export function splitterRatioVertical (state) {
  return position => {
    let {
      splitter: {
        model,
        horizon,
        limits
      }
    } = state
    let ratio = 1
    if (horizon) {
      ratio = (limits[1] - model) / limits[1]
      ratio = position === 'before'
        ? 1 - ratio
        : ratio
    }
    return ratio
  }
}
export function afterIcon (state) {
  let { name } = sectionViewAfter(state)
  return `img:icons/icon-${name}.svg`
}
export function beforeIcon (state) {
  let { name } = sectionViewBefore(state)
  return `img:icons/icon-${name}.svg`
}
export function splitterIcons (state) {
  return (state.splitter.horizon)
    ? {
      middle: 'fa fa-grip-lines',
      top: splitterIsTopEdge(state)
        ? beforeIcon(state)
        : 'fa fa-arrow-down',
      bottom: splitterIsBottomEdge(state)
        ? afterIcon(state)
        : 'fa fa-arrow-up'
    }
    : {
      middle: 'fa fa-grip-lines-vertical',
      top: splitterIsLeftEdge(state)
        ? beforeIcon(state)
        : 'fa fa-arrow-right',
      bottom: splitterIsRightEdge(state)
        ? afterIcon(state)
        : 'fa fa-arrow-left'
    }
}
