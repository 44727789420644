import { Type, Presence } from 'sculp'
export const icons = [
  {
    icon: 'img:icons/icon-image-flip-reset.svg',
    value: 'scale(1, 1)'
  },
  {
    icon: 'img:icons/icon-image-flip-horizontal.svg',
    value: 'scale(1, -1)'
  },
  {
    icon: 'img:icons/icon-image-flip-vertical.svg',
    value: 'scale(-1, 1)'
  }
]
export const enums = [
  'scale(1, 1)',
  'scale(-1, 1)',
  'scale(1, -1)'
]
export const schema = {
  type: Type.STRING_NET,
  initial: enums[0],
  transform (v) {
    return enums.indexOf(v) === -1
      ? enums[0]
      : v
  },
  meta: 'Enumeration of classes for flips'
}
