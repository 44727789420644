import gh from 'geodocr-geohash'
export default {
  label ({ key, value, precision }) {
    let [ lat, lng ] = gh.toLatLng(value, precision)
    return `${value} [${lng}, ${lat}]`
  },
  key: true,
  component: 'geohash-display',
  icon: 'fa fa-hashtag',
  props (key, value) {
    let label
    if (value) {
      let { lat, lng } = gh.toLatLng(value)
      label = `${value} [${lng}, ${lat}]`
    } else {
      label = 'Not Found'
    }
    return {
      recordKey: key,
      geohash: value,
      label
    }
  }
}
