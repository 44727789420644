<template>
  <q-btn :icon="`${defaultMarker}`"
    @click="clickMarkerRuntime"
    :class="defaultClassesRuntime"
    :size="size"
    :dense="dense"
    :flat="flat"
  />
</template>

<script>
import { QBtn } from 'quasar'
export default {
  name: 'mapDefaultMarker',
  extends: QBtn,
  props: {
    clickMarker: {
      type: Function
    },
    defaultClasses: {
      type: [Array, Object],
      default: () => ({})
    },
    defaultMarker: {
      type: String,
      default: () => 'img:icons/marker-default.svg'
    }
  },
  data () {
    return {
      defaultClassesBase: {
        'z-max': true
      }
    }
  },
  methods: {
    clickMarkerRuntime (e) {
      if (this.clickMarker) {
        this.clickMarker(e)
      } else {
        this.$emit('click', e, this)
      }
    }
  },
  computed: {
    defaultClassesRuntime () {
      return (Array.isArray(this.defaultClasses))
        ? {
          ...this.defaultClasses.reduce((acc, c) => {
            acc[c] = true
            return acc
          }),
          ...this.defaultClassesBase
        }
        : {
          ...this.defaultClasses,
          ...this.defaultClassesBase
        }
    }
  }
}
</script>
