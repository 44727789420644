export function dbDocOther (state) {
  return rpid => state.dbDocOthers[rpid]
}
export function wirePeers (state) {
  return Object
    .entries(state.peers)
    .filter(([ key, value ]) => value)
}
export function messagesInReverse (state) {
  return [...state.messages].reverse()
}
