import identityRecords from './components/records'
import identityRecordDetail from './components/recordDetail'
import {
  SCHEMA_SPEC,
  PRIMARY_KEY,
  SCHEMA_NAME,
  WORKER_NAME
} from './constants'

import {
  IdentityWorkerInterface
} from './IdentityWorkerInterface'

const Worker = require(`worker-loader?name=identity-worker.js!./worker.js`)

export const plugin = ({ app, router, Vue, store, schemaSpec }) => {
  let worker = new IdentityWorkerInterface(Worker, {
    vm: store._vm,
    store,
    storeKey: SCHEMA_NAME,
    schemaName: SCHEMA_NAME,
    primaryKey: PRIMARY_KEY,
    schemaSpec
  })
  Vue.prototype[WORKER_NAME] = worker
  Vue.component('identity-records', identityRecords)
  Vue.component('identity-record-detail', identityRecordDetail)
  return worker
}
export store from './store'
export const schemaSpecMerge = { [SCHEMA_NAME]: SCHEMA_SPEC }
export const schemaName = SCHEMA_NAME
export const workerName = WORKER_NAME
