<template>
  <div :class="`horizontal-fixture-${position} q-pb-md`">
    <q-input bottom-slots v-model="message"
      ref="input"
      v-on:keyup.shift.enter="sendMessage"
      :label="`[${timehashTimestamp}] Log`"
      :loading="loading"
      type="textarea"
      :dense="dense"
      autofocus
      debounce
      autogrow
      standout
      counter
    >
      <template v-slot:before v-if="$q.screen.gt.md">
        <q-icon name="img:icons/icon-sync.svg" size="2em"/>
      </template>

      <template v-slot:prepend>
        <q-icon v-show="message !== ''"
          class="cursor-pointer"
          @click="message = ''"
          name="close"
        />
      </template>
      <template v-slot:before v-if="$q.screen.gt.md">
        <q-icon name="far fa-comment-dots" />
      </template>
      <template v-slot:hint>
        {{localTimestamp}} Send Message [Shift + Enter]
      </template>

      <template v-slot:after>
        <q-btn @click="sendMessage"
          icon="far fa-paper-plane"
          round
          dense
          flat
        />
      </template>
    </q-input>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { get, sync } from 'vuex-pathify'
export default {
  name: 'WireSendMessage',
  props: {
    position: String,
    dense: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      message: null,
      loading: false
    }
  },
  methods: {
    async sendMessage () {
      let vm = this
      vm.loading = true
      let {
        idHash,
        roomId,
        deviceHash,
        myPeerId
      } = vm
      let record = {
        timestamp: new Date().valueOf(),
        peerId: myPeerId,
        idHash,
        roomId,
        deviceHash,
        type: 'wire/userMessage',
        payload: {
          icon: 'far fa-user',
          deviceName: this.deviceName,
          message: this.message.trim()
        }
      }
      await vm.$WireWorker.setData(record)
      await vm.$WireWorker.ensureSyncDbDoc()
      this.message = ''
      await wait(300)
      this.$el.querySelector('textarea').focus()
      vm.$WireWorker.$emit('newRecord', { schema: 'wire', record })
      vm.loading = false
    },
    getTimestamp () {
      return new Date().valueOf()
    },
    getPeerColor (key) {
      return `#${key.slice(0, 6)}`
    },
    itemStyle (key) {
      return {
        'border-left-style': 'solid',
        'border-left-width': 'thick',
        'border-color': this.getPeerColor(key)
      }
    }
  },
  computed: {
    idHash: get('identity/idHash'),
    timestamp: get('wire/timestamp'),
    myPeerId: get('wire/sync@myPeerId'),
    roomPeerId: get('wire/sync@roomPeerId'),
    deviceName: get('layout/deviceId@name'),
    deviceHash: get('layout/deviceId@deviceHash'),
    roomId () {
      return (this.idHash)
        ? this.$WireWorker.calcRoomId(this.idHash)
        : null
    },
    localTimestamp () {
      return format(
        new Date(this.timestamp),
        'yyyy-MM-dd HH:mm:ss'
      )
    },
    timehashTimestamp () {
      return this.$timehash.encodems(this.timestamp)
    }
  }
}
</script>
