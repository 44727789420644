import fake from './fake'
import toLine from './toLine'
import abLine from './abLine'
import { schema } from './schema'
import initials from './initials'
import checks from './checks'
import * as constants from './constants'
import {
  keyHid,
  keyUid
} from './keySchema'

export default {
  fake,
  toLine,
  checks,
  abLine,
  schema,
  initials,
  constants,
  key: {
    hid: keyHid,
    uid: keyUid
  }
}
