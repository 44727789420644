// eslint-disable
import { getInitial } from 'sculp'
import { schema } from './schema'

function fake () {
  let fakeDocr
  if (process.env.DEV) {
    let faker = require('faker')
    let header = faker.fake('{{lorem.sentence}}')
    let text = faker.fake('{{lorem.sentences}}')
    let fakeDocr = getInitial(schema)
    fakeDocr.docr.content[1].content[0].text = text
    fakeDocr.title.content[0].text = header
    fakeDocr.docr.content[0] = fakeDocr.title
  }
  return fakeDocr
}

export default fake
