import { Type, Presence } from 'sculp'
import {
  timehashLib
} from '../libs'
import constants from './constants'
import * as regexps from '../regexps'

export const timehash = {
  type: Type.STRING_NET,
  $presence: Presence.REQUIRED,
  initial: () => timehashLib.encodems(new Date().valueOf()),
  $regexp: regexps.timehash,
  transform: (v) => v ? v.toLowerCase() : '',
  $max: 10,
  $min: 1
}

export const schema = {
  type: Type.OBJECT,
  properties: (() => {
    return constants.timestampEnum.reduce((agg, prop) => {
      agg[prop] = { ...timehash }
      return agg
    }, {})
  })()
}
