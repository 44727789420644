/* eslint-disable */
import crsLandfill from './leaflet/libs/crs/104741_landfill.json'

import {
  plugin as utilPlugin
} from './util'

import {
  store as wireStore,
  plugin as wirePlugin,
  schemaName as wireSchemaName,
  schemaSpecMerge as wireSpecMerge
} from './wire'

import {
  store as identityStore,
  plugin as identityPlugin,
  schemaName as identitySchemaName,
  schemaSpecMerge as identitySpecMerge
} from './identity'

import {
  store as docrStore,
  plugin as docrPlugin,
  schemaName as docrSchemaName,
  schemaSpecMerge as docrSpecMerge
} from './docr'

import {
  fields as mediaFields,
  store as mediaStore,
  plugin as mediaPlugin,
  schemaName as mediaSchemaName,
  schemaSpecMerge as mediaSpecMerge
} from './media'

import {
  store as leafletStore,
  plugin as leafletPlugin,
  schemaName as leafletSchemaName,
  schemaSpecMerge as leafletSpecMerge
} from './leaflet'

import { utils } from '../utils'
// NEED TO MERGE SPECS BEFORE ANY DB OPENS
const schemaSpec = {
  ...wireSpecMerge,
  ...identitySpecMerge,
  ...docrSpecMerge,
  ...mediaSpecMerge,
  ...leafletSpecMerge
}

export default async ({ app, router, Vue, store }) => {
  let pluginArgs = {
    app,
    router,
    Vue,
    store,
    schemaSpec
  }
  store.registerModule(docrSchemaName, docrStore)
  let docrWorker = docrPlugin(pluginArgs)
  await docrWorker.isReady()
  utils.fireLoadingEvent(document)

  store.registerModule(mediaSchemaName, mediaStore)
  let mediaWorker = mediaPlugin(pluginArgs)
  await mediaWorker.isReady()
  utils.fireLoadingEvent(document)

  store.registerModule(leafletSchemaName, leafletStore)
  let leafletWorker = leafletPlugin(pluginArgs)
  await leafletWorker.isReady()
  utils.fireLoadingEvent(document)

  store.registerModule(identitySchemaName, identityStore)
  let identityWorker = identityPlugin(pluginArgs)
  await identityWorker.isReady()
  utils.fireLoadingEvent(document)
  
  store.registerModule(wireSchemaName, wireStore)
  let wireWorker = wirePlugin(pluginArgs)
  await wireWorker.isReady()
  utils.fireLoadingEvent(document)

  Vue.prototype.$dexieFields = {
    media: mediaFields
  }

  Vue.prototype.$dexieWorkers = {
    util: utilPlugin(pluginArgs),
    identity: identityWorker,
    leaflet: leafletWorker,
    media: mediaWorker,
    wire: wireWorker,
    docr: docrWorker
  }
  console.log('$dexieWorkers', Vue.prototype.$dexieWorkers)
  utils.fireLoadingEvent(document)
}
