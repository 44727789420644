import axios from 'axios'
import { utils } from './utils'
export default async ({ Vue }) => {
  Vue.prototype.$axios = axios
  utils.fireLoadingEvent(document)
}

export {
  axios
}
