export const SCHEMA_NAME = 'tileLayers'
export const STORE_KEY = 'dexieOffline'
export const WORKER_NAME = '$TileWorker'
export const SCHEMA_SPEC = '&key, url, description'
export const HASH_COLUMNS = ['key']

export const SPATIAL_CRS_KEY_PREFIX = 'spatial-crs'

export const COLUMNS = SCHEMA_SPEC.split(', ').reduce((acc, val) => {
  val = val.replace(/(\*|\+|&)/ig, '')
  acc.push(val)
  return acc
}, [])

export const PRIMARY_KEY = COLUMNS[0]
