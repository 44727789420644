import { L } from '../leaflet/libs'
import { sanitizeUrl } from '@braintree/sanitize-url'
import {
  EditorInterface
} from 'geodocr-editor'
import { uid } from 'quasar'
import { timehashApi } from '../timehash'
let editor
function getEditorInterface ({ vm, Vue, store, uid }) {
  if (!editor) {
    editor = new EditorInterface({
      vm,
      Vue,
      uid,
      store,
      mode: 'editable',
      events: vm.$dexieWorkers.docr.events,
      components: {
        imageIconComponent: 'geodocr-icon-image',
        map: 'geodocr-main-map'
      },
      api: {
        L,
        util: {
          ...vm.$dexieWorkers.util.api()
        },
        docr: {
          ...vm.$dexieWorkers.docr.keyStore(),
          ...vm.$dexieWorkers.docr.api()
        },
        media: {
          ...vm.$dexieWorkers.media.keyStore(),
          ...vm.$dexieWorkers.media.api()
        },
        map: {
          ...vm.$leaflet.api(),
          ...vm.$dexieWorkers.leaflet.api()
        },
        timehash: timehashApi,
        geohash: { ...vm.$geohash },
        icons ({ type, name }) {
          return sanitizeUrl(`img:icons/${type}-${name}.svg`)
        }
      }
    })
  }
  return editor
}

export default getEditorInterface
