import { Type, Presence } from 'sculp'
import { sha512 } from '../regexps'
export const schema = {
  type: Type.OBJECT,
  $presence: Presence.REQUIRED,
  properties: {
    hasPassword: {
      type: Type.BOOLEAN,
      $presence: Presence.REQUIRED,
      meta: 'Is Record Password Encrypted?'
    },
    uid: {
      type: Type.STRING,
      $presence: Presence.REQUIRED,
      meta: 'Arbitrary Unique uid [prefer idHash for client records if conflict]'
    },
    idHash: {
      type: Type.STRING,
      $presence: Presence.REQUIRED,
      $regexp: sha512,
      meta: ' Unique identity Hash in record.identity'
    },
    recordB64: {
      type: Type.STRING,
      $presence: Presence.REQUIRED,
      meta: 'Record Data as [encrypt] -> b64 -> JSON [may be encrypted with password]'
    }
  }
}
