import LRU from 'lru-cache'
import crs4326 from './libs/crs/4326.json'
import {
  DexieWorkerCrsInterface
} from './DexieWorkerCrsInterface'
export const defaultOpts = {
  vm: null,
  store: null,
  schemaSpec: null,
  schemaName: null
}
export class LeafletWorkerInterface extends DexieWorkerCrsInterface {
  constructor (Worker, opts = defaultOpts) {
    super(Worker, opts)
    let self = this
    self.cachedTiles = new LRU({
      max: 200,
      maxAge: 1000 * 60 * 60 // 1 hour
    })
    self.cachedConversions = new LRU({
      max: 1000,
      maxAge: 1000 * 60 * 60 // 1 hour
    })
  }
  filterAvailableCrs ({ filter, crs }) {
    let regexp = new RegExp(`(${filter})`, 'gi')
    let filtered = crs.filter(
      c => regexp.test(c.name) ||
      regexp.test(c.epsg) ||
      (c.matrix && c.matrix.name && regexp.test(c.matrix.name))
    )
    return filtered
  }
  findProj4Units (proj4) {
    let matches = proj4.match(/\+units=([A-Za-z-]+)/)
    return matches && matches.length > 0
      ? matches[1]
      : null
  }
  nodeToNamedCrs ({
    refDescription,
    refType,
    refName,
    crsType,
    crsData
  }, matrix = {}) {
    return {
      epsg: `${refType.toUpperCase()}:${refName}`,
      name: refDescription,
      proj4: crsData,
      units: this.findProj4Units(crsData),
      matrix: matrix || {}
    }
  }
  api () {
    let self = this
    return {
      leafletCrs: crs4326,
      leafletLayerUnits: 'm',
      calculateExtent: self.calculateExtent.bind(self),
      nodeToNamedCrs: self.nodeToNamedCrs,
      async availableCrs ({ filter }) {
        let result = await self.availableNamedCrs(filter)
        return result
      },
      async encodeCrs ({ crs }) {
        let result = await self.encodeNamedCrs(crs)
        return result
      },
      async decodeCrs ({ crs }) {
        let result = await self.decodeNamedCrs(crs)
        return result
      },
      async convertInverse ({
        cacheKey,
        to,
        from,
        coord,

        translations
      }) {
        let converted, extent
        if (cacheKey && self.cachedConversions.has(`inverse-${cacheKey}`)) {
          return self.cachedConversions.get(`inverse-${cacheKey}`)
        }
        if (translations) {
          ({ coord: converted } = await self.translateInverse({
            translations,
            coord
          }))
        }
        ({ coord: converted, extent } = await self.transformInverse({
          getExtent: true,
          proj4To: to,
          proj4From: from,
          coord: converted
        }))
        let result = {
          coord: converted,
          extent
        }
        if (`inverse-${cacheKey}`) {
          self.cachedConversions.set(`inverse-${cacheKey}`, result)
        }
        return result
      },
      async convertForward ({
        cacheKey,
        to,
        from,
        coord,
        translations
      }) {
        if (cacheKey && self.cachedConversions.has(`forward-${cacheKey}`)) {
          return self.cachedConversions.get(`forward-${cacheKey}`)
        }
        let extent
        let { coord: converted } = await self.transformForward({
          proj4To: to,
          proj4From: from,
          getExtent: true,
          coord
        })
        if (translations) {
          ({ coord: converted, extent } = await self.translateForward({
            getExtent: true,
            translations,
            coord: converted
          }))
        }
        let result = {
          coord: converted,
          extent
        }
        if (`forward-${cacheKey}`) {
          self.cachedConversions.set(`forward-${cacheKey}`, result)
        }
        return result
      },
      getKey (url) {
        return url.split('.').slice(1).join('.')
      },
      getItem: function (url, coord) {
        let key = this.getKey(url)
        return new Promise(async (resolve, reject) => {
          let cachedTile = self.cachedTiles.get(key)
          if (cachedTile) {
            return resolve(cachedTile)
          }
          let message = {
            db: self.dbName,
            schema: self.schemaName,
            handler: 'lookupTile',
            key,
            url,
            coord
          }
          self.message(message, (result) => {
            if (result.error) reject(result.error)
            let { blob } = result
            if (blob) {
              self.cachedTiles.set(key, blob)
              resolve(blob)
            } else {
              reject(new Error('NoBlob'))
            }
          })
        })
      },
      saveTiles: function (tilesUrls) {
        return new Promise((resolve, reject) => {
          self.message({
            handler: 'setTileUrls',
            schema: self.schemaName,
            db: self.dbName,
            value: tilesUrls
          }, (result) => {
            resolve(result)
          })
        })
      },
      clear: function () {
        return new Promise((resolve, reject) => {
          resolve()
        })
      }
    }
  }
}
