var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-btn',{staticClass:"text-black full-width",attrs:{"icon":"fa fa-file-signature","label":"EXPORT IDENTITY","color":"warning","size":"sm","dense":"","push":""}},[_c('q-popup-proxy',{directives:[{name:"close-popup",rawName:"v-close-popup"}],ref:"exportDialog",attrs:{"transition-show":"flip-up","transition-hide":"flip-down"}},[_c('q-card',[_c('close-dialog-button',{on:{"close":function () { return _vm.$refs.exportDialog.toggle(); }}}),_c('q-item-section',{staticClass:"bg-primary q-pa-sm"},[_c('q-item-label',{staticClass:"text-info",attrs:{"overline":""}},[_c('q-icon',{attrs:{"name":"fa far fa-envelope"}}),_vm._v("\n            "+_vm._s(_vm.record.identity.email)+"\n          ")],1),_c('q-item-label',[_c('q-icon',{attrs:{"size":"2em","name":"fa fa-key","color":"yellow"}}),_vm._v("\n          Export Identity File\n        ")],1),_c('q-item-label',{staticClass:"text-info",attrs:{"caption":""}},[_vm._v("\n          GEODOCR Recommends Password Protection for Identity Files\n        ")])],1),_c('q-card-actions',{staticClass:"bg-primary",attrs:{"vertical":""}},[_c('form',[_c('q-input',{staticClass:"col-grow q-py-sm",attrs:{"label":"Password","type":"password","color":"accent","autofocus":"","outlined":""},model:{value:(_vm.exportPassword),callback:function ($$v) {_vm.exportPassword=$$v},expression:"exportPassword"}}),_c('div',{staticClass:"text-green text-subtitle2"},[_vm._v("\n            Password Strength [minimum Length: 8, Lower Case, Upper Case, Special Characters]\n          ")]),_c('q-linear-progress',{staticClass:"q-mt-sm",attrs:{"value":_vm.exportPasswordStrengthRatio,"color":_vm.exportPasswordStrengthRatio > 0.8
              ? 'positive'
              : _vm.exportPasswordStrengthRatio > 0.5
                ? 'warning'
                : 'negative',"size":"20px","rounded":"","stripe":"","dark":""}}),_c('q-input',{staticClass:"col-grow q-py-sm",attrs:{"label":"Duplicate Password","type":"password","color":"accent","outlined":""},model:{value:(_vm.exportPasswordDupe),callback:function ($$v) {_vm.exportPasswordDupe=$$v},expression:"exportPasswordDupe"}})],1),_c('q-btn',{attrs:{"disable":!_vm.exportPasswordValid,"icon":"fa fa-lock","color":"amber","label":"Export with Password","outline":""},on:{"click":function($event){_vm.$IdentityWorker
          .exportUserFile(_vm.record.uid, {
            password: _vm.exportPassword,
            startDownload: true
          })}}})],1),_c('q-card-actions',{attrs:{"vertical":""}},[_c('q-separator'),_c('q-btn',{attrs:{"label":"Export without Password","icon":"fa fa-lock-open","color":"warning","outline":""},on:{"click":function($event){return _vm.$IdentityWorker.exportUserFile(_vm.record.uid)}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }