import UtilWorkerInterface from './UtilWorkerInterface'

const UtilWorker = require(`worker-loader?name=util-worker.js!./util-worker.js`)
export const plugin = ({ app, router, Vue, store, schemaSpec }) => {
  const worker = new UtilWorkerInterface(UtilWorker, store._vm, {
    workerCount: navigator.hardwareConcurrency
  })
  return worker
}
export default UtilWorker
