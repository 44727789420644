/*
import * as fuzzy from './fuzzy'
import * as encode from './encode'
import * as decode from './decode'
import { commonPrefix } from './prefix'
import * as geohashJson from './geohashJson'
import * as normalize from './normalize'
import * as graticule from './graticule'
import * as constants from './constants'
*/
import { utils } from '../utils'
import geodocrGeohash from 'geodocr-geohash'
import geohashGrid from './components/geohashGrid'
export default ({ app, router, Vue }) => {
  Vue.prototype.$geohash = geodocrGeohash
  Vue.component('geohash-grid', geohashGrid)
  utils.fireLoadingEvent(document)
}
