<template>
  <q-item clickable
    @click="nixData(record)"
  >
    <q-item-section side>
      <q-icon
        name="fa fa-trash"
        color="negative"
        flat
      />
    </q-item-section>
    <q-item-section>
      DELETE
    </q-item-section>
  </q-item>
</template>
<script>
export default {
  name: 'NixRecordItem',
  props: {
    worker: Object,
    primaryKey: String,
    record: Object,
    finishHandler: Function
  },
  methods: {
    async nixData (record) {
      let {
        primaryKey
      } = this
      await this.worker.nixData({ values: record[primaryKey] })
      this.$emit('finish', record)
      if (this.finishHandler) {
        this.finishHandler(record)
      }
    }
  }
}
</script>
