<template>
  <div class="row self-end">
    <q-btn-dropdown class="self-end q-mx-sm no-print"
      ref="dropdown"
      v-model="dropdownModel"
      @show="scrollToZoomElement"
      color="primary"
      dropdown-icon="fa fa-ruler-horizontal"
      dense
    >
      <template v-slot:label class="row">
        <q-btn @click.stop="$emit('update:scaleLock', !scaleLock)"
          :icon="scaleLock ? 'fa fa-lock' : 'fa fa-unlock'"
          :color="scaleLock ? 'yellow-14' : 'green'"
        />
        <transition
          appear
          enter-active-class="animated slideInRight"
          leave-active-class="animated slideOutRight"
        >
          <div v-show="dropdownModel">select scale</div>
        </transition>
      </template>
      <q-list ref="scaleList">
        <template v-for="scale in $leaflet.scales">
          <q-item @click="setZoomToScale(scale)"
            ref="scaleListItem"
            :class="`bg-${scale === oneUnitScale ? 'secondary' : 'dark'}`"
            :clickable="!scaleLock"
            v-close-popup
            dense
            :key="scale">
            <q-item-section v-if="scaleLock && scale === oneUnitScale" avatar>
              <q-icon name="fa fa-lock" color="yellow-14"/>
            </q-item-section>
            <q-item-section>
              <q-item-label>1:{{scale}}</q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </q-btn-dropdown>
    <div class="self-end">
      <svg :width="`${paperUnitScaleSize}${paperUnits}`"
        :height="`${paperUnitScaleSize / 4}${paperUnits}`"
        ref="scaleBounds"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0"
          width="1in"
          height="0.5in"
          fill="#ffffff99"
          stroke="#040404"
          stroke-width="3"
        />
        <path stroke="#085462"
          :stroke-dasharray="`${cannonScale.px / 4}`"
          v-if="cannonScale"
          :d="`M${cannonScale.px / 4},2 l${cannonScale.px},0`"
          stroke-width="3"
        />
        <path stroke="#6e9709"
          :stroke-dasharray="cannonScale.px / 4"
          v-if="cannonScale"
          :d="`M0,2 l${cannonScale.px},0`"
          stroke-width="3"
        />
        <text x="0in" y="0in"
          fill="#000000"
          font-size="1em"
          stroke-width=".24">
          <tspan x="0.5in" y="0.2in" stroke-width=".25" text-align="center" text-anchor="middle">{{oneUnitScale}}-{{mapUnits}}</tspan>
        </text>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SelectStandardScale',
  props: {
    map: Object,
    mapUnits: {
      type: String,
      default: 'ft'
    },
    scaleLock: Boolean,
    scaleLockTarget: Number,
    paperUnits: String,
    paperUnitScaleSize: Number
  },
  data () {
    return {
      dropdownModel: false,
      oneUnitScale: 0,
      cannonScale: null
    }
  },
  mounted () {
    let vm = this
    if (vm.scaleLockTarget && vm.scaleLock) {
      vm.oneUnitScale = vm.scaleLockTarget
    } else {
      vm.oneUnitScale = vm.calcScale()
    }
    vm.map.on('zoomend', this.updateScale)
    vm.updateScale()
  },
  methods: {
    async updateScale () {
      let vm = this
      let isTarget = vm.scaleLockTarget === vm.calcScale()
      if (isTarget) return
      if (vm.scaleLock) {
        vm.map.off('zoomend', vm.updateScale)
        await vm.setZoomToScale(vm.scaleLockTarget)
        vm.map.on('zoomend', vm.updateScale)
      } else {
        let scale = vm.calcScale()
        vm.oneUnitScale = scale
        vm.$emit('update:scaleLockTarget', scale)
      }
    },
    calcScale () {
      this.cannonScale = window.getUnits(this.$refs.scaleBounds, 'width')
      let metersPerPx = this.$geohash.Qty(this.$leaflet.calcMpp(this.map), 'm')
      return metersPerPx
        .mul(this.cannonScale.px)
        .to(this.mapUnits)
        .toPrec(1)
        .scalar
    },
    scrollToZoomElement () {
      let vm = this
      let innerListItem = vm.$refs.scaleListItem.find(e => e.$vnode.key === vm.oneUnitScale) ||
        vm.$refs.scaleListItem.find(e => e.$vnode.key > vm.oneUnitScale) ||
        vm.$refs.scaleListItem[vm.$refs.scaleListItem.length - 1]
      if (innerListItem) return innerListItem.$el.scrollIntoView()
    },
    async setZoomToScale (targetScale) {
      let vm = this
      let { L } = vm.$leaflet
      let mapCenter = vm.map.getCenter()
      let scale = vm.calcScale()
      let mapZ = vm.map.getZoom()
      let zoom = vm.map.getScaleZoom(scale / targetScale, mapZ)
      let promise = new Promise((resolve, reject) => vm.map.once('zoomend', resolve))
      vm.map.setView(mapCenter, zoom)
      await promise
      return vm.approachZoomToScale(targetScale)
    },
    async approachZoomToScale (targetScale) {
      let vm = this
      let z = vm.map.getZoom()
      let mapCenter = vm.map.getCenter()
      let currentScale = vm.calcScale()
      if (currentScale === targetScale) return
      let increment = 0.1
      let pivot = false
      let direction = currentScale < targetScale
      let maxIter = 100
      while (targetScale !== currentScale) {
        if (pivot) {
          increment = increment * 0.1
          pivot = false
        }
        z = direction
          ? vm.map.getZoom() - increment
          : vm.map.getZoom() + increment
        let promise = new Promise((resolve, reject) => vm.map.once('zoomend', resolve))
        vm.map.setView(mapCenter, z)
        await promise
        currentScale = vm.calcScale()
        let newDirection = currentScale < targetScale
        pivot = direction !== newDirection
        direction = newDirection
        maxIter--
        if (maxIter < 0) break
        if (z > 24 || z < 0) break
      }
      let scale = this.calcScale()
      vm.oneUnitScale = scale
      vm.$emit('update:scaleLockTarget', scale)
      return scale
    }
  }
}
</script>
