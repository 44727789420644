import calcRoomId from './calcRoomId'
const { WebrtcProvider, Room } = window['y-webrtc']
const { IndexeddbPersistence } = window['y-indexeddb']
export class Manifest {
  constructor (store, { idHash, deviceHash }) {
    this.store = store
    this.idHash = idHash
    this.exist = this.now()
    this.deviceHash = deviceHash
    this.manifest = new Y.Doc()
    this.indexeddbProvider = new IndexeddbPersistence(deviceHash, this.manifest)
    this.indexeddbProvider.whenSynced.then(() => {
      this._onManifestUpdate = this.onManifestUpdate.bind(this)
      this._onManifestAfterUpdate = this.onManifestAfterUpdate.bind(this)
      this.manifest.on('update', this._onManifestUpdate)
      this.manifest.on('afterTransaction', this._onManifestAfterUpdate)
      this.provider = new WebrtcProvider(calcRoomId(idHash), this.manifest, {
        password: calcRoomId(`password-${idHash}`)
        /* signaling: ['wss://io.geodocr.com'] */
      })
      console.log(this.provider)
      this.provider.key.then(() => {
        this.provider.on('peers', (args) => {
          console.log('peers', args)
        })
        this.manifest.transact(() => {
          this.metaMap().set(this.deviceHash, this.selfMeta())
        }, {
          type: 'begin',
          synced: this.room.synced,
          deviceHash: this.deviceHash
        })
      })
    })
  }
  onManifestAfterUpdate (transaction, manifest) {
    let metaMap = this.metaMap()
    if (transaction.local) {
      console.log(`onManifestAfterUpdate[local][${this.room.synced}]`, manifest, transaction, metaMap.has(this.deviceHash))
    } else {
      console.log(`onManifestAfterUpdate[remote][${transaction.origin.synced}]`, manifest, transaction, metaMap.has(this.deviceHash))
    }
  }
  onManifestUpdate (update, origin, manifest) {
    let metaMap = this.metaMap(manifest)
    let meta = [...metaMap.entries()]
    this.store.set('wire/sync@meta', meta)
    if (origin) {
      console.log(`onManifestUpdate[${origin.synced}][${origin.type || origin.peerId}]`, manifest, metaMap.has(this.deviceHash))
      if (origin.type === 'begin') {
        metaMap.set(this.deviceHash, this.selfMeta())
      } else if (origin.type === 'cease' && this.prepDestroy) {
        console.log('cease')
        this.prepDestroy()
      }
    }
  }
  metaMap (manifest) {
    let meta = (manifest || this.manifest).getMap('meta')
    return meta
  }
  selfMeta () {
    let self = this
    let hasMeta = self.metaMap().has(self.deviceHash)
    if (hasMeta) {
      let meta = self.metaMap().get(self.deviceHash)
      return {
        ...meta,
        begin: self.now()
      }
    }
    return {
      exist: self.exist,
      begin: self.exist,
      cease: self.exist,
      deviceHash: self.deviceHash
    }
  }
  get peerId () {
    return this.room.peerId
  }
  get room () {
    return this.provider.room
  }
  now () {
    return dayjs().tz('Universal').unix()
  }
  destroy () {
    let metaMap = this.metaMap()
    if (metaMap.has(this.deviceHash)) {
      let selfMeta = metaMap.get(this.deviceHash)
      selfMeta.cease = this.now()
      this.manifest.transact(() => {
        metaMap.set(this.deviceHash, selfMeta)
      }, { synced: this.room.synced, type: 'cease' })
    }
    this.provider.destroy()
  }
}

export default Manifest
