export default {
  exist: {
    component: 'timehash-display',
    icon: 'fa fa-clock',
    props (key, val) {
      return {
        recordKey: key,
        timehash: val,
        disable: true
      }
    }
  },
  begin: {
    component: 'timehash-display',
    icon: 'fa fa-hourglass-start',
    props (key, val) {
      return {
        recordKey: key,
        timehash: val,
        disable: true
      }
    }
  },
  pivot: {
    component: 'timehash-display',
    icon: 'fa fa-hourglass-half',
    props (key, val) {
      return {
        recordKey: key,
        timehash: val,
        disable: true
      }
    }
  },
  cease: {
    key: true,
    component: 'timehash-display',
    icon: 'fa fa-hourglass-end',
    props (key, val) {
      return {
        recordKey: key,
        timehash: val,
        disable: false
      }
    }
  }
}
