import { utils } from './utils'
import TimeHash from 'time-hash'
import { format } from 'date-fns'
const timehash = new TimeHash()
const timehashApi = {
  ...timehash,
  toTimestamp (th) {
    let timestamp = timehash.decodems(th)
    return format(
      new Date(timestamp),
      'yyyy-MM-dd HH:mm:ss'
    )
  },
  timestamp: () => timehash.encodems(new Date().valueOf()),
  toTimehash (val) {
    return timehash.encodems(val.valueOf())
  },
  fromTimehash (val) {
    return new Date(timehash.decodems(val))
  }
}
export default async ({ Vue }) => {
  Vue.prototype.$timehash = timehashApi
  utils.fireLoadingEvent(document)
}
export {
  timehashApi
}
