import {
  WORKER_NAME,
  SCHEMA_NAME,
  SCHEMA_SPEC,
  PRIMARY_KEY,
  STORE_KEY,
  COLUMNS
} from '../constants'

export default {
  worker: null,
  results: {
    cache: {},
    latest: null,
    count: 0
  },
  [STORE_KEY]: { // storeKey
    schemaSpec: SCHEMA_SPEC,
    workername: WORKER_NAME,
    schema: SCHEMA_NAME, // dexiejs table
    columns: COLUMNS,
    key: PRIMARY_KEY, // primary key for the offline dexie.jks
    sum: null, // required props on storekey
    ready: false, // is set once the schema is ready
    db: '__global' // dexiejs database
  }
}
