<template>
  <div class="flex flex-center column">
    <q-pagination v-model="currentPage"
      color="neutral"
      :max="totalPages"
    />
    <q-list v-if="identityRecords" class="full-width">
      <template v-for="record in identityRecords">
        <identity-record-detail :key="record.uid"
          @activate="activateIdentity"
          @refresh="refreshProperties"
          @deleted="$asyncComputed.identityRecords.update"
          :record="record"
          :dense="dense"
          :isActive="record.uid === currentUid"
          :id-uid="currentUid"
          :id-hash="currentIdHash"
          :jwt-token="currentJwtToken"
        />
        <q-separator :key="`${record.uid}-separator`" />
      </template>
    </q-list>
  </div>
</template>

<script>
// import 'public/js/localforage.min.js'
export default {
  name: 'IdentityRecords',
  props: {
    recordsLimit: {
      default: 10,
      type: Number
    },
    dense: Boolean,
    currentUid: String,
    currentIdHash: String,
    currentJwtToken: String,
    fields: {
      type: Array,
      default () {
        return ['idHash', 'identity', 'uid', 'exist', 'allowSyncNotification']
      }
    }
  },
  data () {
    return {
      loaded: false,
      recordsOffset: 0,
      serviceWorkerKeys: []
    }
  },
  async mounted () {
    if (this.idUid) {
      this.activateIdentity({
        idHash: this.idHash,
        uid: this.idUid
      })
    }
    this.loaded = true
  },
  methods: {
    async refreshProperties ({ idHash, properties }) {
      let key = `notification:id:${idHash}`
      let existsIndex = this.serviceWorkerKeys.indexOf(key)
      if (existsIndex !== -1) {
        let currentProperties = await localforage.getItem(key)
        properties = Object.assign(currentProperties, properties)
      }
      await localforage.setItem(key, properties)
      return this.$asyncComputed.identityRecords.update()
    },
    activateIdentity (record) {
      this.$IdentityWorker
        .getData({
          values: record.uid
        }, {
          fields: ['jwtToken']
        }).then(({ result }) => {
          this.exportPassword = null
          this.exportPasswordDupe = null
          if (result.length === 0) {
            // record doesn't exist
            this.$emit('update:currentUid', null)
            this.$emit('update:currentIdHash', null)
            this.$emit('update:currentJwtToken', null)
          } else {
            this.$emit('update:currentUid', record.uid)
            this.$emit('update:currentIdHash', record.idHash)
            this.$emit('update:currentJwtToken', result[0].jwtToken)
          }
        })
    },
    isActiveIdentity (record) {
      return record.uid === this.currentUid
    }
  },
  computed: {
    activeIdentity () {
      return (this.identityRecords)
        .find(record => this.isActiveIdentity(record))
    },
    currentPage: {
      get () {
        return Math.floor((this.recordsOffset + this.recordsLimit) / this.recordsLimit)
      },
      set (val) {
        this.recordsOffset = (this.recordsLimit * val) - this.recordsLimit
      }
    },
    totalPages () {
      return parseInt(this.identityCount / this.recordsLimit) + 1
    }
  },
  asyncComputed: {
    async identityRecords () {
      return (this.identityKeys)
        ? this.$IdentityWorker
          .getData({
            values: this.identityKeys
          }, {
            orderBy: 'exist',
            reverse: true,
            fields: this.fields
          })
          .then(({ result }) => result)
        : null
    },
    async identityCount () {
      return (this.currentUid === null ||
        (this.idUid && this.recordsOffset >= 0) ||
        this.loaded
      ) ? this.$IdentityWorker.countData()
          .then(({ result }) => result)
        : null
    },
    async serviceWorkerKeys () {
      return (this.identityKeys)
        ? localforage.keys()
        : null
    },
    async identityKeys () {
      return (this.currentUid === null ||
        (this.currentUid && this.recordsOffset >= 0) ||
        this.loaded
      ) ? this.$IdentityWorker
          .getKeys({
            orderBy: 'exist',
            offset: this.recordsOffset,
            limit: this.recordsLimit
          })
          .then(({ result }) => result)
        : null
    }
  }
}
</script>
