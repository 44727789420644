const toLine = (line) => {
  let { timestamp, ...d } = line
  let { identity } = d.attribution
  return {
    ...d,
    identity,
    ...timestamp
  }
}

export default toLine
