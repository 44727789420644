import { STORE_NAME } from '../constants'
import isEqual from 'lodash.isequal'
export function refreshFixtures ({ state, getters }) {
  let vertical = [...document.querySelectorAll('.vertical-fixture')]
  let horizontal = [...document.querySelectorAll('.horizontal-fixture')]
  let before = [...document.querySelectorAll('.horizontal-fixture-before')]
  let after = [...document.querySelectorAll('.horizontal-fixture-after')]

  let verticalRects = vertical.map(e => getBoundingClientRect(e))
  let horizontalRects = horizontal.map(e => getBoundingClientRect(e))
  let beforeRects = before.map(e => getBoundingClientRect(e))
  let afterRects = after.map(e => getBoundingClientRect(e))

  let noHorizontalChange = isEqual(verticalRects, getters.vrRects)
  let noVerticalChange = isEqual(horizontalRects, getters.hrRects)
  let noBeforeChange = isEqual(beforeRects, getters.beforeHrRects)
  let noAfterChange = isEqual(afterRects, getters.afterHrRects)
  if (noHorizontalChange &&
    noVerticalChange &&
    noBeforeChange &&
    noAfterChange
  ) return
  // if changes.
  let newFixtures = {
    horizontal,
    vertical,
    before,
    after
  }
  console.log(`${STORE_NAME}/fixtures [change]`, noHorizontalChange, noVerticalChange, noBeforeChange, noAfterChange)
  this.set(`${STORE_NAME}/fixtures`, newFixtures)
}
