<template>
  <q-item clickable
    class="q-px-xs"
    @click="$emit('activate', record)"
  >
    <q-item-section side class="q-pa-xs column relative-position">
      <q-spinner-radio v-if="isActive && $asyncComputed.idValid.updating" class="col-6  q-pb-sm"/>
      <q-icon v-else-if="isActive && !$asyncComputed.idValid.updating"
        class="col-6 q-pb-sm"
        name="fa fa-check-circle"
        color="positive"
        size="sm"
        outline
      />
      <q-icon v-else
        class="cursor-pointer col-6  q-pb-sm"
        @click="$emit('activate', record)"
        name="far fa-circle"
        color="neutral"
        outline
      />
      <template v-if="isActive">
        <q-badge v-if="$asyncComputed.idValid.updating" color="info" outline class="col-6">
          <q-icon name="fa fa-server"/>
          |
          <q-icon name="fa fa-info">
            <q-tooltip>
              Checking Remote Server is available and that this identity has Interactive Permissions.
            </q-tooltip>
          </q-icon>
        </q-badge>
        <q-badge v-else-if="idValid" outline color="positive" class="col-6">
          <q-icon name="fa fa-server"/>
          |
          <q-icon name="fa fa-network-wired">
            <q-tooltip>
              Remote Server is Accessible and this Identity has Interactive Permissions.
            </q-tooltip>
          </q-icon>
        </q-badge>
        <q-badge v-else color="warning" outline class="col-6">
          <q-icon name="fa fa-server"/>
          |
          <q-icon name="fa fa-times">
            <q-tooltip>
              Remote Server is unavailable or this identity does not have Interactive Permissions.
            </q-tooltip>
          </q-icon>
        </q-badge>
      </template>
    </q-item-section>
    <q-item-section avatar>
      <q-item-label v-if="isActive" overline>
        <template v-if="idValid">
          <q-badge floating v-if="hasNotificationsDenied" color="negative">
            <q-icon name="fa fa-comment-slash"/>
            <q-tooltip>
              Browser Notifications are Disabled.
            </q-tooltip>
          </q-badge>
          <q-badge floating v-else-if="hasNotificationsGranted" color="positive">
            <q-icon name="fa fa-comment"/>
            <q-icon name="fa fa-check-circle" v-if="allowSyncNotification"/>
            <q-icon name="fa fa-times" v-else/>
            <q-tooltip>
              Browser Notifications are Enabled.
            </q-tooltip>
          </q-badge>
          <q-badge floating v-else-if="hasNotificationsDefault" color="primary">
            <q-icon name="fa fa-comment"/>
            <q-icon name="fa fa-question-circle"/>
            <q-tooltip>
              Browser Notifications have not been Enabled.
            </q-tooltip>
          </q-badge>
        </template>
      </q-item-label>
    </q-item-section>
    <q-item-section class="col-sm-6 col-xl-grow">
      <q-item-label overline>
        <q-icon name="fa far fa-envelope" />
        {{record.identity.email}}
      </q-item-label>
      <q-item-label v-if="record.identity.name">
        <q-icon name="fa far fa-user-circle" />
        {{record.identity.name}}
      </q-item-label>
      <q-item-label overline v-if="record.identity.projectName">
        <q-icon name="fa far fa-briefcase" />
        {{record.identity.projectName}}
      </q-item-label>
      <q-item-label overline v-if="record.identity.telephone">
        <q-icon name="fa far fa-briefcase" />
        {{record.identity.telephone}}
      </q-item-label>
    </q-item-section>
    <q-item-section avatar v-if="isActive" class="col-grow">
      <q-btn @click.stop="visible = true"
        icon="fa fa-cogs"
        color="info"
        size="xs"
        outline
      />
    </q-item-section>
    <!-- CONFIG DIALOG -->
    <q-dialog v-model="visible">
      <div class="relative-position bg-primary fit q-pa-sm row content-stretch justify-between items-stretch">
        <close-dialog-button @close="visible = false" />
        <q-banner v-if="!idValid" class="full-width">
          <q-spinner-radio v-if="isActive && $asyncComputed.idValid.updating" size="3em"/>
          <q-icon v-else name="fa fa-exclamation-triangle" color="warning" size="1em"/>
          IDENTITY IN LOCAL ONLY MODE
        </q-banner>
        <div class="display-inline q-mt-md">
          <q-checkbox v-model="allowSyncNotification"
            @input="assureNotifications"
            :disable="!idValid ||
              $asyncComputed.webpushValid.updating ||
              hasNotificationsDenied"
            class="q-ma-sm full-width"
            label="Sync Notifications"
          >
            <q-tooltip v-if="hasNotificationsDenied">
              Browser Notifications are Disabled.
            </q-tooltip>
            <q-tooltip v-else-if="hasNotificationsGranted">
              Browser Notifications are Enabled.
            </q-tooltip>
            <q-tooltip v-else>
              Browser Notifications have not been Enabled.
            </q-tooltip>
          </q-checkbox>
        </div>
        <div>
          <q-btn @click="webPushNotificationSync"
            :color="allowSyncNotification ? 'positive' : 'grey'"
            label="Request Identity-Device Sync Notifications"
            :disable="!allowSyncNotification || !idValid"
            class="q-ma-sm full-width"
            icon="fa fa-bell"
            push
          />
          <record-export-identity :record="record"/>
          <q-btn @click="$IdentityWorker.deleteLocalUser(record, identityNoPromptDelete).then(() => $emit('deleted'))"
            icon="fa fa-trash"
            label="DELETE LOCAL IDENTITY"
            class="text-black q-ma-sm full-width"
            color="negative"
            size="sm"
            dense
            push
          />
          <q-checkbox v-model="identityNoPromptDelete"
            label="Do Not Prompt for Delete"
            color="negative"
          />
        </div>
      </div>
    </q-dialog>
  </q-item>
</template>

<script>
import { debounce } from 'quasar'
import recordExportIdentity from './recordExportIdentity'
import { get, sync } from 'vuex-pathify'
export default {
  name: 'IdentityRecordDetail',
  components: {
    recordExportIdentity
  },
  props: {
    record: Object,
    dense: Boolean,
    isActive: Boolean,
    idUid: String,
    idHash: String,
    jwtToken: String
  },
  watch: {
    allowSyncNotification (val, oldVal) {
      if (val !== undefined && val !== null) {
        this.$IdentityWorker
          .setSyncNotificationPermissions(this.idHash, val)
      }
    }
  },
  data () {
    return {
      allowSyncNotification: null,
      visible: false
    }
  },
  mounted () {
    let vm = this
    vm.$IdentityWorker
      .getSyncNotificationProperties(this.idHash).then((val) => {
        vm.allowSyncNotification = val.allowSyncNotification
      })
    vm.assureSubscription = debounce(vm.$IdentityWorker.assureSubscription, 1000, true)
    vm.deniedNotificationsPrompt = debounce(vm.$IdentityWorker.deniedNotificationsPrompt, 1000, true)
    vm.allowNotificationsPrompt = debounce(vm.$IdentityWorker.allowNotificationsPrompt, 1000, true)
    if (vm.hasNotificationsDenied && vm.allowSyncNotification) {
      vm.allowSyncNotification = false
      vm.deniedNotificationsPrompt
        .call(vm.$IdentityWorker)
    }
  },
  methods: {
    expand (e) {
      console.log(e)
    },
    async assureNotifications (allowSyncNotification) {
      let vm = this
      if (!allowSyncNotification) return
      let {
        idHash,
        jwtToken,
        webpushValid,
        isSubscriptionValid,
        hasNotificationsDenied,
        hasNotificationsDefault,
        collectSubscriptionNotification
      } = vm
      if (vm.$asyncComputed.webpushValid.updating) return
      if (hasNotificationsDenied) {
        vm.allowSyncNotification = false
        return vm.deniedNotificationsPrompt
          .call(vm.$IdentityWorker)
      }
      if (hasNotificationsDefault) {
        await vm.allowNotificationsPrompt
          .call(vm.$IdentityWorker, { jwtToken, idHash }, {
            webpushValid
          })
        return vm.$asyncComputed.webpushValid.update()
      }
      let valid = this.isSubscriptionValid()
      if (!valid) { // no subscription
        await vm.assureSubscription
          .call(vm.$IdentityWorker, { jwtToken, idHash, webpushValid })
        return vm.$asyncComputed.webpushValid.update()
      }
    },
    async webPushNotificationSync () {
      let {
        hasNotificationsDenied,
        idHash,
        jwtToken
      } = this
      let {
        deviceHash,
        name: deviceName
      } = this.deviceId
      let result = await this.$IdentityWorker.webPushNotificationSync({
        idHash,
        jwtToken,
        idRoute: `/sync/id/${compressHexString(idHash)}`,
        deviceName,
        deviceHash
      })
    },
    isSubscriptionValid () {
      if (!(this.webpushValid && this.collectSubscriptionNotification)) return null
      if (!this.collectSubscriptionNotification.subscription) return null
      let {
        subscription: remote
      } = this.webpushValid
      let {
        subscription: local
      } = this.collectSubscriptionNotification
      local = objectSubscription(local)
      return remote.endpoint === local.endpoint &&
        remote.keys.p256dh === local.keys.p256dh &&
        remote.keys.auth === local.keys.auth
    },
    assureSubscription () {},
    allowNotificationsPrompt () {},
    deniedNotificationsPrompt () {}
  },
  computed: {
    deviceId: get('layout/deviceId'),
    identityNoPromptDelete: sync('layout/identity@noPromptDelete'),
    notificationsProperties: sync('identity/notifications@properties'),
    hasNotificationsDefault () {
      return this.$IdentityWorker.hasNotificationsDefault()
    },
    hasNotificationsDenied () {
      return this.$IdentityWorker.hasNotificationsDenied()
    },
    hasNotificationsGranted () {
      return this.$IdentityWorker.hasNotificationsGranted()
    }
  },
  asyncComputed: {
    collectSubscriptionNotification () {
      return this.isActive && collectSubscriptionNotification()
    },
    async webpushValid () {
      let vm = this
      // TODO: Only verify's JWT Token validity
      return (vm.isActive && vm.idHash && vm.jwtToken)
        ? new Promise(async (resolve, reject) => {
          let {
            idHash,
            deviceId: { deviceHash },
            jwtToken
          } = vm
          if (!(jwtToken && idHash)) return resolve(false)
          let result = await vm.$IdentityWorker
            .webPushByIdHashAndDeviceHash({
              idHash,
              deviceHash,
              jwtToken
            })
          if (result) resolve(result.data)
          else resolve(false)
        })
        : false
    },
    async idValid () {
      let vm = this
      return (vm.isActive && vm.idHash && vm.jwtToken)
        ? new Promise(async (resolve, reject) => {
          let {
            idHash,
            deviceId: { name: deviceName },
            jwtToken
          } = vm
          if (!(jwtToken && idHash)) return resolve(false)
          let result = await vm.$IdentityWorker.verifyIdHash({
            idHash,
            deviceName,
            jwtToken
          })
          if (result) resolve(result.data)
          else resolve(false)
        })
        : false
    }
  }
}
</script>

<style lang="stylus">
.q-item__section--avatar
  min-width 24px
  padding-right 6px
</style>
