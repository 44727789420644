/* eslint-disable */
import UtilWorkerInterface from './UtilWorkerInterface'
import {
  timehashApi
} from '../timehash'
import LRU from 'lru-cache'
export const defaultOpts = {
  vm: null,
  store: null,
  schemaSpec: null,
  schemaName: null
}

export class DexieWorkerInterface extends UtilWorkerInterface {
  constructor (Worker, opts = defaultOpts) {
    let runtimeOpts = Object.assign({}, defaultOpts, opts)
    super(Worker, runtimeOpts.vm, opts)
    let self = this
    self.opts = runtimeOpts
    self.ready = false
    self.boot()
  }

  async boot () {
    let self = this
    return new Promise(async (resolve, reject) => {
      let { result } = await self.setSchemaSpec(self.opts.schemaSpec)
      self.events.$emit('ready', result)
      self.ready = true
      resolve()
    })
  }

  async isReady () {
    let self = this
    return new Promise((resolve, reject) => {
      if (self.ready) resolve()
      else {
        self.events.$once('ready', resolve)
      }
    })
  }

  verifyReady (fn) {
    let self = this
    return new Promise(async (resolve, reject) => {
      afterEvent(
        () => self.ready,
        () => self.events.emit('waiting'),
        async () => {
          let result = await fn
          resolve(result)
        }
      )
    })
  }

  setSchemaSpec (schemaSpec, opts = {
    schemaName: null,
    dbName: null,
  }) {
    let message = {
      handler: 'addSchemas',
      db: opts.dbName || this.dbName,
      schema: schemaSpec
    }
    return this.messageAll(message)
  }

  toggleListener (settings, opts = {
    schemaName: null,
    dbName: null,
  }) {
    let message = {
      // uid, subscribe
      ...settings,
      handler: 'obsData',
      db: opts.dbName || this.dbName,
      schema: opts.schemaName || this.schemaName,
    }
    return this.message(message)
  }

  countData (opts = {
    query: null,
    dbName: null,
    schemaName: null
  }) {
    let message = {
      handler: 'sumData',
      query: opts.query,
      db: opts.dbName || this.dbName,
      schema: opts.schemaName || this.schemaName,
    }
    return this.message(message)
  }

  latestData (keyName, opts = {
    dbName: null,
    schemaName: null
  }) {
    return this.getData(null, {
      first: true,
      orderBy: keyName,
      reverse: true
    })
  }
  digData ({
    dig,
    digType,
    digLogic,
    digSelect,
    key,
    values,
    orderBy,
    reverse,
  }, opts = {
    schemaName: null,
    dbName: null
  }) {
    let self = this
    let message = {
      handler: 'digData',
      db: opts.dbName || self.dbName,
      schema: opts.schemaName || self.schemaName,
      dig, // object { key, string/object/regex }
      digType, // if matching strings, default type
      digLogic, // array function 'some'
      digSelect, // prop selector
      key, // limits dig to key/values
      values, // limits dig to key/values
      orderBy,
      reverse,
    }
    return self.message(message)
  }
  fixData (data, opts = {
    schemaName: null,
    dbName: null
  }) {
    let self = this
    let cease = self.timehash.timestamp()
    if (!isArray(data)) data = [data]
    for (const { key, changes } of data) {
      if (!changes.cease) changes.cease = cease
    }
    let message = {
      handler: 'fixData',
      db: opts.dbName || self.dbName,
      schema: opts.schemaName || self.schemaName,
      changes: data  // [{ key, changes }]
    }
    return self.message(message)
  }

  nixData (query, opts = {
    key: null,
    schemaName: null,
    dbName: null
  }) {
    let self = this
    let message = {
      handler: 'nixData',
      db: opts.dbName || self.dbName,
      schema: opts.schemaName || self.schemaName,
      key: opts.key || self.primaryKey,
      ...query // values
    }
    return self.message(message)
  }

  setData (data, opts = {
    schemaName: null,
    dbName: null
  }) {
    let self = this
    if (!data.cease) {
      data.cease = self.timehash.timestamp()
    }
    let message = {
      handler: 'setData',
      db: opts.dbName || self.dbName,
      schema: opts.schemaName || self.schemaName,
      data
    }
    return self.message(message)
  }
  getDataByKey(query, opts = {
    fields: null,
    schemaName: null,
    dbName: null
  }) {
    let self = this
    let message = {
      handler: 'getData',
      ...opts,
      db: opts.dbName || self.dbName,
      schema: opts.schemaName || self.schemaName,
      key: Object
        .entries(query)
        .reduce((acc, [ key, value ]) => {
          if (query[key]) acc[key] = query[key]
          return acc
        }, {})
    }
    // console.log('getDataByKey', message)
    return self.message(message)
  }
  getData(query, opts = {
    key: null,
    first: null, // first record in collection
    orderBy: null,
    reverse: null,
    limit: null,
    offset: null,
    schemaName: null,
    dbName: null
  }) {
    let self = this
    let message = {
      handler: 'getData',
      ...opts,
      db: opts.dbName || self.dbName,
      key: opts.key || self.primaryKey,
      schema: opts.schemaName || self.schemaName,
      ...query
    }
    log: message
    return self.message(message)
  }
  getKeys ({
    query,
    limit,
    offset,
    orderBy,
    reverse,
    schemaName,
    dbName,
  }) {
    let self = this
    let message = {
      handler: 'getKeys',
      db: dbName || self.dbName,
      schema: schemaName || self.schemaName,
      query,
      limit,
      offset,
      orderBy,
      reverse
    }
    return this.message(message)
  }
  api () {
    let self = this
    return {
      fetchBuffer: self.fetchBuffer,
      fetchBlob: self.fetchBlob,
      getBuffer: self.getBuffer
    }
  }
  keyStore () {
    let self = this
    let cache = new LRU({
      max: 5000,
      maxAge: 1000 * 60, // 1 minute
      updateAgeOnGet: true // reup max age
    })
    return {
      readLock: self.readLock.bind(self),
      writeLock: self.readLock.bind(self),
      getChunks: async (buffer, chunkSize = 10) => {
        let { result } = await self.getBufferChunks({ buffer, chunkSize })
        return result
      },
      getKey: async (buffer) => {
        let key = await self.getKey(buffer)
        return result
      },
      clear: () => {
        log: 'clear', self.vm
      },
      getItem: async (key, fn) => {
        if (!key) return
        let release = await self.readLock(key)
        if (cache.has(key)) {
          release()
          return cache.get(key)
        }
        let { result: item } = await self.getDataByKey({
          [self.primaryKey]: key
        })
        if (fn) fn(item)
        cache.set(key, item)
        release()
        return item
      },
      fixItem: async (key, changes, fn) => {
        cache.del(key)
        let release = await self.writeLock(key)
        let result = await self.fixData({ key, changes })
        if (fn) fn({ key, changes })
        release()
        return result
      },
      setItem: async (data, fn) => {
        let release = await self.writeLock(data[self.primaryKey])
        self.setData(data)
        cache.set(data[self.primaryKey], data)
        if (fn) fn(data)
        release()
      },
      deleteItem: (key) => {
        return new Promise(async (resolve, reject) => {
          let result = await self.nixData({
            query: {
              key: self.primaryKey,
              values: [key]
            }
          })
          resolve(result)
        })
      }
    }
  }

  get timehash () {
    return timehashApi
  }
  get dbName () {
    return this.opts.store
      .state[this.storeKey]
      .dexieOffline
      .db
  }
  get deviceId () {
    return this.store.get('layout/deviceId')
  }
  get deviceIdName () {
    return this.deviceId.name
  }
  get deviceHash () {
    return this.deviceId.deviceHash
  }
  get vm () {
    return this.opts.vm
  }
  get storeKey () {
    return this.opts.storeKey
  }
  get primaryKey () {
    return this.opts.primaryKey
  }
  get schemaName () {
    return this.store
      .state[this.storeKey]
      .dexieOffline
      .schema
  }
  onerror (event) {
    if (this.events) {
      this.events.$emit('onerror', event)
    }
  }
  onmessage (event) {
    // console.log(event)
    if (this.events) {
      if (event.uid) {
        this.events.$emit(`${event.uid}-${event.handler}`, event)
      } else if (event.handler) {
        this.events.$emit(event.handler, event)
      } else {
        this.events.$emit('onmessage', event)
      }
    }
  }
}
