import LRU from 'lru-cache'
const blobUrls = new LRU({
  max: 5000,
  dispose: function (key, n) { URL.revokeObjectURL(n) },
  maxAge: 1000 * 60 * 60, // 1 hour
  updateAgeOnGet: true // reup max age
})
export const cacheBlobUrls = {
  get: key => blobUrls.get(key),
  set: (key, blob) => {
    let url
    url = blobUrls.get(key)
    if (url) return url
    url = URL.createObjectURL(blob)
    blobUrls.set(key, url)
    return url
  }
}
import { utils } from './utils'

export default ({ Vue }) => {
  // something to do
  Vue.prototype.$cache = cacheBlobUrls
  utils.fireLoadingEvent(document)
}
