import { exportFile } from 'quasar'
import {
  WORKER_NAME,
  SCHEMA_NAME
} from '../constants'
import {
  modeler
} from '../../../models'
export function newDocr (context) {
  return modeler.new(SCHEMA_NAME)
}
export function countDocr (context) {
  let worker = this._vm[WORKER_NAME]
  return new Promise(async (resolve, reject) => {
    let { result: count } = await worker.countData()
    this.set(`${SCHEMA_NAME}/results@count`, count)
    resolve(count)
  })
}
export function toggleListener (_, settings) {
  // function assumes no record exists
  let worker = this._vm[WORKER_NAME]
  return worker.toggleListener(settings)
}
export async function importDocr (_) {
  let worker = this._vm[WORKER_NAME]
  let files = await clickGetFilesFromBrowser({ multiple: true, accept: 'application/zip' })
  if (!files) return
  let results = []
  for (const f of files) {
    let { result } = await worker.importZip(f)
    results.push(result)
  }
  return results
}
export function exportDocr (_, docId) {
  let worker = this._vm[WORKER_NAME]
  worker.exportZip({ uid: docId }).then(({ result }) => {
    let {
      file
    } = result
    exportFile(file.name, file, file.type)
  })
}
export function fixDocr (_, records) {
  // function assumes no record exists
  let worker = this._vm[WORKER_NAME]
  return worker.fixData(records) // [{ key, changes }]
}
export function setDocr (_, record) {
  // function assumes no record exists
  let worker = this._vm[WORKER_NAME]
  return worker.setData(record)
}
export function getDocr (context, { docId, origin }) {
  let worker = this._vm[WORKER_NAME]
  return new Promise(async (resolve, reject) => {
    await worker.fixData([{
      key: docId,
      changes: {
        update: null,
        syncDiff: null,
        syncVector: null
      }
    }])
    let { result } = await worker.getDataByKey({ uid: docId })

    if (result && result.content) {
      this.set(`${SCHEMA_NAME}/results@latest`, result)
      return resolve(result)
    }

    let record = await newDocr(context)
    let {
      result: [uid]
    } = await this.dispatch(`${SCHEMA_NAME}/setDocr`, record)
    let attrs = { ...record.attrs, docId: uid }
    await this.dispatch(`${SCHEMA_NAME}/fixDocr`, {
      key: uid,
      changes: {
        attrs,
        origin
      }
    })
    let newRecord = {
      ...record,
      uid,
      attrs
    }
    this.set(`${SCHEMA_NAME}/results@latest`, newRecord)
    return resolve(newRecord)
  })
}
export function latestDocr (context, { origin }) {
  let worker = this._vm[WORKER_NAME]
  return new Promise(async (resolve, reject) => {
    let { result } = await worker.latestData('cease')
    if (result && result.content) {
      this.set(`${SCHEMA_NAME}/results@latest`, result)
      return resolve(result)
    }
    let record = await newDocr(context)
    if (result && !result.content) {
      await worker.fixData({
        key: result.uid,
        changes: {
          title: record.title,
          content: record.content
        }
      })
      result.title = record.title
      result.content = record.content
      this.set(`${SCHEMA_NAME}/results@latest`, result)
      return resolve(result)
    }
    if (!result) {
      let {
        result: [uid]
      } = await this.dispatch(`${SCHEMA_NAME}/setDocr`, record)
      let attrs = { ...record.attrs, docId: uid }
      await this.dispatch(`${SCHEMA_NAME}/fixDocr`, {
        key: uid,
        changes: {
          attrs,
          origin
        }
      })
      let newRecord = {
        ...record,
        uid,
        attrs
      }
      this.set(`${SCHEMA_NAME}/results@latest`, newRecord)
      resolve(newRecord)
    }
  })
}
