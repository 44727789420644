import { licenseItems } from '../../models/enums'
export default {
  icon: 'fab fa-creative-commons',
  label (key, value) {
    return licenseItems[value]
  },
  component: 'select-license-type',
  props (key, value) {
    return {
      licenseType: value
    }
  }
}
