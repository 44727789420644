export default {
  MediaProperties: {
    fields: {
      alt: {
        type: 'string',
        id: 1
      },
      filename: {
        type: 'string',
        id: 2
      },
      'data-hid': {
        type: 'string',
        id: 3
      },
      'data-exist': {
        type: 'string',
        id: 4
      },
      'data-pivot': {
        type: 'string',
        id: 5
      },
      'data-begin': {
        type: 'string',
        id: 6
      },
      'data-geohash': {
        type: 'string',
        id: 7
      },
      'data-mimetype': {
        type: 'string',
        id: 8
      },
      'data-is-placeholder': {
        type: 'string',
        id: 9
      },
      'data-link': {
        type: 'string',
        id: 10
      }
    }
  },
  MediaRecord: {
    fields: {
      hid: {
        type: 'string',
        id: 1
      },
      geohash: {
        type: 'string',
        id: 2
      },
      filename: {
        type: 'string',
        id: 3
      },
      searchText: {
        type: 'string',
        id: 4
      },
      title: {
        type: 'string',
        id: 5
      },
      blob: {
        comment: 'isFile',
        type: 'bytes',
        id: 6
      },
      mimetype: {
        type: 'string',
        id: 7
      },
      properties: {
        type: 'MediaProperties',
        id: 8
      },
      tags: {
        type: 'string',
        repeated: true,
        id: 9
      },
      attribution: {
        type: 'string',
        id: 10
      },
      encrypt: {
        type: 'bool',
        id: 11
      },
      share: {
        type: 'Tags',
        id: 12
      },
      url: {
        type: 'string',
        id: 13
      },
      begin: {
        type: 'string',
        id: 14
      },
      pivot: {
        type: 'string',
        id: 15
      },
      cease: {
        type: 'string',
        id: 16
      },
      exist: {
        type: 'string',
        id: 17
      },
      lastModified: {
        type: 'fixed32',
        id: 18
      }
    }
  }
}
