<template>
  <div class="relative-position popup-display">
    <q-img :src="url" class="fit" contain/>
    <slot name="before"></slot>
  </div>
</template>
<script>
export default {
  name: 'MediaDisplay',
  props: {
    recordKey: String,
    disable: Boolean,
    url: String
  }
}
</script>
<style lang="stylus">
.popup-display
  background-color $primary-2

</style>
