export const geohash = /^([0123456789bcdefghjkmnpqrstuvwxyz]){1,12}$/
export const geohashTag = /(geohash|geohashes[[0-9]+\])$/i

export const timehash = /^([01abcdef]){1,10}$/i

export const latitudeTag = /(lat|latitude|coordinates\[1\])$/i
export const longitudeTag = /(long|lng|longitude|coordinates\[0\])$/i
export const latLngTag = /(latLng)$/i
export const lngLatTag = /(lngLat|coordinates)$/i

export const sha512 = /^([a-fA-F0-9]){128}$/
export const sha256 = /^([a-fA-F0-9]){64}$/
export const infohash = /^([a-fA-F0-9]){40}$/
export const email = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+[.][A-Za-z]+$/
