<template>
  <div>
    <q-item-label>
    <template v-if="$checks.geohash(geohash)">
      {{geohash}}
      <q-item-label caption>
        Lat/X:&nbsp;{{latLng.lat}}
      </q-item-label>
      <q-item-label caption>
        Lng/Y:&nbsp;{{latLng.lng}}
      </q-item-label>
    </template>
    <template v-else>
      Location Not Found
    </template>
    </q-item-label>
    <q-item-label caption>
      {{recordKey}}
    </q-item-label>
  </div>
</template>
<script>
import { format } from 'date-fns'
export default {
  name: 'GeohashDisplay',
  props: {
    recordKey: String,
    disable: Boolean,
    geohash: String
  },
  computed: {
    latLng () {
      return this.geohash
        ? this.$geohash.toLatLng(this.geohash)
        : null
    }
  }
}
</script>
