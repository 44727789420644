const timestampEnum = [
  'exist',
  'begin',
  'pivot',
  'cease'
]

export default {
  timestampEnum
}
