<template>
  <svg
    :width="`${pageRect.width}px`"
    :height="`${pageRect.height}px`"
    style="pointer-events: none; fill: none; opacity:1;"
    class="absolute-center container-rect"
  >
    <rect x="0" y="0" :class="pageRectClass" :width="pageRect.width" :height="pageRect.height"></rect>
    <rect
      v-for="(item, index) in componentRects"
      :key="`component-${index}`"
      :x="positionOnPageX(item)"
      :y="positionOnPageY(item)"
      :class="componentRectClass"
      :width="item.width"
      :height="item.height"
    ></rect>
    <g>
      <circle
        v-if="dataCenterPoint && viewDataCenterPointVisible"
        class="view-data"
        :cx="dataCenterPoint.x + 14"
        :cy="dataCenterPoint.y + 5"
        r="10"
        stroke="black"
        stroke-width="2"
        fill="#ff00ff33"
      ></circle>
    </g>
  </svg>
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'pageOverlay',
  props: {
    map: {
      type: Object,
      default: null
    },
    isViewVisible: {
      type: Boolean,
      default: true
    },
    viewData: {
      type: Object,
      default () {
        return null
      }
    },
    componentRects: {
      type: Array,
      default: () => []
    },
    pageRect: {
      type: Object,
      default () {
        return {
          width: 0,
          height: 0,
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      }
    }
  },
  data () {
    return {
      dataCenterPoint: null,
      mapBounds: null
    }
  },
  mounted () {
    if (this.map) {
      this.map.off('move', this.handleMapMove)
      this.map.on('move', this.handleMapMove)
      this.map.off('zoom', this.handleMapZoom)
      this.map.on('zoom', this.handleMapZoom)
    }
    this.$emit('show')
  },
  watch: {
    viewElement (newVal, oldVal) {
      log: newVal, oldVal
    },
    map (newVal, oldVal) {
      log: 'pageOverlay', newVal, oldVal
      if (oldVal) {
        this.mapBounds = null
        oldVal.off('move', this.handleMapMove)
        oldVal.off('zoom', this.handleMapZoom)
      }
      if (newVal) {
        this.mapBounds = this.map.getBounds()
        newVal.on('move', this.handleMapMove)
        newVal.on('zoom', this.handleMapZoom)
      }
    },
    viewDataLocalCenter (newVal, oldVal) {
      if (newVal && this.map) {
        this.dataCenterPoint = this.map.latLngToContainerPoint(newVal)
        this.mapBounds = this.map.getBounds()
      } else {
        this.dataCenterPoint = null
        this.mapBounds = null
      }
    }
  },
  methods: {
    positionOnPageX (rect) {
      return rect.left - this.pageRect.left
    },
    positionOnPageY (rect) {
      return rect.top - this.pageRect.top
    },
    handleMapZoom (e) {
      log: 'zoom', e, this.viewDataLocal
      if (this.viewDataLocalCenter) {
        this.dataCenterPoint = this.map.latLngToContainerPoint(
          this.viewDataLocalCenter
        )
        this.mapBounds = this.map.getBounds()
      }
    },
    handleMapMove (e) {
      log: 'move', e, this.viewDataLocal
      if (this.viewDataLocalCenter) {
        this.dataCenterPoint = this.map.latLngToContainerPoint(
          this.viewDataLocalCenter
        )
        this.mapBounds = this.map.getBounds()
      }
    }
  },
  computed: {
    viewDataCenterPointVisible () {
      return this.mapBounds
        ? this.mapBounds.contains(this.viewDataLocalCenter)
        : false
    },
    viewDataLocalCenter () {
      return this.viewDataLocal && this.viewDataLocal.center
        ? this.viewDataLocal.center
        : null
    },
    viewDataLocal () {
      return this.viewData && this.viewData.viewData
        ? this.viewData.viewData
        : null
    },
    viewDataComponentName () {
      return this.viewData && this.viewData.componentName
        ? this.viewData.componentName
        : null
    },
    viewDataLocalGeojson: {
      get () {
        let { geojson } = this.viewDataLocal
        if (geojson) {
          return geojson
        } else {
          return null
        }
      }
    },
    childRectClass () {
      return {
        'child-rect': true
      }
    },
    componentRectClass () {
      return {
        'component-rect': true
      }
    },
    pageRectClass () {
      return {
        'page-rect': true
      }
    },
    viewDataComponentRectClass () {
      return {
        'highlight-rect': true
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.child-rect {
  fill: $negative;
  stroke: red;
  stroke-width: 4px;
  opacity: 0.3;
}

.component-rect {
  fill: $positive;
  border-style: solid;
  opacity: 0.3;
}

.page-rect {
  fill: $neutral;
  opacity: 0.3;
}

.container-rect {
  z-index: $top-map !important;
}

.view-data {
  z-index: $top-fullscreen;
}

.highlight-rect {
  border-style: solid;
  border-width: 1px;
}
</style>
