export default {
  TileCoord: {
    fields: {
      x: {
        type: 'fixed32',
        id: 1
      },
      y: {
        type: 'fixed32',
        id: 2
      },
      z: {
        type: 'fixed32',
        id: 3
      }
    }
  },
  TileLayersRecord: {
    fields: {
      key: {
        type: 'string',
        id: 1
      },
      url: {
        type: 'string',
        id: 2
      },
      blob: {
        type: 'bytes',
        id: 3
      },
      coord: {
        type: 'TileCoord',
        id: 4
      }
    }
  }
}
