import glyphsInstall from './glyphs'
import pageOverlay from './pageOverlay'
import mediaDisplay from './media-display'
import geohashDisplay from './geohash-display'
import timehashDisplay from './timehash-display'
import selectLicenseType from './select-license-type'
import closeDialogButton from './close-dialog-button'
import { utils } from '../utils'

export default ({ Vue }) => {
  glyphsInstall({ Vue })
  Vue.component('page-overlay', pageOverlay)
  Vue.component('select-license-type', selectLicenseType)
  Vue.component('close-dialog-button', closeDialogButton)
  Vue.component('timehash-display', timehashDisplay)
  Vue.component('geohash-display', geohashDisplay)
  Vue.component('media-display', mediaDisplay)
  utils.fireLoadingEvent(document)
}
