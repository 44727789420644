import {
  hexStringSHA512
} from '../crypto/transforms'
import { uid } from 'quasar'

// HASH ID (DUPLICATE DATA IDENTIFIER)
export const keyHid = (data) => {
  return hexStringSHA512(JSON.stringify(data.docr), 1)
}
// UNIQUE ID (REPLACEMENT DATA IDENTIFIER)
export const keyUid = (data) => {
  return uid()
}
