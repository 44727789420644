import state from './state'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

import { make } from 'vuex-pathify'

export default {
  namespaced: true,
  state,
  getters: Object.assign(make.getters(state), getters),
  mutations: Object.assign(make.mutations(state), mutations),
  actions
}
