import {
  IdentityWorkerHandlersInterface
} from './IdentityWorkerHandlersInterface'
import {
  Dialog
} from 'quasar'
const allowNotificationsOpts = {
  webpushValid: null,
  title: `Allow Notification Permissions`
}
export class IdentityWorkerNotificationsInterface extends IdentityWorkerHandlersInterface {
  async notificationsKey () {
    const response = await fetch(`${process.env.SIGNALING_URL}/config/webpush.json`)
    const { publicKey } = await response.json()
    const convertedVapidKey = urlBase64ToUint8(publicKey)
    return convertedVapidKey
  }
  deniedNotificationsPrompt () {
    return Dialog.create({
      title: 'Notification Permissions Denied',
      color: 'negative',
      message: `Your Browser Permission Doesnt Allow Notifications`,
      persistent: true,
      ok: true
    })
  }

  async assureSubscription ({
    webpushValid,
    idHash,
    jwtToken
  }) {
    let self = this
    let result
    let {
      registration,
      subscription
    } = await collectSubscriptionNotification()
    if (!subscription) {
      let vapidKey = await self.notificationsKey()
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: vapidKey
      })
    }
    if (webpushValid) {
      result = await self.updateWebPush({
        jwtToken,
        nodeId: webpushValid.nodeId,
        subscription: objectSubscription(subscription)
      })
    } else {
      result = await self.createWebPush({
        jwtToken,
        idHash,
        deviceHash: self.deviceHash,
        subscription: objectSubscription(subscription)
      })
    }
    return result || subscription
  }
  allowNotificationsPrompt ({
    idHash,
    jwtToken
  }, opts = allowNotificationsOpts) {
    let self = this
    opts = Object.assign({}, allowNotificationsOpts, opts)
    console.log('allowNotificationsPrompt', self)
    return new Promise(async (resolve, reject) => {
      Dialog.create({
        message: `This Feature Requires BROWSER PERMISSION!
        Click [OK] to Proceed`,
        ok: true,
        persistent: true,
        cancel: true,
        ...opts
      }).onOk(async () => {
        let permission = await askPermissionNotifications()
        if (self.notificationsGranted(permission)) {
          let {
            webpushValid
          } = opts
          let result = await self.assureSubscription({ jwtToken, idHash, webpushValid })
          if (result) {
            resolve(result)
          } else { reject() }
        } else if (self.notificationsDenied(permission)) {
          Dialog.create({
            message: `You Denied Browser Permissions for this Website
              You will need to reset permissions if you wish to use this feature in the future
            `,
            ok: true,
            ...opts
          })
          resolve()
        } else {
          console.warn('allowNotificationsPrompt', permission)
          resolve()
        }
      }).onCancel(() => {
        Dialog.create({
          message: `You Canceled Browser Permission Requests`,
          ok: true,
          ...opts
        })
        resolve()
      })
    })
  }
  hasNotificationsDefault () {
    return this.notificationsDefault(Notification.permission)
  }
  hasNotificationsDenied () {
    return this.notificationsDenied(Notification.permission)
  }
  hasNotificationsGranted () {
    return this.notificationsGranted(Notification.permission)
  }
  notificationsDefault (permission) {
    return permission
      ? permission === NOTIFICATION_PERMISSION_DEFAULT
      : checkPermissionNotifications() === NOTIFICATION_PERMISSION_DEFAULT
  }
  notificationsDenied (permission) {
    return permission
      ? permission === NOTIFICATION_PERMISSION_DENIED
      : checkPermissionNotifications() === NOTIFICATION_PERMISSION_DENIED
  }
  notificationsGranted (permission) {
    return permission
      ? permission === NOTIFICATION_PERMISSION_GRANTED
      : checkPermissionNotifications() === NOTIFICATION_PERMISSION_GRANTED
  }
}
