const toLine = (line) => {
  let {
    timestamp,
    metadata,
    ...d
  } = line
  return {
    ...d,
    ...metadata,
    ...timestamp
  }
}

export default toLine
