import { SHA256, SHA512, cryptoLib } from 'crypto-js'

const char64id = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

const make64id = () => {
  let text = ''
  for (var i = 0; i < 64; i++) {
    text += char64id.charAt(Math.floor(Math.random() * char64id.length))
  }
  return text
}
const make128id = () => {
  let text = ''
  for (var i = 0; i < 128; i++) {
    text += char64id.charAt(Math.floor(Math.random() * char64id.length))
  }
  return text
}
export const hexBinarySHA512 = (buffer, turns = 10) => {
  let wordArray = cryptoLib.WordArray.create(buffer)
  let sha512 = SHA512(wordArray).toString()
  return sha512
}

export const hexStringSHA512 = (string, turns = 10) => {
  let wordArray = SHA512(string || make128id())
  if (turns !== undefined) {
    for (let i = 1; i < turns; i++) {
      wordArray = SHA512(wordArray.toString())
    }
  } else {
    wordArray = SHA512(wordArray.toString())
  }
  return wordArray.toString()
}

export const hexBinarySHA256 = (buffer, turns = 10) => {
  let wordArray = cryptoLib.WordArray.create(buffer)
  let sha256 = SHA256(wordArray).toString()
  return sha256
}

export const hexStringSHA256 = (string, turns = 10) => {
  let wordArray = SHA256(string || make64id())
  if (turns !== undefined) {
    for (let i = 1; i < turns; i++) {
      wordArray = SHA256(wordArray.toString())
    }
  } else {
    wordArray = SHA256(wordArray.toString())
  }
  return wordArray.toString()
}
