<template>
  <l-layer-group
    :bubblingMouseEvents="true"
    layer-type="overlay"
    name="geohashBounds"
  >
    <l-rectangle
      :options="{
        bubblingMouseEvents: true
      }"
      className="non-selectable"
      @add="onAdd"
      ref="rectangle"
      :interactive="interactive"
      :weight="1"
      :stroke="stroke"
      :color="fillColor"
      :fillColor="color"
      :bounds="bounds"
    >
    </l-rectangle>
    <template v-for="([key, val], index) in Object.entries(locations)">
      <l-marker @click="e => clickThisLocation(e, key, val)"
        :lat-lng="val"
        :key="`spots-${key}-${index}`">
        <l-icon
          :ref="key"
          :iconSize="[16,16]"
          :iconAnchor="[0,0]"
        >
          <slot :name="key"
            :geohashPrecise="geohashPrecise"
            :latLng="val"
          >
            <div/>
          </slot>
        </l-icon>
      </l-marker>
    </template>
    <defaultIcon
      :latLng="locations['top-left']"
    />
    <defaultIcon
      :latLng="geohashLatLng"
    />
  </l-layer-group>
</template>

<script>
import {
  // searchIcon,
  defaultIcon
} from './icons'
export default {
  name: 'mapBoundsGeohashBase',
  components: {
    // searchIcon,
    defaultIcon
  },
  extends: defaultIcon,
  methods: {
    recurseNodes (element, fn) {
      for (let el of element.childNodes) {
        this.recurseNodes(el, fn)
        fn(el)
      }
    },
    recurseChildren (component, fn) {
      if (Array.isArray(component)) {
        component.map(child => this.recurseChildren(child, fn))
      }
      if (
        component &&
        component.$children &&
        component.$children.length > 0
      ) {
        component.$children.map(child => this.recurseChildren(child, fn))
      }
      fn(component)
    },
    clickThisLocation (e, key, val) {
      let parentNode = this.$refs[key].$el
      let childNode = e.originalEvent.target
      this.recurseChildren(this.$refs[key], (component) => {
        if (component.click) {
          this.recurseNodes(component.$el, (el) => {
            if (el.isEqualNode(childNode)) {
              component.click(e.originalEvent)
            }
          })
        }
      })
    }
  }
}
</script>

<style>
</style>
