import Vue from 'vue'
import Vuex from 'vuex'

import pathify from './_pathify'

import layout from './layout'
import transferData from './transferData'

import VuexPersistence from 'vuex-persist'

// import example from './module-example'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */
const vuexLocal = new VuexPersistence({
  key: 'rootStore',
  storage: window.localStorage,
  modules: ['layout', 'route']
})

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    plugins: [
      pathify.plugin,
      vuexLocal.plugin
    ],
    modules: {
      transferData,
      layout
    }
  })

  return Store
}
