import * as L from 'leaflet'

import 'leaflet/dist/leaflet.css'
import 'leaflet-geoman/dist/leaflet-geoman.min'
import 'leaflet-geoman/dist/leaflet-geoman.css'

// L.PM.initialize({ optIn: true })

export {
  L
}
