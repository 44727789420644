import { WireConductor } from './lib/WireConductor'
import calcRoomId from './lib/calcRoomId'
import createClient from './lib/createClient'
import {
  DexieWorkerInterface
} from '../DexieWorkerInterface'
import VuexPersistence from 'vuex-persist'
const vuexWire = new VuexPersistence({
  key: 'WireWorkerInterface',
  storage: window.localStorage,
  filter: mutation => mutation.type.startsWith('wire/persist'),
  reducer: state => {
    return state.wire.persist
  }
})
export const defaultOpts = {
  vm: null,
  store: null,
  syncRate: 100,
  maxPeers: 8,
  minPeers: 4,
  chunkSize: 1024 * 16,
  sendChunkSize: 10,
  hashSize: 64,
  schemaSpec: null,
  schemaName: null
}
export class WireWorkerInterface extends DexieWorkerInterface {
  constructor (Worker, opts = defaultOpts) {
    let runtimeOpts = Object.assign({}, defaultOpts, opts)
    super(Worker, runtimeOpts)
    let self = this
    vuexWire.plugin(self.store)
    self.wire = new Y.Doc()
    self.conductors = {}
  }
  async beginRoom (idHash, deviceHash, properties) {
    let self = this
    let currentProperties = await self.store.dispatch('wire/setSyncProperties', properties)
    let roomId = calcRoomId(idHash)
    self.conductors[roomId] = new WireConductor(self.wire, roomId, deviceHash)
    let handlers = self.conductors[roomId].handlers()
    let room = await createClient(roomId, self.clientConfig, handlers)
    return {
      room,
      roomId,
      properties: currentProperties
    }
  }
  async ceaseRoom (idHash, deviceHash, properties) {
    let self = this
    let roomId = calcRoomId(idHash)
    let currentProperties = await self.store.dispatch('wire/setSyncProperties', properties)
    if (self.conductors[roomId]) {
      self.conductors[roomId].ceaseRoom()
      delete self.conductors[roomId]
    }
    return {
      roomId,
      properties: currentProperties
    }
  }
  get clientConfig () {
    return {
      url: process.env.SIGNALING_URL,
      dataOnly: true,
      enableDataChannels: true,
      media: {
        video: false,
        audio: false
      },
      constraints: {
        maxPeers: this.maxPeers,
        minPeers: this.minPeers
      },
      socketio: {
        path: '/socket.io',
        forceNew: true,
        transports: ['websocket'],
        secure: true,
        port: '443',
        hostname: 'io.geodocr.com'
      }
    }
  }
  get maxPeers () {
    return this.opts.maxPeers
  }
  get minPeers () {
    return this.opts.minPeers
  }
}
