import {
  WORKER_NAME,
  SCHEMA_NAME
} from '../constants'

export function countMedia (context) {
  let worker = this._vm[WORKER_NAME]
  return new Promise(async (resolve, reject) => {
    let { result: count } = await worker.countData()
    this.set(`${SCHEMA_NAME}/results@count`, count)
    resolve(count)
  })
}
export function toggleListener (_, settings) {
  // function assumes no record exists
  let worker = this._vm[WORKER_NAME]
  return worker.toggleListener(settings)
}
export function fixMedia (_, records) {
  // function assumes no record exists
  let worker = this._vm[WORKER_NAME]
  return worker.fixData(records) // [{ key, changes }]
}
export function setMedia (_, record) {
  // function assumes no record exists
  let worker = this._vm[WORKER_NAME]
  return worker.setData(record)
}
export function latestMedia (context) {
  let worker = this._vm[WORKER_NAME]
  return new Promise(async (resolve, reject) => {
    let { result } = await worker.latestData('cease')
    if (result) {
      this.set(`${SCHEMA_NAME}/results@latest`, result)
      return resolve(result)
    } else {
      reject()
    }
  })
}
