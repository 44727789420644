<template>
  <q-item>
    <q-item-section>
      <q-item-label>
        <q-icon name="fa fa-user"/>
        DEVICE
      </q-item-label>
      <q-item-label v-if="peer.peerId" caption lines="6">
        <span v-if="peer.announcement">
          {{peer.announcement.name}}
        </span>
        <q-tooltip>
          Peer Id: [{{peer.peerId}}]
        </q-tooltip>
      </q-item-label>
      <q-item-section>
        <q-expansion-item
          expand-separator
          label="Records"
          dense
        >
          <template v-slot:header v-if="dbDocOtherStat">
            <q-item-section avatar>
              <q-icon v-if="dbDocOtherStat.length > 0"
                name="fa fa-folder"
              />
              <q-spinner-grid v-else
                size="2em"
              />
            </q-item-section>
            <q-item-section>
              RECORDS
            </q-item-section>
            <q-item-section>
              <q-linear-progress v-for="item in progressBars"
                :key="`progress-${item.fileHid}-${item.progressSize}`"
                :value="item.progress"
              />
            </q-item-section>
          </template>
          <template v-if="dbDocOtherStat">
            <q-chip
              v-for="({ db, schema, length }) of dbDocOtherStat"
              :key="`${db}-${schema}-${length}`"
              color="primary"
              square
              dense
            >
              <q-avatar :icon="selectIcon({ db, schema })"
                :text-color="peerDbShares.indexOf(schema)  === -1 ? 'dark' : 'white'"
                :color="peerDbShares.indexOf(schema)  === -1 ? 'secondary' : 'positive'"
                class="q-mx-lg"
              />
              {{schema}}&nbsp;
              <transition
                appear
                enter-active-class="animated fadeIn"
                leave-active-class="animated fadeOut"
              >
                <q-badge color="neutral">
                  {{length}}
                </q-badge>
              </transition>
            </q-chip>
          </template>
        </q-expansion-item>
      </q-item-section>
    </q-item-section>
    <q-item-section side top>
      <q-item-label caption>{{peer.synced}}</q-item-label>
      <q-icon name="far fa-circle" v-if="!peer.local.vector"
        :color="peer.local.vector ? 'positive' : 'negative'"
        size="1em"
      />
      <q-icon name="fa fa-circle" v-if="peer.local.vector"
        :color="peer.remote.vector ? 'positive' : 'negative'"
        size="1em"
      />
      <q-icon name="far fa-circle" v-if="!peer.local.diff"
        :color="!peer.remote.vector ? 'positive' : 'negative'"
        class="q-pt-sm"
        size="1em"
      />
      <q-icon name="fa fa-circle" v-if="peer.local.diff"
        class="q-pt-sm"
        :color="peer.remote.diff ? 'positive' : 'negative'"
        size="1em"
      />
    </q-item-section>
    <q-item-section v-if="!peer.announcement" thumbnail>
      <q-spinner-grid size="2em" color="warning"/>
    </q-item-section>
  </q-item>
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'WirePeerItem',
  props: {
    rpid: String,
    peer: Object
  },
  data () {
    return {
      loading: true,
      transfers: {},
      progressBars: []
    }
  },
  mounted () {
    let vm = this
    vm.$WireWorker.$on('newRecord', ({ handler, db, schema, record }) => {
      // log: 'newRecord', { handler, db, schema, record }
    })
    vm.$WireWorker.$on('newPacket', (packet) => {
      // log: 'newPacket', packet
    })
    vm.$WireWorker.$on('newFile', ({ roomId, peerId, fileHid }) => {
      let rpfId = vm.$WireWorker.calcRpFileId({ roomId, peerId, fileHid })
      vm.transfers[fileHid] = vm.$watch(`progressFiles.${rpfId}`, function (newValue, oldValue) {
        if (!newValue) {
          vm.transfers[fileHid]()
          let transfers = { ...vm.transfers }
          delete transfers[fileHid]
          vm.transfers = transfers
          this.$nextTick(() => {
            this.progressBars = this.progressBars.filter(b => b.fileHid !== fileHid)
          })
          return
        }
        let {
          fileSize,
          progressSize
        } = newValue
        let bar = this.progressBars.find(b => b.fileHid === fileHid)
        if (!bar) {
          bar = {
            fileHid,
            fileSize,
            progressSize,
            progress: progressSize / fileSize
          }
          this.progressBars.push(bar)
        } else {
          bar.progress = progressSize / fileSize
        }
      })
    })
  },
  methods: {
    selectIcon ({ db, schema }) {
      return db === '__global'
        ? 'fa fa-globe'
        : 'fa fa-user-shield'
    }
  },
  computed: {
    progressFiles: get('wire/files'),
    peerId () {
      return this.peer && this.peerId
    },
    peerDbShares () {
      return this.peerAnnouncement && this.peerAnnouncement.syncDb
    },
    peerAnnouncement () {
      return this.peer.announcement
    },
    dbDocOthersStats: get('wire/dbDocStats'),
    dbDocOtherStat () {
      return this.dbDocOthersStats && this.dbDocOthersStats[this.rpid]
        ? this.dbDocOthersStats[this.rpid]
        : null
    }
  }
}
</script>
