import {
  sectionMenuItemsShort
} from '../boot/models/enums'
export const routeWindowedIdentity = (route) => {
  return {
    openBeforeSection: sectionMenuItemsShort[route.params.beforeSection],
    openAfterSection: sectionMenuItemsShort[route.params.afterSection],
    openIdHash: route.params.idHash
      ? decompressHexString(route.params.idHash)
      : null
  }
}
export const routeImageParams = (route) => {
  return {
    hid: route.params.hid
  }
}
export const routePrintParams = (route) => ({
  docId: route.params.docId
})
export const routeGeohashParams = (route) => ({
  geohash: route.params.geohash
})
export const routeAddressParams = (route) => {
  let { address } = route.params
  if (address) {
    let parser = /(,|-|\s|%20)+/i
    address = address
      .split(parser)
      .filter(m => !parser.test(m))
  } else {
    let {
      city,
      country,
      postalcode,
      state,
      street
    } = route.query
    address = {
      city,
      country,
      postalcode,
      state,
      street
    }
  }
  return {
    query: { ...route.query },
    address
  }
}
