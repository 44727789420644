function calcWidth (rects) {
  return rects.reduce((acc, r) => {
    acc += r.width
    return acc
  }, 0)
}
function calcHeight (rects) {
  return rects.reduce((acc, r) => {
    acc += r.height
    return acc
  }, 0)
}
export function vrRects (state) {
  return state.fixtures.vertical
    .map(e => getBoundingClientRect(e))
}
export function hrRects (state) {
  return state.fixtures.horizontal
    .map(e => getBoundingClientRect(e))
}
export function beforeHrRects (state) {
  return state.fixtures.before
    .map(e => getBoundingClientRect(e))
}
export function afterHrRects (state) {
  return state.fixtures.after
    .map(e => getBoundingClientRect(e))
}
export function afterVrWidth (state) {
  let after = vrRects(state)
  return calcWidth(after)
}
export function beforeVrWidth (state) {
  let before = vrRects(state)
  return calcWidth(before)
}
export function afterHrHeight (state) {
  let after = afterHrRects(state)
  return calcHeight(after)
}
export function beforeHrHeight (state) {
  let before = beforeHrRects(state)
  return calcHeight(before)
}
