export default {
  NamedCrs: {
    fields: {
      epsg: {
        type: 'string',
        required: true,
        id: 1
      },
      name: {
        type: 'string',
        required: true,
        id: 2
      },
      proj4: {
        type: 'string',
        required: true,
        id: 3
      },
      units: {
        type: 'string',
        required: true,
        id: 4
      },
      matrix: {
        type: 'NamedCrsMatrix',
        id: 5
      }
    }
  },
  NamedCrsMatrix: {
    fields: {
      name: {
        type: 'string',
        id: 1
      },
      translations: {
        type: 'MatrixTranslation',
        rule: 'repeated',
        id: 2
      }
    }
  },
  MatrixTranslation: {
    fields: {
      rotateDeg: {
        type: 'float',
        id: 1
      },
      translate: {
        type: 'float',
        rule: 'repeated',
        id: 2
      },
      transform: {
        type: 'float',
        rule: 'repeated',
        id: 3
      },
      skewDeg: {
        type: 'float',
        rule: 'repeated',
        id: 4
      },
      scale: {
        type: 'float',
        rule: 'repeated',
        id: 5
      }
    }
  }
}
