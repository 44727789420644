export const SCHEMA_NAME = 'identity'
export const STORE_KEY = 'dexieOffline'
export const WORKER_NAME = '$IdentityWorker'
// prosemirror spec [type, attrs, content]
export const SCHEMA_SPEC = '$$uid, &idHash, exist, userSecretKey, serverPublicKey, jwtToken, identity, syncNotifications'
export const HASH_COLUMNS = ['idHash']

export const COLUMNS = SCHEMA_SPEC.split(', ').reduce((acc, val) => {
  val = val.replace(/(\*|\+|&|\$)/ig, '')
  acc.push(val)
  return acc
}, [])

export const PRIMARY_KEY = COLUMNS[0]
