<template>
  <q-list separator>
    <template v-for="(item, index) in messages">
      <component v-if="components[item.type]"
        :key="`component-${item.timestamp}-${index}`"
        :is="components[item.type]"
        :item="item"
        :message-style="messageStyle(item.deviceHash)"
      />
    </template>
  </q-list>
</template>

<script>
import { debounce } from 'quasar'
import messagesMixin from './messagesMixin'
import userMessage from './userMessage'
import { get, sync } from 'vuex-pathify'
export default {
  name: 'WireMessagesLog',
  mixins: [
    messagesMixin
  ],
  components: {
    userMessage
  },
  props: {
    hasFocus: Boolean,
    idHash: String,
    maxHeight: {
      type: Number,
      default: () => 400
    }
  },
  watch: {
    timestamp (val) {
      this.refreshMessages()
    },
    hasFocus (val, oldVal) {
      if (val === 1 && this.hasFocus) {
        this.newRecordCount = 0
      }
    },
    page (val, oldVal) {
      if (val === 1 && this.hasFocus) {
        this.newRecordCount = 0
      }
    }
  },
  data () {
    return {
      components: {
        'wire/userMessage': userMessage
      }
    }
  },
  mounted () {
    if (this.newRecordHandler) {
      this.$WireWorker.$off('newRecord', this.newRecordHandler)
    }
    this.refreshMessages = debounce(this.refreshMessages, 1000)
    this.newRecordHandler = ({ handler, db, schema, record }) => {
      console.log(handler, this.idHash, db, schema, record)
      if (schema === 'wire' && record.idHash === this.idHash) {
        this.$asyncComputed.messages.update()
        if (this.page !== 1 || !this.hasFocus) {
          this.newRecordCount += 1
        }
      }
    }
    this.$WireWorker.$on('newRecord', this.newRecordHandler)
  },
  methods: {
    refreshMessages () {
      this.$asyncComputed.messages.update()
    },
    getPeerColor (key) {
      return `#${key.slice(0, 6)}`
    },
    getMessageColor (deviceHash) {
      return (this.idHash)
        ? this.idHashDeviceColor(deviceHash)
        : `#000000`
    },
    messageStyle (deviceHash) {
      return {
        'border-left-style': 'solid',
        'border-left-width': 'thick',
        'border-color': this.getMessageColor(deviceHash)
      }
    }
  },
  computed: {
    dbDoc: get('wire/dbDoc'),
    timestamp: get('wire/timestamp'),
    newRecordHandler: sync('wire/newRecordHandler'),
    deviceHash: get('layout/deviceId@deviceHash'),
    newRecordCount: sync('layout/wire@newRecordCount'),
    messagesSettings: get('layout/wire@messagesSettings'),
    idHashDeviceColor: get('identity/idHashDeviceColor'),
    roomId () {
      return (this.idHash)
        ? this.$WireWorker.calcRoomId(this.idHash)
        : null
    }
  },
  asyncComputed: {
    messages () {
      return (this.idHash && this.messagesSettings)
        ? this.$WireWorker.getData(
          { key: 'idHash', values: this.idHash },
          this.messagesSettings)
          .then(({ result }) => result)
        : null
    }
  }
}
</script>
