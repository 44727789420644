var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.geohashLatLng)?_c('l-marker',{ref:"marker",attrs:{"lat-lng":_vm.geohashLatLng}},[_c('l-icon',{key:_vm.defaultKey,ref:"icon",attrs:{"iconSize":_vm.iconSize,"iconAnchor":_vm.iconAnchor}},[_c('l-icon-wrapper',{attrs:{"component":"dummy"},on:{"ready":_vm.iconReady}},[_c('div',{staticClass:"icons-top-row row justify-center"},[_vm._l((_vm.topButtons),function(item,index){return [(item)?_c('q-btn',{key:("btn-" + item + "-" + index),attrs:{"icon":("img:icons/icon-" + item + ".svg"),"color":_vm.color,"size":"lg","dense":"","flat":""},on:{"click":function($event){return _vm.$emit('click', {
              name: _vm.$options.name,
              selected: item,
              transferDataValid: _vm.transferDataValid,
              mapView: _vm.mapView
            })}}}):_c('q-icon',{key:("icon-" + item + "-" + index),staticClass:"q-mx-sm invisible",attrs:{"name":"img:icons/marker-default.svg","color":_vm.color,"size":"lg","dense":""}})]})],2),_c('div',{staticClass:"icons-middle-row row justify-center"},[_vm._l((_vm.middleButtons),function(item,index){return [(item)?_c('q-btn',{key:("btn-" + item + "-" + index),attrs:{"icon":("img:icons/icon-" + item + ".svg"),"color":_vm.color,"size":"lg","dense":"","flat":""},on:{"click":function($event){return _vm.$emit('click', {
              name: _vm.$options.name,
              selected: item,
              transferDataValid: _vm.transferDataValid,
              mapView: _vm.mapView
            })}}}):_c('q-icon',{key:("icon-" + item + "-" + index),staticClass:"q-mx-sm invisible",attrs:{"name":"img:icons/marker-default.svg","color":_vm.color,"size":"lg","dense":""}})]})],2),_c('div',{staticClass:"icons-bottom-row row justify-center"},[_vm._l((_vm.bottomButtons),function(item,index){return [(item)?_c('q-btn',{key:("btn-" + item + "-" + index),attrs:{"icon":("img:icons/icon-" + item + ".svg"),"color":_vm.color,"size":"lg","dense":"","flat":""},on:{"click":function($event){return _vm.$emit('click', {
              name: _vm.$options.name,
              selected: item,
              transferDataValid: _vm.transferDataValid,
              mapView: _vm.mapView
            })}}}):_c('q-icon',{key:("icon-" + item + "-" + index),staticClass:"q-mx-sm invisible",attrs:{"name":"img:icons/marker-default.svg","color":_vm.color,"size":"lg","dense":""}})]})],2)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }